/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

export default function RecoupmentDetail(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const {
    RecoupmentTabValues, RecoupmentTabFrequencyDD, recoupmentTabHandleChange, showRecoupmentError, recoupmentErrMsg,
    frequencyDropdown, disableFields, formatDecimalForAmount, formatDecimalOnFocus, userInquiryPrivileges,setFocusCheck
  } = props;

  let frequencyCode = frequencyDropdown.find(value => value.code === RecoupmentTabValues.frequency);

  return (
    <div>
      <div className="tab-holder my-2">
        <form autoComplete="off">
          <fieldset className="r-custom-fieldset no-padding">
            <legend>Recoupment:</legend>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="installment-value"
                  label="Installment Value"
                  inputProps={{ maxlength: 10 }}
                  value={RecoupmentTabValues.installmentvalue}
                  onChange={recoupmentTabHandleChange('installmentvalue')}
                  InputLabelProps={{
                    shrink: true
                  }}                 
                  disabled={disableFields || !userInquiryPrivileges}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                      ''
                    );
                  }}
                  helperText={showRecoupmentError.showInstallmentErr ? recoupmentErrMsg.installmentErrMsg : null}
                  error={showRecoupmentError.showInstallmentErr ? recoupmentErrMsg.installmentErrMsg : null}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-md" >
                <TextField
                  id="percentage-value"
                  label="Percentage Value"
                  disabled={disableFields || !userInquiryPrivileges}
                  inputProps={{ maxlength: 10 }}
                  value={RecoupmentTabValues.percentagevalue}
                  onChange={recoupmentTabHandleChange('percentagevalue')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={showRecoupmentError.showPecentValueErr ? recoupmentErrMsg.percentValueErrMsg : null}
                  error={showRecoupmentError.showPecentValueErr ? recoupmentErrMsg.percentValueErrMsg : null}
                >
                </TextField>
              </div>
              <NativeDropDown
                id="frequency"
                  disabled={disableFields || !userInquiryPrivileges}
                isRequired={RecoupmentTabValues.percentagevalue || RecoupmentTabValues.installmentvalue}
                  label="Frequency"
                  inputProps={{ maxlength: 2 }}
                value={frequencyCode !== undefined ? RecoupmentTabValues.frequency : DEFAULT_DD_VALUE}
                  onChange={recoupmentTabHandleChange('frequency')}
                  InputLabelProps={{
                    shrink: true
                  }}
                errTxt={showRecoupmentError.showFrequencyErr ? recoupmentErrMsg.frequencyErrMsg : null}
                dropdownList={frequencyDropdown ? frequencyDropdown.map(option => (
                  <option key={option.code} value={option.code}>
                      {option.description}
                  </option>
                  )) : null}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="Recoupment_Lag_Days"
                  label="Recoupment Lag Days"
                  type="number"
                  disabled={disableFields || !userInquiryPrivileges}
                  inputProps={{ maxlength: 5 }}
                  value={RecoupmentTabValues.recoupmentLogDays}
                  onChange={recoupmentTabHandleChange('recoupmentLogDays')}
                  InputLabelProps={{
                    shrink: true
                  }}
				  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5);
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="max-withhold-amount"
                  label="Max Withhold Amount"
                  inputProps={{ maxlength: 14 }}
                  value={RecoupmentTabValues.maxWithholdAmount}
                  onChange={recoupmentTabHandleChange('maxWithholdAmount')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onFocus={formatDecimalOnFocus('maxWithholdAmount')}
                  onBlur={formatDecimalForAmount('maxWithholdAmount')}
                  disabled={disableFields || !userInquiryPrivileges}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                      ''
                    );
                  }}
                  helperText={showRecoupmentError.showMaxWithholdAmtErr ? recoupmentErrMsg.showMaxWithholdAmtErrMsg : null}
                  error={showRecoupmentError.showMaxWithholdAmtErr ? recoupmentErrMsg.showMaxWithholdAmtErrMsg : null}
                >
                </TextField>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
}
