/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/FinancialEntityActionTypes';

const initialState = {
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DROPDOWNDATA:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE:
      return { ...state, payeeTypeDetails: action.payeeTypeDetails };
    case actionTypes.FINANCIALENTITY_SEARCH_DATA:
      return { ...state, searchPayeeDetails: action.searchPayeeDetails };
    case actionTypes.FINANCIALENTITY_SINGLE_RECORD_ACTIONTYPE:
      return { ...state, singleSearchResults: action.singleSearchResults };
    case actionTypes.FINANCIALENTITY_ADD_ACTIONTYPE:
      return { ...state, addResponseData: action.addResponseData };
    case actionTypes.FINANCIALENTITY_UPDATE_ACTIONTYPE:
      return { ...state, updateResponseData: action.updateResponseData };
    case actionTypes.FINANCIAL_ENTITY_GET_DROPDOWN_ACTION:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.PAYEE_ID_TYPE_DROPDOWN:
      return { ...state, payeeIdTypeDropdown: action.payeeIdTypeDropdown };
    case actionTypes.BANKING_DROPDOWN_ACTION:
      return { ...state, bankingDropdown: action.bankingDropdown };
    case actionTypes.FINANCIALENTITY_PAYEE_SEARCH_DATA:
      return { ...state, payeeDetailsName: action.payeeDetails };
    case actionTypes.RESET_ADD_RESPONSE:
      return { ...state, addResponseData: null };
    case actionTypes.RESET_UPDATE_RESPONSE:
      return { ...state, updateResponseData: null, singleSearchResults: null, searchPayeeDetails: null };
    case actionTypes.FINANCIAL_ENTITY_LIST_NOTE:
      return { usageDropDown: action.usageDropDown };
    case actionTypes.RESET_PAYEEE_RESPONSE:
      return { ...state, payeeDetailsName: null };
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditDetails: action.payload };
    case actionTypes.AUDIT_LOG_TABLE_MISCELLANEOUS:
      return { ...state, auditMiscellaneousDetails: action.payload };
    case actionTypes.AUDIT_LOG_TABLE_ADDRESS:
      return { ...state, auditAddressDetails: action.payload };
    case actionTypes.AUDIT_LOG_TABLE_BANKING:
      return { ...state, auditBankingDetails: action.payload };
case actionTypes.RESETDATA:
return action.resetData;
    default: return state;
  }
};

export default reducer;
