/* eslint-disable */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const commonAPIHandler = (value, isSearch) => {
  isSearch = isSearch || false;
  let flag = value.status || (value.response ? value.response.status : value.code==="ERR_NETWORK" ?"ERR_NETWORK":500 );
  switch (flag) {
    case 204:
      return [];
      break;
    case 200:
      if (isSearch) {
        return value.data.searchResults;
      } else {
        return value.data;
      }
      break;
    case 400:
      return value.response.data;
      break;
    case 500:
      return { systemFailure: true };
      break;
    case "ERR_NETWORK":
      return { systemFailure: true };
      break;
    default:
      return { systemFailure: true };
      break;
  }
};
const parseData = (args, actionKey) => {
  const isArray = args && Array.isArray(args);
  switch (true) {
    // "@#--Payload sould be standardised on server"
    case isArray && args.length === 1 && true:
      if (actionKey === "FundCode" || actionKey === "claimSummary") {
        return args;
      } else {
        return args[0];
      }
    case !isArray &&
      args.searchResults &&
      Array.isArray(args.searchResults) &&
      args.searchResults.length === 1 &&
      true:
      return args.searchResults[0];
    case !isArray &&
      args.enterpriseSearchresultVO &&
      args.enterpriseSearchresultVO.searchResults &&
      Array.isArray(args.enterpriseSearchresultVO.searchResults) &&
      args.enterpriseSearchresultVO.searchResults[0] &&
      true:
      if (args.enterpriseSearchresultVO.searchResults.length === 1) {
        if (actionKey === "payout") {
          return args.enterpriseSearchresultVO.searchResults[0];
        }
        if (actionKey === "Receipt") {
          return args.enterpriseSearchresultVO.searchResults;
        }
      }
    case !isArray:
      return args;
    default:
      return null;
  }
};

const enterpriseSearchresultVOParser = (_data, _actionKey) => {
  let check =
    Array.isArray(_data.enterpriseSearchresultVO.searchResults) &&
    _data.enterpriseSearchresultVO.searchResults.length === 1;

  if (check) {
    let payloadData =
      actionKey === "Receipt"
        ? _data.enterpriseSearchresultVO.searchResults
        : _data.enterpriseSearchresultVO.searchResults[0];

    return {
      ...payloadData,
      editFlag: true,
      fromSearch: true,
      systemFailue: _data.systemFailue,
      respcode: _data.respcode,
      respdesc: _data.respdesc
    };
  } else return null;
};

const parseAndPush = ({ data = null, url, actionKey, searchCriteria }) => {
  let result = {
    pathname: url
  };
  switch (actionKey) {
    case "payout":
      result.state = { addSuccessData: data, payoutUpdate: true };
      break;
    case "Receipt":
      result.state = {
        payloadViewData: data,
        duplicateSearchPayload: data,
        editFlag: true,
        fromSearch: true
      };
      break;
    case "FundCode":
      result.state = { fundCodeData: data, editFlag: "1" };
      break;
    case "claimSummary":
      result.state = { summaryDetails: data };
      result.state.summaryDetails.searchDetails = searchCriteria;
      break;
    case "AccountReceivable":
      switch (data.activityTypeCode) {
        case "CR":
          result = {
            pathname: "/ClaimsReceivable",
            state: { claimsReceivableData: data }
          };
          break;
        case "WL":
          result = {
            pathname: "/FinancialAccountLienLevyUpdate",
            state: { withHoldSearchResult: data }
          };
          break;
        case "AP":
          result = {
            pathname: "/AdvancePayment",
            state: { advancePaymentData: data }
          };
          break;
        default:
          result = {
            pathname: "/FinancialAccountGrossLevelUpdate",
            state: { claimsReceivableData: data }
          };
          break;
      }
      break;
    case "textUpdate":
      if (searchCriteria.hasOwnProperty("eombProcedureTypeCode")) {
        result = {
          pathname: "/TextUpdate",
          state: { row: data, TextType: "EOMB", EombType: "hcpcs" }
        };
      }
      if (searchCriteria.hasOwnProperty("providerTextSk")) {
        result = {
          pathname: "/TextUpdate",
          state: { row: data, TextType: "Provider Notice" }
        };
      }
      if (searchCriteria.hasOwnProperty("ncpdpRejectCodeText")) {
        result = {
          pathname: "/TextUpdate",
          state: { row: data, TextType: "NCPDP Rejection Codes" }
        };
      }
      if (searchCriteria.hasOwnProperty("adjustmentReasonCode")) {
        result = {
          pathname: "/TextUpdate",
          state: { row: data, TextType: "Adjustment Reason" }
        };
      }
      if (searchCriteria.hasOwnProperty("claimEOBCode")) {
        result = {
          pathname: "/TextUpdate",
          state: { row: data, TextType: "RA EOB" }
        };
      }
      if (searchCriteria.hasOwnProperty("remarkCode")) {
        result = {
          pathname: "/TextUpdate",
          state: { row: data, TextType: "Remark Code" }
        };
      }
      break;
    case "payeeSearchResult":
      result.state = { [actionKey]: data, searchCriteria: searchCriteria };
      break;
    case "searchSingleFinancialEntityRes":
      result.state = { [actionKey]: data, searchCriteria: searchCriteria };
      break;
    default:
      result.state = { [actionKey]: data };
      break;
  }
  return result;
};

export default {
  parseData,
  parseAndPush
};
