/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DATAINVALID = 'Please enter the data in correct format.';
export const SYSTEMERROR = 'There was an error processing the request. Please retry the transaction.';
export const SAVESUCCESS = 'System successfully saved the Information.';
export const DUPLICATEDATE = 'Duplicate entry exists with the exact same date or overlapping date.';
export const DUPLICATEDETAIL = 'Detail criteria already exists.';
export const ATLEASTONEDETAIL = 'At least one Fiscal Pend detail record must be defined for a Fiscal Pend Criteria Set.';
export const PROCEDURE_CODE_MODIFIER = 'Procedure Code/Modifier combination does not exist in database.';
export const PROVIDER_ID_TAXONOMY = 'Provider ID/Taxonomy combination is not valid for the date range entered.';
export const CAP_NEGATIVE = 'CAP Amount cannot be negative.';
export const PROVIDER_ID_REQ = 'Provider ID is required when Taxonomy is entered.';
export const PAID_BEGIN_END_DATE = 'Paid Date End Date must be greater than or equal Paid Date Begin Date.';
export const PROVIDER_ID_BOTH = 'Provider ID and Provider ID Type are both required.';
export const PROVIDER_ID_AND_TYPE_REQ = 'Provider ID and Provider Type are required if Provider ID Type is NPI.';
export const MODIFIER_PROVIDER_CODE_VALUE = 'Modifiers may only be entered if a Procedure Code Value is entered.';
export const PROVIDER_ID_NOTFOUND = 'The Provider ID is not found on database.';
export const MAPSET_ERROR = 'Mapset ID cannot be entered if other detail criteria is entered.';
export const END_DATE_LESSER = 'Paid Date End Date must be greater than or equal Paid Date Begin Date.';
export const BEGIN_DATE_VALID = 'Paid Date Begin Date that was entered is Invalid.';
export const END_DATE_VALID = 'Paid Date End Date that was entered is invalid.';
export const DES_REQ = 'Description is required.';
export const RANK_REQ = 'Rank is required.';
export const BEGIN_DATE_REQ = 'Paid Date Begin Date is required.';
export const END_DATE_REQ = 'Paid Date End Date is required.';
export const CAP_REQ = 'Cap Amount is required.';
export const CAP_VALID = 'Please enter the data in correct format.';
export const INVALID_ENDDATE = 'Paid Date End Date must be greater than or equal Paid Date Begin Date.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const CANNOT_EDIT = 'Fiscal Pend Record Cannot Be Deleted Due to the Fiscal Pend Payment Dates Already Used by the Payment Cycle.';
export const FISCAL_PEND = 'Fiscal Pend Record Cannot Be updated or deleted as the Fiscal Pend ID has been used to fiscally pend a claim.';
