/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './FinancialEntityActionTypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

const headers = {
  'Access-Control-Allow-Origin': '*'
};

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  const body = {
    tableName: tableName,
    keyValue: keyValue
  };
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data) { dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData)); }
      })
      .catch(error => {
dispatch(setAuditLogData(error));
      });
  };
};

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const getAuditLogBankingDetailsAction = (tableName, keyValue, auditData) => {
  const body = {
    tableName: tableName,
    keyValue: keyValue
  };
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data) { dispatch(setAuditLogBankingData(response.data.responseAuditLogDetails, auditData)); }
      })
      .catch(error => {
dispatch(setAuditLogBankingData(error));
      });
  };
};

export const setAuditLogBankingData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_BANKING,
    payload: data,
    keyName
  };
};
export const getAuditLogAddressDetailsAction = (body, auditData) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, body)
      .then(response => {
        if (response.data) { dispatch(setAuditLogAddressData(response.data.responseAuditLogDetails, auditData)); }
      })
      .catch(error => {
dispatch(setAuditLogAddressData(error));
      });
  };
};

export const setAuditLogAddressData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ADDRESS,
    payload: data,
    keyName
  };
};
export const getAuditLogMiscellaneousDetailsAction = (tableName, keyValue, auditData) => {
  const body = {
    tableName: tableName,
    keyValue: keyValue
  };
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data) { dispatch(setAuditLogMiscellaneousData(response.data.responseAuditLogDetails, auditData)); }
      })
      .catch(error => {
dispatch(setAuditLogMiscellaneousData(error));
});
  };
};

export const setAuditLogMiscellaneousData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_MISCELLANEOUS,
    payload: data,
    keyName
  };
};
export const getBankingDropdownTypeDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, value)
      .then(response => {
        dispatch(setBankingTypeDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setBankingTypeDropdownData(error));
      });
  };
};

export const setBankingTypeDropdownData = (bankingDropdown) => {
  return {
    type: actionTypes.BANKING_DROPDOWN_ACTION,
    bankingDropdown: bankingDropdown
  };
};

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.FINANCIAL_ENTITY_GET_DROPDOWN_ACTION,
    dropdownData: dropdownData
  };
};

export const getPayeeIdType = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYEE_TYPE_ID_DROPDOWN_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse) {
          dispatch(setPayeeIdTypeDropdown(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      });
  };
};

export const setPayeeIdTypeDropdown = (data) => {
  return {
    type: actionTypes.PAYEE_ID_TYPE_DROPDOWN,
    payeeIdTypeDropdown: data
  };
};

export const getPayeeIdTypeCodeAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.FINANCIALENTITY_GETPAYEEIDTYPECODE}?payeeTypeCode=${value}`, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeIdTypeCodeDetails(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeCodeDetails(sendingResponse));
      });
  };
};

export const SearchPayeeAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIALENTITY_SEARCH}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response, true);
        dispatch(setSearchPayeeDetails(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error, true);
        dispatch(setSearchPayeeDetails(sendingResponse));
      });
  };
};

export const SearchPayeeIDNameAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIALENTITY_GETPAYEEIDNAME}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setSearchPayeeIDNameDetails(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSearchPayeeIDNameDetails(sendingResponse));
      });
  };
};

export const setSearchPayeeIDNameDetails = (searchResult) => {
  return {
    type: actionTypes.FINANCIALENTITY_PAYEE_SEARCH_DATA,
    payeeDetails: searchResult
  };
};

export const setPayeeIdTypeCodeDetails = (payeeTypeData) => {
  return {
    type: actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE,
    payeeTypeDetails: payeeTypeData
  };
};

export const ResetResponseData = () => {
  return {
    type: actionTypes.RESET_ADD_RESPONSE
  };
};
export const ResetPayeeResponse = () => {
  return {
    type: actionTypes.RESET_PAYEEE_RESPONSE
  };
};
export const ResetUpdateResponseData = () => {
  return {
    type: actionTypes.RESET_UPDATE_RESPONSE
  };
};

export const ResetSearchResponseData = () => {
  return {
    type: actionTypes.RESET_SEARCH_RESULT
  };
};

export const setSearchPayeeDetails = (searchResult) => {
  return {
    type: actionTypes.FINANCIALENTITY_SEARCH_DATA,
    searchPayeeDetails: searchResult
  };
};

export const searchSingleRecordFinancialEntity = (value) => {
  const userLoginState = JSON.parse(
    window.localStorage.getItem('loginState')
  );
  const commonEntitySK = value.commonEntitySK || value.systemPayeeId || value.sysID || value;
  const requestBody = {
    userId: userLoginState?.logInUser ? userLoginState.logInUser : null,
    userLastName: userLoginState?.userName ? userLoginState.userName : null,
    functionCode: "FINANCIAL-ENTITY",
    keyType: "G_ENTY_EXTL_ID",
    key: value.entityID || value.payeeId,
    commonEntitySK: commonEntitySK
  };
  return dispatch => {
    return axios.post(serviceEndPoint.FINANCIALENTITY_SINGLE_RECORD_SEARCH, requestBody)
      .then(response => {
        let data = [];
        data = response.data;
        dispatch(setSingleSearchFinancialEntity(data));
      })
      .catch(error => {
dispatch(setSingleSearchFinancialEntity(error));
});
  };
};

export const setSingleSearchFinancialEntity = (singleSearchResults) => {
  return {
    type: actionTypes.FINANCIALENTITY_SINGLE_RECORD_ACTIONTYPE,
    singleSearchResults: singleSearchResults
  };
};
export const addFinancialEntity = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIALENTITY_ADD}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setFinancialEntityAddResponse(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialEntityAddResponse(sendingResponse));
      });
  };
};
export const setFinancialEntityAddResponse = (addResponseData) => {
  return {
    type: actionTypes.FINANCIALENTITY_ADD_ACTIONTYPE,
    addResponseData: addResponseData
  };
};

export const updateFinancialEntity = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIALENTITY_UPDATE}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setFinancialEntityUpdateResponse(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialEntityUpdateResponse(sendingResponse));
      });
  };
};
export const setFinancialEntityUpdateResponse = (updateResponseData) => {
  return {
    type: actionTypes.FINANCIALENTITY_UPDATE_ACTIONTYPE,
    updateResponseData: updateResponseData
  };
};
export const getSubDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, value)
      .then(response => {
        dispatch(setSubDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setSubDropdownData(error));
      });
  };
};

export const setSubDropdownData = (subdropdownData) => {
  return {
    type: actionTypes.FINANCIAL_ENTITY_LIST_NOTE,
    usageDropDown: subdropdownData
  };
};
export const resetFinancialEntitySearch = () => {
return {
type: actionTypes.RESETDATA,
resetData: []
};
};
