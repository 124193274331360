/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/FinancialReceipt/financialReceiptActionTypes';

const axiosPayLoad = {
  financialReceiptSearch: null,
  dropDowns: []
};
const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.DROPDOWNDATA:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.FINANCIAL_RECEIPT_SEARCH_ACTION_TYPE:
      return { ...state, financialReceiptSearch: action.financialReceiptSearch };
case actionTypes.FINANCIAL_RECEIPT_FCN_SEARCH_ACTION_TYPE:
return { ...state, fcnSearch: action.fcnSearch };
    case actionTypes.FINANCIAL_ADD_GENERATE_FCN:
      return { ...state, generatedFCNNUM: action.generatedFCNNUM };
    case actionTypes.SEARCH_SYSTEM_LIST:
      return { ...state, systemLists: action.systemLists };
    case actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE:
      return { ...state, payeeIDTypeCodeDetails: action.payeeIDTypeCodeDetails };
    case actionTypes.GET_FINANCIALREASONCODE_ON_ACTIVITYTYPE:
      return { ...state, financialReasonCodeDetails: action.financialReasonCodeDetails };
    case actionTypes.GET_ADJUSTMENT_DATA:
      return { ...state, adjustmentData: action.adjustmentData };
    case actionTypes.GET_ADJUSTMENT_CALCULATION:
      return { ...state, calculatedData: action.calculatedData };
    case actionTypes.SAVE_ADJUSTMENT_CALCULATION:
      return { ...state, saveResult: action.saveResult };
    case actionTypes.VALIDATE_PAYEE_RESULT:
      return { ...state, validatePayeeResult: action.validatePayeeResult };
    case actionTypes.MEMBER_ID_DETAILS:
      return { ...state, memberDetails: action.memberDetails };
    case actionTypes.FINANCIAL_RECEIPT_UPDATE_ACTION_TYPE:
      return { ...state, financialReceiptUpdate: action.financialReceiptUpdate };
    case actionTypes.ADD_REFUND_RECOVERY_VALIDATE:
      return { ...state, validateAddRefundResult: action.validateAddRefundResult };
    case actionTypes.ADD_FINANCIAL_RECEIPT:
      return { ...state, addFinanceReceiptResult: action.addResponse };
    case actionTypes.UPDATE_FINANCIAL_RECEIPT:
      return { ...state, updateFinancialReceiptResult: action.updateResponse };
    case actionTypes.ADD_RECEIPT_REVERSAL:
      return { ...state, addReceiptReverseResponse: action.addReceiptReverseResponse };
    case actionTypes.SAVE_RECEIPT_REVERSAL:
      return { ...state, saveReceiptReverseResponse: action.saveReceiptReverseResponse };
  case actionTypes.GET_CHECK_REPLACEMENT_DATA:
      return { ...state, checkReplacementData: action.checkReplacementData };
case actionTypes.RESET_RECEIPT_VALUES :
return { ...state, addReceiptReverseResponse: null, adjustmentData: null, checkReplacementData: null };
    case actionTypes.REST_RECEIPT_SEARCH_RESULT:
return { ...state, fcnSearch: null, financialReceiptSearch: null };
case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditDetails: action.payload };
    case actionTypes.AUDIT_LOG_TABLE_ROW:
      return { ...state, auditRowDetails: action.payload };
case actionTypes.RESETDATA:
      return action.resetData;
    default: return state;
  }
};

export default reducer;
