/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const GET_PAYEEIDTYPECODE_ON_PAYEETYPE = 'GET_PAYEEIDTYPECODE_ON_PAYEETYPE';
export const FINANCIALENTITY_SEARCH_DATA = 'FINANCIALENTITY_SEARCH_DATA';
export const FINANCIALENTITY_SINGLE_RECORD_ACTIONTYPE = 'FINANCIALENTITY_SINGLE_RECORD_ACTIONTYPE';
export const FINANCIALENTITY_ADD_ACTIONTYPE = 'FINANCIALENTITY_ADD_ACTIONTYPE';
export const FINANCIALENTITY_UPDATE_ACTIONTYPE = 'FINANCIALENTITY_UPDATE_ACTIONTYPE';
export const FINANCIALENTITY_PAYEE_SEARCH_DATA = 'FINANCIALENTITY_PAYEE_SEARCH_DATA';
export const FINANCIAL_ENTITY_GET_DROPDOWN_ACTION = 'FINANCIAL_ENTITY_GET_DROPDOWN_ACTION';
export const PAYEE_ID_TYPE_DROPDOWN = 'PAYEE_ID_TYPE_DROPDOWN';
export const BANKING_DROPDOWN_ACTION = 'BANKING_DROPDOWN_ACTION';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const RESET_ADD_RESPONSE = 'RESET_ADD_RESPONSE';
export const RESET_UPDATE_RESPONSE = 'RESET_UPDATE_RESPONSE';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';
export const FINANCIAL_ENTITY_LIST_NOTE = 'FINANCIAL_ENTITY_LIST_NOTE';
export const RESET_PAYEEE_RESPONSE = 'RESET_PAYEEE_RESPONSE';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ADDRESS = "AUDIT_LOG_TABLE_ADDRESS";
export const AUDIT_LOG_TABLE_MISCELLANEOUS = "AUDIT_LOG_TABLE_MISCELLANEOUS";
export const AUDIT_LOG_TABLE_BANKING = "AUDIT_LOG_TABLE_BANKING";
export const RESETDATA = "RESETDATA";
