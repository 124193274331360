/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/AccountPayableActionTypes';

const reducer = (state = { searchValuesFPA: {} }, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_PAYABLE_APPROVAL_FCN_SEARCH :
      return {
        ...state,
        fcnSearchData: action.fcnSearchData
      };
    case actionTypes.ACCOUNT_PAYABLE_APPROVAL_PAYEE_SEARCH :
      return {
        ...state,
        payeeSearchData: action.payeeSearchData
      };
    case actionTypes.GETPAYEEIDTYPE :
      return {
        ...state,
        payeeIdTypeCcdeData: action.payeeIdTypeDropdown
      };
    case actionTypes.DROPDOWNDATA:
      return {
        ...state,
        dropdownData: action.dropdownData
      };
    case actionTypes.UPDATE_PAYOUT_APPROVAL_FCN :
      return {
        ...state,
        updateApprovalDataFCN: action.updateApprovalDataFCN
      };
    case actionTypes.UPDATE_PAYOUT_APPROVAL_PAYEE :
      return {
        ...state,
        updateApprovalDataPayee: action.updateApprovalDataPayee
      };
    case actionTypes.PAYOUT_APPROVAL_SEARCH_VALUES :
      return {
        ...state,
        searchValuesFPA: { ...state.searchValuesFPA, ...action.searchQuery }
      };
    case actionTypes.RESETDATA:
      return action.resetData;
    default: return state;
  }
};
export default reducer;
