/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_FINANCIAL_PAYOUT = 'SEARCH_FINANCIAL_PAYOUT';
export const PAYEE_SEARCH_FINANCIAL_PAYOUT = 'PAYEE_SEARCH_FINANCIAL_PAYOUT';
export const PAYEE_OUTSTANDING_SEARCH_FINANCIAL_PAYOUT = 'PAYEE_OUTSTANDING_SEARCH_FINANCIAL_PAYOUT';
export const ROW_SEARCH_FINANCIAL_PAYOUT = 'ROW_SEARCH_FINANCIAL_PAYOUT';
export const RESETDATA = 'RESETDATA';
export const PAYEE_ID_TYPE_DROPDOWN = 'PAYEE_ID_TYPE_DROPDOWN';
export const MCN_SEARCH_FINANCIAL_PAYOUT = 'MCN_SEARCH_FINANCIAL_PAYOUT';
export const GET_PAYEEIDTYPECODE_ON_PAYEETYPE = 'GET_PAYEEIDTYPECODE_ON_PAYEETYPE';
export const SEARCH_FINANCIAL_PAYEE = 'SEARCH_FINANCIAL_PAYEE';
export const NEW_PAYOUT_RESPONSE = 'NEW_PAYOUT_RESPONSE';
export const PAYOUT_ADD_ACTION = 'PAYOUT_ADD_ACTION';
export const PAYOUT_UPDATE_ACTION = 'PAYOUT_UPDATE_ACTION';
export const PAYOUT_DETAILS = 'PAYOUT_DETAILS';
export const GET_SINGLE_PAYOUT_DETAILS = 'GET_SINGLE_PAYOUT_DETAILS';
export const RESET_SINGLE_PAYOUT_DETAILS = 'RESET_SINGLE_PAYOUT_DETAILS';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const PAYOUT_MEMBER_DETAILS = 'PAYOUT_MEMBER_DETAILS';
export const PAYOUT_LIST_NOTE = 'PAYOUT_LIST_NOTE';