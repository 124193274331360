/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './financialReceiptActionTypes';
import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler/index';
import axios from 'axios';
const headers = {
  'Access-Control-Allow-Origin': '*'
};
export const getAuditLogDetailsAction = (body, auditData) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, body)
      .then(response => {
        if (response.data) { dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData)); }
      })
      .catch(error => {
dispatch(setAuditLogData(error));
      });
  };
};
export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};
export const getAuditLogRowDetailsAction = (tableName, keyValue, auditData) => {
  const body = {
    tableName: tableName,
    keyValue: keyValue
  };
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data) { dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData)); }
      })
      .catch(error => {
const sendingResponse = commonAPIHandler(error);
dispatch(setAuditLogRowData(sendingResponse));
});
  };
};
export const setAuditLogRowData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ROW,
    payload: data,
    keyName
  };
};
export const resetFinancialReceiptSearch = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};
export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const getAdjustmentDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_GET_ADJUST_DATA}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(getAdjustmentData(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(getAdjustmentData(sendingResponse));
      });
  };
};
export const getAdjustmentCalculationAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_CALCULATE}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(getAdjustmentCalculation(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(getAdjustmentCalculation(sendingResponse));
      });
  };
};
export const saveAdjustmentCalculationAction = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('receiptVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_ADJUST_SAVE}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(saveAdjustmentCalculation(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(saveAdjustmentCalculation(sendingResponse));
      });
  };
};

export const validatePayeeAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_VALIDATE_PAYEEID}`, value, { headers: headers })
      .then(response => {
        let data = [];
        data = response.data;
        const sendingResponse = commonAPIHandler(response);
        dispatch(setValidatePayeeResult(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);

        dispatch(setValidatePayeeResult(sendingResponse));
      });
  };
};
export const getPayeeIdTypeCodeAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYEE_TYPE_ID_DROPDOWN_ENDPOINT}?payeeTypeCode=${value}`, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        let data = [];
        data = sendingResponse.payeeIdTypeCode;
        dispatch(setPayeeIdTypeCodeDetails(data));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeCodeDetails(sendingResponse));
      });
  };
};
export const financialReceiptSearchAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCEIAL_RECEIPT_FCN_SEARCH}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse) {
dispatch(setFinancialReceitSearch(sendingResponse));
dispatch(resetReceiptValues());
} else { dispatch(setFinancialReceitSearch([])); };
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialReceitSearch(sendingResponse));
      });
  };
};

export const validateMemberAPI = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.MEMBER_ID_VALIDATE}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse) { dispatch(setMemberDetails(sendingResponse)); };
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setMemberDetails(sendingResponse));
      });
  };
};

export const setMemberDetails = (data) => {
  return {
    type: actionTypes.MEMBER_ID_DETAILS,
    memberDetails: data
  };
};

export const financialReceiptUpdateAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_FCN_UPDATE}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setFinancialReceitUpdate(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialReceitUpdate(sendingResponse));
      });
  };
};
export const addRefundRecoveryValidateAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_ADD_REFUND_RECOVERY_VALIDATE}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setAddRefundRecoveryValidate(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setAddRefundRecoveryValidate(sendingResponse));
      });
  };
};
export const generateFCNForAdd = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.FINANCEIAL_RECEIPT_ADD_GENERATE_FCN_NUM}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setGeneratedFCN(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setGeneratedFCN(sendingResponse));
      });
  };
};
export const addFinancialReceiptAction = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('receiptVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_ADD}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setAddFinancialReceipt(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setAddFinancialReceipt(sendingResponse));
      });
  };
};

export const updateFinancialReceiptAction = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('receiptVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_UPDATE}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setAddFinancialReceipt(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setAddFinancialReceipt(sendingResponse));
      });
  };
};

export const addFinancialReceiptReverseAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_REVERSE_ADD}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setAddFinancialReceiptReversal(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setAddFinancialReceiptReversal(sendingResponse));
      });
  };
};
export const saveFinancialReceiptReverseAction = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('receiptVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_REVERSE_SAVE}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setSaveFinancialReceiptReversal(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSaveFinancialReceiptReversal(sendingResponse));
      });
  };
};

export const setAddFinancialReceiptReversal = (Response) => {
  return {
    type: actionTypes.ADD_RECEIPT_REVERSAL,
    addReceiptReverseResponse: Response
  };
};
export const setSaveFinancialReceiptReversal = (Response) => {
  return {
    type: actionTypes.SAVE_RECEIPT_REVERSAL,
    saveReceiptReverseResponse: Response
  };
};
export const setUpdateFinancialReceipt = (updateResponse) => {
  return {
    type: actionTypes.UPDATE_FINANCIAL_RECEIPT,
    updateResponse: updateResponse
  };
};
export const setAddFinancialReceipt = (addResponse) => {
  return {
    type: actionTypes.ADD_FINANCIAL_RECEIPT,
    addResponse: addResponse
  };
};
export const setAddRefundRecoveryValidate = (validateAddRefundResult) => {
  return {
    type: actionTypes.ADD_REFUND_RECOVERY_VALIDATE,
    validateAddRefundResult: validateAddRefundResult
  };
};
export const setValidatePayeeResult = (validatePayeeResult) => {
  return {
    type: actionTypes.VALIDATE_PAYEE_RESULT,
    validatePayeeResult: validatePayeeResult
  };
};
export const setPayeeIdTypeCodeDetails = (payeeIDTypeCodeData) => {
  return {
    type: actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE,
    payeeIDTypeCodeDetails: payeeIDTypeCodeData
  };
};
export const setFinancialReasonCodeDetails = (financialReasonCodeData) => {
  return {
    type: actionTypes.GET_FINANCIALREASONCODE_ON_ACTIVITYTYPE,
    financialReasonCodeDetails: financialReasonCodeData
  };
};
export const setFinancialReceitSearch = (financialReceiptSearch) => {
  return {
    type: actionTypes.FINANCIAL_RECEIPT_SEARCH_ACTION_TYPE,
    financialReceiptSearch: financialReceiptSearch
  };
};
export const resetReceiptValues = (addReceiptReverseResponse) => {
return { type: actionTypes.RESET_RECEIPT_VALUES };
};

export const resetReceiptSearchData = () => {
return {
type: actionTypes.REST_RECEIPT_SEARCH_RESULT
};
};

export const setFinancialReceitUpdate = (financialReceiptUpdate) => {
  return {
    type: actionTypes.FINANCIAL_RECEIPT_UPDATE_ACTION_TYPE,
    financialReceiptUpdate: financialReceiptUpdate
  };
};
export const setGeneratedFCN = (data) => {
  return {
    type: actionTypes.FINANCIAL_ADD_GENERATE_FCN,
    generatedFCNNUM: data
  };
};
export const setSystemList = (systemList) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_LIST,
    systemLists: systemList
  };
};
export const getAdjustmentData = (value) => {
  return {
    type: actionTypes.GET_ADJUSTMENT_DATA,
    adjustmentData: value
  };
};
export const getAdjustmentCalculation = (value) => {
  return {
    type: actionTypes.GET_ADJUSTMENT_CALCULATION,
    calculatedData: value
  };
};
export const saveAdjustmentCalculation = (value) => {
  return {
    type: actionTypes.SAVE_ADJUSTMENT_CALCULATION,
    saveResult: value
  };
};

export const getReplacementDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_GET_CHECK_REPLACEMENT_DATA}`, value)
      .then(response => {
        let data = [];
        data = response.data;
        const sendingResponse = commonAPIHandler(response);
        dispatch(getCheckReplacementData(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(getCheckReplacementData(sendingResponse));
      });
  };
};

export const getCheckReplacementData = (checkReplacementData) => {
  return {
    type: actionTypes.GET_CHECK_REPLACEMENT_DATA,
    checkReplacementData: checkReplacementData
  };
};

export const fcnSearchAction = (value) => {
return dispatch => {
return axios.post(`${serviceEndPoint.FINANCEIAL_RECEIPT_FCN_SEARCH}`, value, { headers: headers })
.then(response => {
const sendingResponse = commonAPIHandler(response);
if (sendingResponse) {
dispatch(setFcnSearch(sendingResponse));
dispatch(resetReceiptValues());
}
else { dispatch(setFcnSearch([])); };
})
.catch(error => {
const sendingResponse = commonAPIHandler(error);
dispatch(setFcnSearch(sendingResponse));
});
};
  };

export const setFcnSearch = (fcnSearch) => {
  return {
    type: actionTypes.FINANCIAL_RECEIPT_FCN_SEARCH_ACTION_TYPE,
    fcnSearch: fcnSearch
  };
};
