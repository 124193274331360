/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import numeral from 'numeral';

export const checkValueExists = (arrayValue, value) => {
    if (arrayValue && arrayValue.length > 0) {
        let flag = false;
        arrayValue.map((obj) => {
            if (obj.code === value) {
                flag = true;
            }
        });
        return flag;
    }
}
export const getLongDescription = (arrayValue, value) => {
    if (arrayValue && arrayValue.length > 0) {
        let longDescription = value;
        arrayValue.map((obj) => {
            const valDesc = `${obj.code}-${obj.description}`
            if (obj.code === value || valDesc === value || obj.description === value) {
                longDescription = obj.longDescription ? obj.longDescription : value;
            }
        });
        return longDescription;
    }
}

export const getShortDescription = (arrayValue, value) => {
    if (arrayValue && arrayValue.length > 0) {
        let shortDescription = value;
        arrayValue.map((obj) => {
            const valDesc = `${obj.code}-${obj.description}`
            if (obj.code === value || valDesc === value || obj.description === value) {
                shortDescription = obj.description || value;
            }
        });
        return shortDescription;
    }
}

export const removeLeadingTrailingSpaces = (objectData) => {
    if (objectData) {
        Object.entries(objectData).map(([key, value]) => {
            if (typeof (value) === 'string' && value !== null) {
                objectData[key] = String(value).trim()
            }
        })
        return objectData;
    }
    return objectData;

}

export const checkingDecimal=(data)=>{
    const rx = /^[A-Za-z0-9]{0,20}(\.[A-Za-z0-9]{0,2})?$/;
      if (rx.test(data)) {
          return true;     
        }
      else{
        return false;
      }
};

const someInMenus = (e, p) => {
  if (
    p === e.rootUrl ||
    p.includes(e.url) ||
    e.assocURLs.some((l) => p.includes(l))
  ) {
    return e.readOnly;
  }
  if (e.children?.length) {
    return e.children.some((el) => someInMenus(el, p));
  }
  return false;
};

export const isReadOnly = () => {
  return JSON.parse(localStorage.getItem("loginState"))?.menuItems?.some((e) =>
    someInMenus(e, window.location.pathname)
  );
};


const isInLeftNav = (e, p) => {
  if (
    p === e.rootUrl ||
    p.includes(e.url)
  ) {
    return true;
  }
  if (e.children?.length) {
    return e.children.some((el) => isInLeftNav(el, p));
  }
  return false;
};

export const someInLeftNav = () => {
 return JSON.parse(localStorage.getItem("loginState"))?.menuItems?.some((i) =>
  isInLeftNav(i, window.location.pathname)
);
};

export const determineAmountToUse = (adjustedAmount, originalAmount) => {
  const adjusted = Number(adjustedAmount);
  const original = Number(originalAmount);
  return adjusted > 0 ? adjusted : original;
};

export const parseAmount = (amountString) => {
    return numeral(amountString).value() || 0;
};
export const decodeParams = (encodedStr) => {
  return JSON.parse(decodeURIComponent(atob(encodedStr)));
};

export const getQueryParam = (params, key) => {
  if (params instanceof URLSearchParams) {
    return params.get(key);
  } else if (typeof params === 'object' && params !== null) {
    return params[key];
  }
  return null;
};
