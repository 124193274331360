/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';

import TableComponent from '../../../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchClaimsReceivableAction,
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';

const headCellsMultiRecord = [
  {
    id: 'receivableFCN',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    width: '14%'
  },
  {
    id: 'establishedDate',
    disablePadding: false,
    label: 'Established Date',
    enableHyperLink: false,
    isDate: true,
    width: '12%'
  },
  {
    id: 'reasonCodeDesc',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    width: '15%',
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: 'payeePayerTypeCodeDesc',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    width: '15%'
  },
  {
    id: 'payeePayerType',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    width: '9%'
  },
  {
    id: 'entityName',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    width: '21%'
  },
  {
    id: 'balanceAmount',
    disablePadding: false,
    label: 'Balance Amount',
    enableHyperLink: false,
    isBalance: true,
    width: '14%',
    numeric: true
  }
];

const headCellsSingleRecord = [
  {
    id: 'receivableFCN',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    width: '14%'
  },
  {
    id: 'establishedDate',
    disablePadding: false,
    label: 'Established Date',
    enableHyperLink: false,
    isDate: true,
    width: '12%'
  },
  {
    id: 'accountReceivableReasonCodeDesc',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    width: '15%',
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: 'payeePayerTypeCodeDesc',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    width: '15%'
  },
  {
    id: 'payeePayerIdFromDB',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    width: '9%'
  },
  {
    id: 'payeePayerNameFromDB',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    width: '21%'
  },
  {
    id: 'balanceAmount',
    disablePadding: false,
    label: 'Balance Amount',
    enableHyperLink: false,
    isBalance: true,
    width: '14%',
    numeric: true
  }
];

/* AccountReceivableSearchResultsTable()
 * This component is used to display a table for search(payee/manual check) results
 */
function AccountReceivableSearchResultsTable (props) {
  const [redirect, setRedirect] = React.useState(0);
  const [pTypeVal, setPTypeVal] = React.useState('');
  const [reasonVal, setReasonVal] = React.useState('');
  const dispatch = useDispatch();
  const onRowClick = values => dispatch(searchClaimsReceivableAction(values));
  const claimsReceivableData = useSelector(
    state => state.accountReceivableState.payloadClaims
  );
  /* Redirect to Claims Receivable screen if claims data coimg from api is not null */
  if (redirect === 1) {
    if (claimsReceivableData != null && claimsReceivableData !== '') {
      if (claimsReceivableData.activityTypeCode === 'CR') {
        claimsReceivableData.payeePayerTypeCode = pTypeVal;
        claimsReceivableData.accountReceivableReasonCode = reasonVal;
        claimsReceivableData.activityTypeCode = 'CR-Claims Rec';
        if (
          claimsReceivableData.payeePayerIDTypeCode != null &&
          claimsReceivableData.payeePayerIDTypeCode !== undefined
        ) {
          for (var payeeIdTypeCode in props.payeeTypeCodeDataInitial) {
            if (
              props.payeeTypeCodeDataInitial[payeeIdTypeCode].code ===
              claimsReceivableData.payeePayerIDTypeCode
            ) {
              claimsReceivableData.payeePayerIDTypeCode =
                props.payeeTypeCodeDataInitial[payeeIdTypeCode].description;
            }
          }
        }
        props.history.push({
          pathname: '/ClaimsReceivable',
          state: { claimsReceivableData, values: props.values, payloadPayees: props.payloadPayees, tableData: props.tableData }
        });
      } else if (claimsReceivableData.activityTypeCode === 'WL') {
        const withHoldSearchResult = claimsReceivableData;
        props.history.push({
          pathname: '/FinancialAccountLienLevyUpdate',
          state: { withHoldSearchResult, values: props.values, payloadPayees: props.payloadPayees, tableData: props.tableData }
        });
      } else if (claimsReceivableData.activityTypeCode === 'GL') {
        props.history.push({
          pathname: '/FinancialAccountGrossLevelUpdate',
          state: { claimsReceivableData, values: props.values, payloadPayees: props.payloadPayees, tableData: props.tableData }
        });
      } else if (claimsReceivableData.activityTypeCode === 'AP') {
        const advancePaymentData = claimsReceivableData;
        props.history.push({
          pathname: '/AdvancePayment',
          state: { advancePaymentData, values: props.values, payloadPayees: props.payloadPayees, tableData: props.tableData }
        });
      } else {
        props.history.push({
          pathname: '/FinancialAccountGrossLevelUpdate',
          state: { claimsReceivableData, values: props.values, payloadPayees: props.payloadPayees, tableData: props.tableData }
        });
      }
    }
  }

  /* On click of table row call API to fetch Claims Receivable data by passing FCN number for corresponding row click */
  const editRow = row => event => {
    setPTypeVal(row.payeePayerTypeCode);
    setReasonVal(row.reasonCode);
    onRowClick(row.receivableFCN);
    const valuetoredirect = redirect + 1;
    setRedirect(valuetoredirect);
  };
  const formatSearchCriteria = (row) => {
    return (row.receivableFCN);
  };
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          let field, targetField;
          if (d.length === 1) {
            field = each.accountReceivableReasonCode;
            targetField = 'accountReceivableReasonCodeDesc';
          } else {
            field = each.reasonCode;
            targetField = 'reasonCodeDesc';
          }
          // getting respective record from dropdown list which also has desc
          const reasonCode =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => value.code === field
            );

          // updating the record with desc
          each[targetField] =
            reasonCode && reasonCode.length > 0
              ? reasonCode[0].description
              : field || '';

          // getting respective record from dropdown list which also has desc
          const payeePayerTypeCode =
            props.dropdowns &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'] &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'].filter(
              value => value.code === each.payeePayerTypeCode
            );

          each.payeePayerTypeCodeDesc =
            payeePayerTypeCode && payeePayerTypeCode.length > 0
              ? payeePayerTypeCode[0].description
              : each.payeePayerTypeCode ? each.payeePayerTypeCode : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  const headCells = props.tableData && props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;

  return (
    <TableComponent
      print={props.print}
      fixedTable
      pathTo='/FinancialAccountGrossLevelUpdate?data='
      formatSearchCriteria={formatSearchCriteria}
      headCells={headCells}
      tableData={props.tableData ? getTableData(props.tableData) : []}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[1].id}
      order={'desc'}
      isSearch={true}
    />
  );
}
export default withRouter(AccountReceivableSearchResultsTable);
