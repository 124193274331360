/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import TableComponent from "../../../SharedModules/Table/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../SharedModules/TabPanel/TabPanel";
import NoSaveMessage from "../../../SharedModules/Errors/NoSaveMessage";
import DialogActions from "@material-ui/core/DialogActions";
import AppBar from "@material-ui/core/AppBar";
import {
  checkingDecimal,
  getLongDescription,
  removeLeadingTrailingSpaces
} from "../../../SharedModules/CommonUtilities/commonUtilities";
import {
  DialogTitle,
  DialogContent
} from "../../../SharedModules/Dialog/DialogUtilities";
import { Prompt } from "react-router-dom";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from "../../../SharedModules/Errors/SuccessMessage";
import * as AppConstants from "../../../SharedModules/AppConstants";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import moment from "moment";
import * as ErrorConstants from "../Components/ErrorConstants";
import {
  fiscalPendAddAction,
  FiscalPendIDGenerator,
  fiscalPendDeleteAction,
  fiscalPendUpdateAction,
  resetState,
  fiscalPendAddToEdit,
  ficalePendIDResest
} from "../Store/Actions/FiscalPendAddActions";
import {
  getFiscalPendRecordAction,
  getDropdownDataAction,
  getMapIDList,
  getBenefitPlanIDList,
  getAuditLogDetailsAction,
  getAuditLogRowDetailsAction
} from "../Store/Actions/FiscalPendCriteriaSearchActions";

import {
  compareTwoDatesGreaterThanOrEqual,
  validateDateMinimumValue,
  generateUUID,
  compareTwoDates,
  getUTCTimeStamp
} from "../../../SharedModules/DateUtilities/DateUtilities";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import dropdownCriteria from "./FiscalPendAddUpdate.json";
import Notes from "../../../SharedModules/Notes/Notes";
import NavHOC from "../../../SharedModules/Navigation/NavHOC";
import Footer from "../../../SharedModules/Layout/footer";
import numeral from "numeral";
import Radio from "@material-ui/core/Radio";
import AuditTableComponent from "../../../SharedModules/AuditLog/AuditTableComponent";
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from "../../../SharedModules/Dropdowns/NativeDropDown";

const headCells = [
  {
    id: "billingProviderIdTypeDesc",
    disablePadding: false,
    label: "Prov ID Type",
    enableHyperLink: false,
    width: "13%",
    fontSize: 14,
    isDropDown: true
  },
  {
    id: "billingProviderId",
    disablePadding: false,
    label: "Prov ID",
    enableHyperLink: false,
    width: "12%",
    fontSize: 14
  },
  {
    id: "billingProviderTypeDesc",
    disablePadding: false,
    label: "Prov Type",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isDropDown: true
  },
  {
    id: "claimTypeDesc",
    disablePadding: false,
    label: "Claim Type",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isDropDown: true
  },
  {
    id: "benefitPlan",
    disablePadding: false,
    label: "Benefit Plan",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isTooltip: true,
    isTitle: "benefitPlanDesc"
  },
  {
    id: "COSDesc",
    disablePadding: false,
    label: "COS",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isTooltip: true,
    isTitle: "cosCodeLongDesc"
  },
  {
    id: "mapSetId",
    disablePadding: false,
    label: "Mapset ID",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isTooltip: true,
    isTitle: "mapSetIdDesc"
  },
  {
    id: "dollarCapAmtFormat",
    disablePadding: false,
    label: "Dollar Cap Amt",
    enableHyperLink: false,
    isBalance: true,
    width: "15%",
    fontSize: 14
  },
  {
    id: "rank",
    disablePadding: false,
    label: "Rank",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14
  }
];
const editHeadCells = [
  {
    id: "billingProviderIdTypeDesc",
    disablePadding: false,
    label: "Prov ID Type",
    enableHyperLink: false,
    width: "11%",
    fontSize: 14,
    isDropDown: true
  },
  {
    id: "billingProviderId",
    disablePadding: false,
    label: "Prov ID",
    enableHyperLink: false,
    width: "11%",
    fontSize: 14
  },
  {
    id: "billingProviderTypeDesc",
    disablePadding: false,
    label: "Prov Type",
    enableHyperLink: false,
    width: "9%",
    fontSize: 14,
    isDropDown: true
  },
  {
    id: "claimTypeDesc",
    disablePadding: false,
    label: "Claim Type",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isDropDown: true
  },
  {
    id: "benefitPlan",
    disablePadding: false,
    label: "Benefit Plan",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isTooltip: true,
    isTitle: "benefitPlanDesc"
  },
  {
    id: "COSDesc",
    disablePadding: false,
    label: "COS",
    enableHyperLink: false,
    width: "9%",
    fontSize: 14,
    isTooltip: true,
    isTitle: "cosCodeLongDesc"
  },
  {
    id: "mapSetId",
    disablePadding: false,
    label: "Mapset ID",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14,
    isTooltip: true,
    isTitle: "mapSetIdDesc"
  },
  {
    id: "dollarCapAmtFormat",
    disablePadding: false,
    label: "Dollar Cap Amt",
    enableHyperLink: false,
    isBalance: true,
    width: "12%",
    fontSize: 14
  },
  {
    id: "rank",
    disablePadding: false,
    label: "Rank",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14
  },
  {
    id: "voidDate",
    disablePadding: false,
    label: "Void",
    enableHyperLink: false,
    width: "10%",
    fontSize: 14
  }
];
export default NavHOC({
  Wrapped: FiscalPendCriteriaAdd,
  action: getFiscalPendRecordAction,
  selector: "fiscalPendSearchState.searchRecord",
  url: "/FiscalPendCriteriaEdit",
  actionKey: "searchResult"
});
function FiscalPendCriteriaAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector((state) => state.sharedState.logInUserID);

  const dropdown = useSelector(
    (state) => state.fiscalPendSearchState.dropdownData
  );
  const mapIdDropdown = useSelector((state) => state.fundCodeState.mapIdData);
  const benefitPlanIdDropdown = useSelector(
    (state) => state.fundCodeState.benefitPlanIdData
  );
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [mainTableDetails, setMainTableDetails] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [fundCodeDropDown, setFundCodeDropDown] = React.useState([]);
  const [billingProviderTypeDropdown, setBillingProviderTypeDropdown] =
    React.useState([]);
  const [billingProviderIdTypeDropdown, setBillingProviderIdTypeDropdown] =
    React.useState([]);
  const [claimTypeDropdown, setClaimTypeDropdown] = React.useState([]);
  const [COSDropdown, setCOSDropdown] = React.useState([]);
  const [mapSetIdDropdown, setMapSetIdDropdown] = React.useState([]);
  const [benefitPlanDropdown, setBenefitPlanDropdown] = React.useState([]);
  const [desError, setDesError] = React.useState(false);
  const [desErrorDes, setDesErrorDes] = React.useState("");
  const [beginDateError, setBeginDateError] = React.useState(false);
  const [endDateError, setEndDateError] = React.useState(false);
  const [beginDateErrorDes, setBeginDateErrorDes] = React.useState("");
  const [endDateErrorDes, setEndDateErrorDes] = React.useState("");
  const [rankError, setRankError] = React.useState(false);
  const [rankErrorDes, setRankErrorDes] = React.useState("");
  const [dialogueRankError, setDialogueRankError] = React.useState(false);
  const [dialogueRankErrorDes, setDialogueRankErrorDes] = React.useState("");
  const [dollarCapAmtError, setDollarCapAmtError] = React.useState(false);
  const [dollarCapAmtErrorDes, setDollarCapAmtErrorDes] = React.useState("");
  const [billingProviderIdError, setBillingProviderIdError] =
    React.useState(false);
  const [billingProviderIdErrorDes, setBillingProviderIdErrorDes] =
    React.useState("");
  const [billingProviderTypeError, setBillingProviderTypeError] =
    React.useState(false);
  const [billingProviderTypeErrorDes, setBillingProviderTypeErrorDes] =
    React.useState("");
  const [billingProviderIdTypeError, setBillingProviderIdTypeError] =
    React.useState(false);
  const [billingProviderIdTypeErrorDes, setBillingProviderIdTypeErrorDes] =
    React.useState("");
  const [dialogueVoid, setDialogueVoid] = React.useState(false);
  const [mainVoid, setMainVoid] = React.useState(false);
  const [mapSetIdError, setMapSetIdError] = React.useState(false);
  const [mapSetIdErrorDes, setMapSetIdErrorDes] = React.useState("");
  const [modifierError, setModifierError] = React.useState(false);
  const [modifierErrorDes, setModifierErrorDes] = React.useState("");
  const [editFlag, setEditFlag] = React.useState(false);
  const [rowFiscalPendData, setRowFiscalPendData] = React.useState([]);
  const [rowEditData, setRowEditData] = React.useState({});
  const [editPageFlag, setEditPageFlag] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(false);
  const [pendInd, setPendInd] = React.useState("N");
  const [dataToDel, setDataToDel] = React.useState(false);
  const [fiscalPendDeleteData, setFiscalPendDeleteData] = React.useState([]);
  const dispatch = useDispatch();
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [disableNotes, setDisableNotes] = React.useState(true);
  const fiscalPendAddToEditAction = (values) => {
    dispatch(fiscalPendAddToEdit(values));
  };
  let searchResultAfterAdd = useSelector(
    (state) => state.fiscalPendState.searchRecord
  );
  const fiscalPendDeleteResults = useSelector(
    (state) => state.fiscalPendState.deleteResult
  );
  const getDropdownData = (dropdownValues) => {
    dispatch(getDropdownDataAction(dropdownValues));
  };
  const getMapIDData = () => {
    dispatch(getMapIDList("FFP"));
  };
  const getBenefitPlanIDData = () => {
    dispatch(getBenefitPlanIDList());
  };
  const onFiscalPendAdd = (value) => {
    dispatch(fiscalPendAddAction(value));
  };
  const onFiscalPendUpdate = (value) => {
    dispatch(fiscalPendUpdateAction(value));
  };
  let saveResult = useSelector(
    (state) => state.fiscalPendState.resultOfFiscalPendAdd
  );
  let saveResultUpdate = useSelector(
    (state) => state.fiscalPendState.resultOfFiscalPendUpdate
  );
  const getFiscalPendID = () => {
    dispatch(FiscalPendIDGenerator());
  };
  let generatedID = useSelector((state) => state.fiscalPendState.fiscalPendID);
  const resetStateValues = () => {
    dispatch(resetState());
  };
  const [deleteDetailsState, setDeleteDetailsState] = React.useState({});
  const [antSwitchName, setAntSwitchName] = React.useState("No");
  const [auditId, setAuditId] = React.useState("");
  const [auditIdTimeStamp, setAuditIdTimeStamp] = React.useState("");

  // Notes
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [voidDateRef, setVoidDateRef] = React.useState("");

  const toPrintRef = useRef();
  let requestObject = {
    auditUserID: logInUserID,
    auditTimeStamp: new Date(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: new Date(),
    versionNo: "0",
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    fiscalPendDetailsVO: {
      auditUserID: "C5107292",
      auditTimeStamp: "2020-01-22T09:14:39.457+0000",
      addedAuditUserID: "C5107292",
      addedAuditTimeStamp: "2020-01-22T04:54:58.000+0000",
      versionNo: "3",
      dbRecord: false,
      sortColumn: null,
      fiscalPendID: "9",
      description: "TEST",
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      fundCode: "21",
      beginDate: "01/14/2020",
      endDate: "01/24/2020",
      financialTxnIndicator: "N",
      voidDateInd: "N",
      fiscalPendProcessingIndicator: "N",
      rankNumber: "1",
      voidDate: null,
      noteSet: null,
      showVoids: false,
      notesWithCount: "0"
    },
    fiscalPendDetails: [
      {
        auditUserID: "C5107292",
        auditTimeStamp: "2020-01-22T09:14:39.457+0000",
        addedAuditUserID: "C5107292",
        addedAuditTimeStamp: "2020-01-22T04:55:05.106+0000",
        versionNo: "0",
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        providerTypeCode: "01",
        providerTaxonomyCode: null,
        claimTypeCode: "1",
        benefitPlanID: "Medicaid Benefit plan",
        providerAltIDTypeCode: "CLIA",
        providerAltID: null,
        categoryOfServiceCode: null,
        procedureCode: null,
        capitationAmount: null,
        rankNumber: "100",
        serviceLocationNumKey: "1",
        providerSystemID: null,
        firstModifierCode: "1",
        secondModifierCode: "2",
        thirdModifierCode: "3",
        fourthModifierCode: "4",
        firstModifierCodeDescription: "5",
        secondModifierCodeDescription: null,
        thirdModifierCodeDescription: null,
        fourthModifierCodeDescription: null,
        mapsetID: "54",
        printRAIndicator: "N",
        voidDateInd: "N",
        daysToPend: null,
        voidDate: null,
        auditUserName: null,
        addedAuditUserName: null,
        providerName: null,
        procedureCodeDescription: null,
        providerIdMaskFlag: false
      }
    ],
    fiscalPendCriterias: [
      {
        auditUserID: "C5107292",
        auditTimeStamp: "2020-01-22T09:14:39.457+0000",
        addedAuditUserID: "C5107292",
        addedAuditTimeStamp: "2020-01-22T04:55:05.106+0000",
        versionNo: "0",
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        providerTypeCode: "01",
        providerTaxonomyCode: null,
        claimTypeCode: "1",
        benefitPlanID: "Medicaid Benefit plan",
        providerAltIDTypeCode: "CLIA",
        providerAltID: null,
        categoryOfServiceCode: null,
        procedureCode: null,
        capitationAmount: null,
        rankNumber: "100",
        serviceLocationNumKey: "1",
        providerSystemID: null,
        firstModifierCode: "1",
        secondModifierCode: "2",
        thirdModifierCode: "3",
        fourthModifierCode: "4",
        firstModifierCodeDescription: "5",
        secondModifierCodeDescription: null,
        thirdModifierCodeDescription: null,
        fourthModifierCodeDescription: null,
        mapsetID: "54",
        printRAIndicator: "N",
        voidDateInd: "N",
        daysToPend: null,
        voidDate: null,
        auditUserName: null,
        addedAuditUserName: null,
        providerName: null,
        procedureCodeDescription: null,
        providerIdMaskFlag: false
      }
    ],
    deleteFiscalPendCriterias: null,
    fiscalPendCriteriaVO: null,
    fiscalPendDetailsMap: {},
    renderFiscalPendCriteriaSection: false,
    renderFiscalPendSubAddHeader: false,
    renderFiscalPendSubEditHeader: false,
    renderFiscalPendSubDetailsDelete: false,
    addModeFlag: false,
    noteSetVO: notesInput
  };
  const userInquiryPrivileges = !global.globalIsReadOnly();

  useEffect(() => {
    getDropdownData(dropdownCriteria);
    getMapIDData();
    getBenefitPlanIDData();
    resetStateValues();
    searchResultAfterAdd = null;
    saveResult = [];
    saveResultUpdate = [];
    if (props.history.location.state) {
      if (props.history.location.state.saveMessage !== undefined) {
        saveResult = [props.history.location.state.saveMessage];
        setSuccessMessages(saveResult);
        setFocusCheck(false);
        setEditPageFlag(true);
      } else {
        saveResult = [];
      }
      if (props.history.location.state !== undefined) {
        setEditPageFlag(true);
      }
      if (props.history.location.state.searchResult !== undefined) {
        setVoidDateRef(
          props.history.location.state.searchResult.fiscalPendDetailsVO?.voidDate
        );
        requestObject = JSON.parse(
          JSON.stringify(props.history.location.state.searchResult)
        );
        const dummyVariable = JSON.parse(
          JSON.stringify(props.history.location.state.searchResult)
        );
        // notes data in edit
        const searchData = JSON.parse(
          JSON.stringify(props.history.location.state.searchResult)
        );
        if (searchData.noteSetVO) {
          const noteSetVO = searchData.noteSetVO;
          const notesTable = searchData.noteSetVO.notesList;
          setNotesInput({
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: noteSetVO.addedAuditUserID
              ? noteSetVO.addedAuditUserID
              : logInUserID,
            addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
              ? noteSetVO.addedAuditTimeStamp
              : getUTCTimeStamp(),
            versionNo: noteSetVO.versionNo,
            dbRecord: noteSetVO.dbRecord,
            sortColumn: noteSetVO.sortColumn,
            tableName: noteSetVO.tableName,
            noteSetSK: noteSetVO.noteSetSK,
            noteSourceName: noteSetVO.noteSourceName,
            notesList: notesTable,
            globalNotesList: [],
            checkAll: noteSetVO.checkAll,
            addNewLinkRender: noteSetVO.addNewLinkRender,
            filterLinkRender: noteSetVO.filterLinkRender,
            printLinkRender: noteSetVO.printLinkRender,
            completeNotesList: []
          });

          setNotesTableData(notesTable);
        }
        setSelectedBeginDate(
          new Date(dummyVariable.fiscalPendDetailsVO?.beginDate)
        );
        setSelectedEndDate(new Date(dummyVariable.fiscalPendDetailsVO.endDate));
        setAuditId(dummyVariable.fiscalPendDetailsVO.addedAuditUserID);
        setAuditIdTimeStamp(
          dummyVariable.fiscalPendDetailsVO.addedAuditTimeStamp
        );
        setMainValue({
          auditUserID: logInUserID,
          auditTimeStamp: dummyVariable.fiscalPendDetailsVO.auditTimeStamp,
          addedAuditUserID: dummyVariable.fiscalPendDetailsVO.addedAuditUserID,
          addedAuditTimeStamp:
            dummyVariable.fiscalPendDetailsVO.addedAuditTimeStamp,
          versionNo: dummyVariable.fiscalPendDetailsVO.versionNo,
          fiscalPendId: dummyVariable.fiscalPendDetailsVO.fiscalPendID,
          description: dummyVariable.fiscalPendDetailsVO.description,
          fundCode:
            dummyVariable.fiscalPendDetailsVO.fundCode !== "" &&
            dummyVariable.fiscalPendDetailsVO.fundCode !== null
              ? dummyVariable.fiscalPendDetailsVO.fundCode
              : DEFAULT_DD_VALUE,
          rank: dummyVariable.fiscalPendDetailsVO.rankNumber,
          beginDate: dummyVariable.fiscalPendDetailsVO.beginDate,
          endDate: dummyVariable.fiscalPendDetailsVO.endDate,
          include: dummyVariable.fiscalPendDetailsVO.financialTxnIndicator,
          fiscalPendProcessingIndicator:
            dummyVariable.fiscalPendDetailsVO.fiscalPendProcessingIndicator,
          void: dummyVariable.fiscalPendDetailsVO.voidDateInd
        });
        if (dummyVariable.fiscalPendDetailsVO.voidDateInd === "Y") {
          setMainVoid(true);
        } else {
          setMainVoid(false);
        }
        if (
          dummyVariable.fiscalPendDetailsVO.fiscalPendProcessingIndicator ===
          "Y"
        ) {
          setDataToEdit(true);
          setErrorMessages([ErrorConstants.FISCAL_PEND]);
          setPendInd("Y");
        } else {
          setDataToEdit(false);
        }
        if (
          compareTwoDates(
            new Date(dummyVariable.fiscalPendDetailsVO.beginDate),
            new Date()
          )
        ) {
          setDataToDel(true);
        } else {
          setDataToDel(false);
        }
        const dummytableValues = [];
        if (dummyVariable.fiscalPendCriterias !== null) {
          dummyVariable.fiscalPendCriterias.map((varData) => {
            const sampleObj = {};
            sampleObj.auditUserID = varData.auditUserID;
            sampleObj.auditTimeStamp = varData.auditTimeStamp;
            sampleObj.addedAuditUserID = varData.addedAuditUserID;
            sampleObj.addedAuditTimeStamp = varData.addedAuditTimeStamp;
            sampleObj.uuid = generateUUID();
            sampleObj.id = generateUUID();
            sampleObj.isDBRecord = varData.isDBRecord;
            sampleObj.billingProviderIdType =
              varData.providerAltIDTypeCode !== "" &&
              varData.providerAltIDTypeCode !== null
                ? varData.providerAltIDTypeCode
                : DEFAULT_DD_VALUE;
            sampleObj.billingProviderId =
              varData.providerAltID !== null ? varData.providerAltID : "";
            sampleObj.billingProviderType =
              varData.providerTypeCode !== "" &&
              varData.providerTypeCode !== null
                ? varData.providerTypeCode
                : DEFAULT_DD_VALUE;
            sampleObj.rank =
              varData.rankNumber !== null ? varData.rankNumber : "";
            sampleObj.billingTaxonomy =
              varData.providerTaxonomyCode !== null
                ? varData.providerTaxonomyCode
                : "";
            sampleObj.claimType =
              varData.claimTypeCode !== "" && varData.claimTypeCode !== null
                ? varData.claimTypeCode
                : DEFAULT_DD_VALUE;
            sampleObj.benefitPlan =
              varData.benefitPlanID !== "" && varData.benefitPlanID !== null
                ? varData.benefitPlanID
                : "";
            sampleObj.benefitPlanDesc =
              varData.benefitPlanID !== "" && varData.benefitPlanID !== null
                ? varData.benefitPlanID + "-" + varData.benefitPlanID
                : "";
            sampleObj.COS =
              varData.categoryOfServiceCode !== "" &&
              varData.categoryOfServiceCode !== null
                ? varData.categoryOfServiceCode
                : DEFAULT_DD_VALUE;
            sampleObj.dollarCapAmt =
              varData.capitationAmount !== null
                ? varData.capitationAmount : "";
				sampleObj.dollarCapAmtFormat =
              varData.capitationAmount !== null
                ? numeral(varData.capitationAmount).format("0,0.00")
                : "";
            sampleObj.daysToPend =
              varData.daysToPend !== null ? varData.daysToPend : "";
            sampleObj.procedureCode =
              varData.procedureCode !== null ? varData.procedureCode : "";
            sampleObj.modifiers_1 = varData.firstModifierCode
              ? varData.firstModifierCode
              : "";
            sampleObj.modifiers_2 = varData.secondModifierCode
              ? varData.secondModifierCode
              : "";
            sampleObj.modifiers_3 = varData.thirdModifierCode
              ? varData.thirdModifierCode
              : "";
            sampleObj.modifiers_4 = varData.fourthModifierCode
              ? varData.fourthModifierCode
              : "";
            sampleObj.printRA = varData.printRAIndicator;
            sampleObj.void = varData.voidDateInd;
            if (varData.voidDate !== null) {
              sampleObj.void = "Y";
              sampleObj.voidDate = varData.voidDate;
            } else {
              sampleObj.void = "N";
              sampleObj.voidDate = null;
            }
            sampleObj.mapSetId =
              varData.mapsetID !== "" && varData.mapsetID !== null
                ? varData.mapsetID
                : "";
            sampleObj.fiscalPendCriteriaSeqNum =
              varData.fiscalPendCriteriaSeqNum;
            dummytableValues.push(sampleObj);
          });
        }
        setMainTableDetails(dummytableValues);
        setDeleteDetailsState(JSON.parse(JSON.stringify(dummytableValues)));
      }
    } else {
      dispatch(ficalePendIDResest());
      getFiscalPendID();
    }
    generatedID = "";
    onDropdowns([
      Dropdowns.PROVIDER_ID_TYPE,
      Dropdowns.PROVIDER_TYPE_CODE,
      Dropdowns.CLAIM_TYPE,
      Dropdowns.COS
    ]);
  }, []);

  useEffect(() => {
    if (generatedID) {
      setMainValue({ ...mainValue, fiscalPendId: generatedID });
    }
  }, [generatedID]);
  useEffect(() => {
    if (
      searchResultAfterAdd &&
      searchResultAfterAdd.systemFailure === undefined
    ) {
      if (props.location.pathname === "/FiscalPendCriteriaEdit") {
        requestObject = JSON.parse(JSON.stringify(searchResultAfterAdd));
        setVoidDateRef(searchResultAfterAdd.fiscalPendDetailsVO.voidDate);
        const dummyVariable = JSON.parse(JSON.stringify(searchResultAfterAdd));
        const searchData = JSON.parse(JSON.stringify(searchResultAfterAdd));
        if (searchData.noteSetVO) {
          const noteSetVO = searchData.noteSetVO;
          const notesTable = searchData.noteSetVO.notesList;
          setNotesInput({
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: noteSetVO.addedAuditUserID
              ? noteSetVO.addedAuditUserID
              : logInUserID,
            addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
              ? noteSetVO.addedAuditTimeStamp
              : getUTCTimeStamp(),
            versionNo: noteSetVO.versionNo,
            dbRecord: noteSetVO.dbRecord,
            sortColumn: noteSetVO.sortColumn,
            tableName: noteSetVO.tableName,
            noteSetSK: noteSetVO.noteSetSK,
            noteSourceName: noteSetVO.noteSourceName,
            notesList: notesTable,
            globalNotesList: [],
            checkAll: noteSetVO.checkAll,
            addNewLinkRender: noteSetVO.addNewLinkRender,
            filterLinkRender: noteSetVO.filterLinkRender,
            printLinkRender: noteSetVO.printLinkRender,
            completeNotesList: []
          });

          setNotesTableData(notesTable);
        }
        setSelectedBeginDate(
          new Date(dummyVariable.fiscalPendDetailsVO.beginDate)
        );
        setSelectedEndDate(new Date(dummyVariable.fiscalPendDetailsVO.endDate));
        setMainValue({
          auditUserID: logInUserID,
          auditTimeStamp: dummyVariable.fiscalPendDetailsVO.auditTimeStamp,
          addedAuditUserID: dummyVariable.fiscalPendDetailsVO.addedAuditUserID,
          addedAuditTimeStamp:
            dummyVariable.fiscalPendDetailsVO.addedAuditTimeStamp,
          versionNo: dummyVariable.fiscalPendDetailsVO.versionNo,
          fiscalPendId: dummyVariable.fiscalPendDetailsVO.fiscalPendID,
          description: dummyVariable.fiscalPendDetailsVO.description,
          fundCode: dummyVariable.fiscalPendDetailsVO.fundCode
            ? dummyVariable.fiscalPendDetailsVO.fundCode
            : DEFAULT_DD_VALUE,
          rank: dummyVariable.fiscalPendDetailsVO.rankNumber,
          beginDate: dummyVariable.fiscalPendDetailsVO.beginDate,
          endDate: dummyVariable.fiscalPendDetailsVO.endDate,
          include: dummyVariable.fiscalPendDetailsVO.financialTxnIndicator,
          fiscalPendProcessingIndicator:
            dummyVariable.fiscalPendDetailsVO.fiscalPendProcessingIndicator,
          void: dummyVariable.fiscalPendDetailsVO.voidDateInd
        });
        if (dummyVariable.fiscalPendDetailsVO.voidDateInd === "Y") {
          setMainVoid(true);
        } else {
          setMainVoid(false);
        }
        if (
          dummyVariable.fiscalPendDetailsVO.fiscalPendProcessingIndicator ===
          "Y"
        ) {
          setDataToEdit(true);
          setErrorMessages([ErrorConstants.FISCAL_PEND]);
          setPendInd("Y");
        } else {
          setDataToEdit(false);
        }
        if (
          compareTwoDates(
            new Date(dummyVariable.fiscalPendDetailsVO.beginDate),
            new Date()
          )
        ) {
          setDataToDel(true);
        } else {
          setDataToDel(false);
        }
        const dummytableValues = [];
        if (dummyVariable.fiscalPendCriterias !== null) {
          dummyVariable.fiscalPendCriterias.map((varData) => {
            const sampleObj = {};
            sampleObj.fiscalPendCriteriaSeqNum =
              varData.fiscalPendCriteriaSeqNum;
            sampleObj.auditUserID = varData.auditUserID;
            sampleObj.auditTimeStamp = varData.auditTimeStamp;
            sampleObj.addedAuditUserID = varData.addedAuditUserID;
            sampleObj.addedAuditTimeStamp = varData.addedAuditTimeStamp;
            sampleObj.uuid = generateUUID();
            sampleObj.id = generateUUID();
            sampleObj.billingProviderIdType =
              varData.providerAltIDTypeCode !== "" &&
              varData.providerAltIDTypeCode !== null
                ? varData.providerAltIDTypeCode
                : DEFAULT_DD_VALUE;
            sampleObj.billingProviderId =
              varData.providerAltID !== null ? varData.providerAltID : "";
            sampleObj.billingProviderType =
              varData.providerTypeCode !== "" &&
              varData.providerTypeCode !== null
                ? varData.providerTypeCode
                : DEFAULT_DD_VALUE;
            sampleObj.rank =
              varData.rankNumber !== null ? varData.rankNumber : "";
            sampleObj.billingTaxonomy =
              varData.providerTaxonomyCode !== null
                ? varData.providerTaxonomyCode
                : "";
            sampleObj.claimType =
              varData.claimTypeCode !== "" && varData.claimTypeCode !== null
                ? varData.claimTypeCode
                : DEFAULT_DD_VALUE;
            sampleObj.benefitPlan =
              varData.benefitPlanID !== "" && varData.benefitPlanID !== null
                ? varData.benefitPlanID
                : "";
            sampleObj.COS =
              varData.categoryOfServiceCode !== "" &&
              varData.categoryOfServiceCode !== null
                ? varData.categoryOfServiceCode
                : DEFAULT_DD_VALUE;
            sampleObj.dollarCapAmt =
              varData.capitationAmount !== null
                ? (varData.capitationAmount) : "";
				sampleObj.dollarCapAmtFormat =
              varData.capitationAmount !== null
                ? numeral(varData.capitationAmount).format("0,0.00")
                : "";
            sampleObj.daysToPend =
              varData.daysToPend !== null ? varData.daysToPend : "";
            sampleObj.procedureCode =
              varData.procedureCode !== null ? varData.procedureCode : "";
            sampleObj.modifiers_1 = varData.firstModifierCode
              ? varData.firstModifierCode
              : "";
            sampleObj.modifiers_2 = varData.secondModifierCode
              ? varData.secondModifierCode
              : "";
            sampleObj.modifiers_3 = varData.thirdModifierCode
              ? varData.thirdModifierCode
              : "";
            sampleObj.modifiers_4 = varData.fourthModifierCode
              ? varData.fourthModifierCode
              : "";
            sampleObj.printRA = varData.printRAIndicator;
            sampleObj.void = varData.voidDateInd;
            if (varData.voidDate !== null) {
              sampleObj.void = "Y";
              sampleObj.voidDate = varData.voidDate;
            } else {
              sampleObj.void = "N";
              sampleObj.voidDate = null;
            }
            sampleObj.mapSetId =
              varData.mapsetID !== "" && varData.mapsetID !== null
                ? varData.mapsetID
                : "";
            if (sampleObj.billingProviderIdType !== DEFAULT_DD_VALUE) {
              billingProviderIdTypeDropdown.map((var2) => {
                if (sampleObj.billingProviderIdType === var2.code) {
                  sampleObj.billingProviderIdTypeDesc = var2.description;
                }
              });
            } else {
              sampleObj.billingProviderIdTypeDesc = DEFAULT_DD_VALUE;
            }
            if (sampleObj.billingProviderType !== DEFAULT_DD_VALUE) {
              billingProviderTypeDropdown.map((var2) => {
                if (sampleObj.billingProviderType === var2.code) {
                  sampleObj.billingProviderTypeDesc = var2.description;
                }
              });
            } else {
              sampleObj.billingProviderTypeDesc = DEFAULT_DD_VALUE;
            }
            if (sampleObj.claimType !== DEFAULT_DD_VALUE) {
              claimTypeDropdown.map((var2) => {
                if (sampleObj.claimType === var2.code) {
                  sampleObj.claimTypeDesc = var2.description;
                }
              });
            } else {
              sampleObj.claimTypeDesc = DEFAULT_DD_VALUE;
            }
            if (sampleObj.COS !== DEFAULT_DD_VALUE) {
              COSDropdown.map((var2) => {
                if (sampleObj.COS === var2.code) {
                  sampleObj.COSDesc = var2.description;
                  sampleObj.cosCodeLongDesc = var2.longDescription;
                }
              });
            } else {
              sampleObj.COSDesc = null;
            }
            if (sampleObj.benefitPlan !== DEFAULT_DD_VALUE) {
              benefitPlanDropdown.map((var2) => {
                if (sampleObj.benefitPlan === var2.code) {
                  sampleObj.benefitPlanDesc = `${var2.code}-${var2.description}`;
                }
              });
            } else {
              sampleObj.benefitPlanDesc = "";
              sampleObj.benefitPlan = "";
            }
            if (sampleObj.mapSetId !== DEFAULT_DD_VALUE) {
              mapSetIdDropdown.map((var2) => {
                if (sampleObj.mapSetId === var2.code) {
                  sampleObj.mapSetIdDesc = `${sampleObj.mapSetId}-${var2.description}`;
                }
              });
            } else {
              sampleObj.mapSetIdDesc = null;
              sampleObj.mapSetId = "";
            }
            dummytableValues.push(sampleObj);
          });
        }
        setMainTableDetails(dummytableValues);
        setDeleteDetailsState(JSON.parse(JSON.stringify(dummytableValues)));
      } else {
        props.history.push({
          pathname: "/FiscalPendCriteriaEdit",
          state: {
            searchResult: searchResultAfterAdd,
            saveMessage: ErrorConstants.SAVESUCCESS,
            values: ValuesFromFiscPend,
            isFromAdd: true
          }
        });
      }
    }
    if (
      searchResultAfterAdd &&
      searchResultAfterAdd.systemFailure !== undefined
    ) {
      setErrorMessages([ErrorConstants.SYSTEMERROR]);
    }
  }, [searchResultAfterAdd]);
  useEffect(() => {
    if (saveResult !== [] && saveResult !== undefined) {
      if (saveResult.respcode) {
        if (saveResult.respcode === "01") {
          setAllowNavigation(false);
          setSuccessMessages([ErrorConstants.SAVESUCCESS]);
          setFocusCheck(false);
          setErrorMessages([]);
          fiscalPendAddToEditAction(
            "/" + mainValue.fiscalPendId + "/" + AppConstants.DEFAULT_LOB_VALUE
          );
        }
        if (saveResult.respcode !== "01") {
          const tempArray = [];

          tempArray.push(saveResult.respdesc);
          setErrorMessages(tempArray);
        }
      }
      if (saveResult.systemFailure) {
        const tempArray = [];

        tempArray.push(ErrorConstants.SYSTEMERROR);
        setErrorMessages(tempArray);
      }
    }
  }, [saveResult]);
  useEffect(() => {
    if (
      fiscalPendDeleteResults !== [] &&
      fiscalPendDeleteResults !== undefined
    ) {
      if (fiscalPendDeleteResults.respcode) {
        if (fiscalPendDeleteResults.respcode !== "01") {
          const tempArray = [];
          tempArray.push(fiscalPendDeleteResults.respdesc);
          setErrorMessages(tempArray);
        }
      }
      if (fiscalPendDeleteResults.systemFailure) {
        const tempArray = [];
        tempArray.push(ErrorConstants.SYSTEMERROR);
        setErrorMessages(tempArray);
      }
    }
  }, [fiscalPendDeleteResults]);
  useEffect(() => {
    if (saveResultUpdate !== [] && saveResultUpdate !== undefined) {
      if (saveResultUpdate.respcode) {
        if (saveResultUpdate.respcode === "01") {
          setAllowNavigation(false);
          setSuccessMessages([saveResultUpdate.respdesc]);
          setFocusCheck(false);
          setErrorMessages([]);
          fiscalPendAddToEditAction(
            "/" + mainValue.fiscalPendId + "/" + AppConstants.DEFAULT_LOB_VALUE
          );
        }
        if (saveResultUpdate.respcode !== "01") {
          const tempArray = [];

          tempArray.push(saveResultUpdate.respdesc);
          setErrorMessages(tempArray);
        }
      }
      if (saveResultUpdate.systemFailure) {
        const tempArray = [];

        tempArray.push(ErrorConstants.SYSTEMERROR);
        setErrorMessages(tempArray);
      }
    }
  }, [saveResultUpdate]);

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 35,
      height: 17,
      padding: 0,
      display: "flex",
      "&$disabled": {
        color: "white"
      }
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(18px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#274463",
          borderColor: theme.palette.primary.main
        }
      }
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: "none"
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {},
    disabled: {}
  }))(Switch);
  const [opnFiscalPendDtl, setOpnFiscalPendDtl] = React.useState(false);
  useEffect(() => {
    if (opnFiscalPendDtl && document.getElementById("form_pop_up")) { document.getElementById("form_pop_up").scrollIntoView(); }
  }, [opnFiscalPendDtl]);
  const [value, setValue] = React.useState({
    billingProviderIdType: DEFAULT_DD_VALUE,
    billingProviderId: "",
    billingProviderType: DEFAULT_DD_VALUE,
    rank: "",
    billingTaxonomy: "",
    claimType: DEFAULT_DD_VALUE,
    benefitPlan: DEFAULT_DD_VALUE,
    COS: DEFAULT_DD_VALUE,
    dollarCapAmt: "",
    daysToPend: "",
    procedureCode: "",
    modifiers_1: "",
    modifiers_2: "",
    modifiers_3: "",
    modifiers_4: "",
    printRA: "N",
    mapSetId: DEFAULT_DD_VALUE,
    void: "N",
    voidDate: ""
  });
  const resetValue = {
    billingProviderIdType: DEFAULT_DD_VALUE,
    billingProviderId: "",
    billingProviderType: DEFAULT_DD_VALUE,
    rank: "",
    billingTaxonomy: "",
    claimType: DEFAULT_DD_VALUE,
    benefitPlan: DEFAULT_DD_VALUE,
    COS: DEFAULT_DD_VALUE,
    dollarCapAmt: "",
    daysToPend: "",
    procedureCode: "",
    modifiers_1: "",
    modifiers_2: "",
    modifiers_3: "",
    modifiers_4: "",
    printRA: "N",
    mapSetId: DEFAULT_DD_VALUE,
    void: "N",
    voidDate: ""
  };
  const [mainValue, setMainValue] = React.useState({
    lob: DEFAULT_DD_VALUE,
    fiscalPendId: "",
    description: null,
    fundCode: DEFAULT_DD_VALUE,
    rank: null,
    beginDate: null,
    endDate: null,
    include: "N",
    fiscalPendProcessingIndicator: "N",
    void: "N",
    voidDate: null
  });

  function RowDeleteClick () {
    if (editPageFlag) {
      Confirm();
    } else {
      deleteConfirm();
    }
  }
  function deleteConfirm () {
    if (rowFiscalPendData != null || rowFiscalPendData !== undefined) {
      let updatedTableDetails = JSON.parse(JSON.stringify(mainTableDetails));
      const frontEndOnlyRecordsToDelete = mainTableDetails.filter(
        (record) => !record.isDBRecord && rowFiscalPendData.includes(record.id)
      );
      updatedTableDetails = updatedTableDetails.filter(
        (record) => !frontEndOnlyRecordsToDelete.some((r) => r.id === record.id)
      );
      const recordsToDelete = updatedTableDetails.filter(
        (record) => record.isDBRecord && rowFiscalPendData.includes(record.id)
      );
      if (recordsToDelete && recordsToDelete.length) {
        if (dataToEdit || dataToDel) {
          setErrorMessages([ErrorConstants.CANNOT_EDIT]);
        } else {
          const databaseRecordsToDelete = recordsToDelete.filter((record) =>
            deleteDetailsState.some((deleteRecord) => deleteRecord.id === record.id)
          );
          updatedTableDetails = updatedTableDetails.filter(
            (record) => !databaseRecordsToDelete.some((r) => r.id === record.id)
          );
          setFiscalPendDeleteData(databaseRecordsToDelete);
        }
      }
      setMainTableDetails(updatedTableDetails);
      setRowFiscalPendData([]);
    }
  }

  const fiscalPendData = requestObject.fiscalPendDetails[0];

  const rowDeleteFiscalPend = (data) => {
    setRowFiscalPendData(data);
  };
  const handleClickOpenDialogueBoxSave = () => {
      const DialogueErrorArray = [];
      if (value.rank === "" || value.rank === null) {
        setDialogueRankError(true);
        setDialogueRankErrorDes(ErrorConstants.RANK_REQ);
        DialogueErrorArray.push(ErrorConstants.RANK_REQ);
      } else {
        setDialogueRankError(false);
      }
      if (value.dollarCapAmt === "" || value.dollarCapAmt === null) {
        setDollarCapAmtError(true);
        setDollarCapAmtErrorDes(ErrorConstants.CAP_REQ);
        DialogueErrorArray.push(ErrorConstants.CAP_REQ);
      } else {
        if (Number(value.dollarCapAmt) < 0) {
          setDollarCapAmtError(true);
          setDollarCapAmtErrorDes(ErrorConstants.CAP_NEGATIVE);
          DialogueErrorArray.push(ErrorConstants.CAP_NEGATIVE);
        } else {
			const decimalCapAmount = value.dollarCapAmt.split(".");
	  if (decimalCapAmount.length === 1) {
		if (decimalCapAmount[0].length > 7) {
			setDollarCapAmtError(true);
          setDollarCapAmtErrorDes(ErrorConstants.DATAINVALID);
          DialogueErrorArray.push(ErrorConstants.DATAINVALID);
		}else {
			setDollarCapAmtError(false);
		}
	  } 
        }
      }
      if (value.billingTaxonomy !== "" && value.billingTaxonomy !== null) {
        if (
          value.billingProviderId === "" ||
          value.billingProviderId === null
        ) {
          setBillingProviderIdError(true);
          setBillingProviderIdErrorDes(ErrorConstants.PROVIDER_ID_REQ);
          DialogueErrorArray.push(ErrorConstants.PROVIDER_ID_REQ);
        } else {
          setBillingProviderIdError(false);
        }
      }
      if (value.billingProviderIdType !== DEFAULT_DD_VALUE) {
        let flag = false;
        if (
          value.billingProviderId === "" ||
          value.billingProviderId === null ||
          value.billingProviderId.trim() === ""
        ) {
          setBillingProviderIdError(true);
          setBillingProviderIdErrorDes(ErrorConstants.PROVIDER_ID_BOTH);
          flag = true;
        }
        if (flag) {
          DialogueErrorArray.push(ErrorConstants.PROVIDER_ID_BOTH);
        }
      }
      if (value.billingProviderId) {
        let flag = false;
        if (value.billingProviderIdType === DEFAULT_DD_VALUE) {
          setBillingProviderIdTypeError(true);
          setBillingProviderIdTypeErrorDes(ErrorConstants.PROVIDER_ID_BOTH);
          flag = true;
        }
        if (flag) {
          DialogueErrorArray.push(ErrorConstants.PROVIDER_ID_BOTH);
        }
      }
      if (value.billingProviderIdType === "XX") {
        if (
          value.billingProviderId === "" ||
          value.billingProviderId === null
        ) {
          setBillingProviderIdError(true);
          setBillingProviderIdErrorDes(ErrorConstants.PROVIDER_ID_AND_TYPE_REQ);
          DialogueErrorArray.push(ErrorConstants.PROVIDER_ID_AND_TYPE_REQ);
        }
      }
      let detailsCount = 0;
      Object.entries(value).forEach(([key, detail]) => {
        if (
          key === "claimType" ||
          key === "billingProviderIdType" ||
          key === "billingProviderId" ||
          key === "billingProviderType" ||
          key === "billingTaxonomy" ||
          key === "benefitPlan" ||
          key === "COS" ||
          key === "daysToPend" ||
          key === "procedureCode" ||
          key === "modifiers_1" ||
          key === "modifiers_2" ||
          key === "modifiers_3" ||
          key === "modifiers_4"
        ) {
          if (
            detail === "" ||
            detail === null ||
            detail === DEFAULT_DD_VALUE
          ) {
            detailsCount++;
          }
        }
      });
      if (value.mapSetId !== DEFAULT_DD_VALUE && detailsCount !== 13) {
        setMapSetIdError(true);
        setMapSetIdErrorDes(ErrorConstants.MAPSET_ERROR);
        DialogueErrorArray.push(ErrorConstants.MAPSET_ERROR);
      }
      value.modifiers_1 = value.modifiers_1 ? value.modifiers_1 : "";
      value.modifiers_2 = value.modifiers_2 ? value.modifiers_2 : "";
      value.modifiers_3 = value.modifiers_3 ? value.modifiers_3 : "";
      value.modifiers_4 = value.modifiers_4 ? value.modifiers_4 : "";

      const modifier =
        value.modifiers_1 +
        value.modifiers_2 +
        value.modifiers_3 +
        value.modifiers_4;
      if (
        modifier !== "" &&
        (value.procedureCode === "" || value.procedureCode === null)
      ) {
        setModifierError(true);
        setModifierErrorDes(ErrorConstants.MODIFIER_PROVIDER_CODE_VALUE);
        DialogueErrorArray.push(ErrorConstants.MODIFIER_PROVIDER_CODE_VALUE);
      } else {
        setModifierError(false);
      }
      if (DialogueErrorArray.length === 0) {
        if (editFlag) {
          const dummyTable = mainTableDetails;
          dummyTable.map((tempmap) => {
            if (tempmap.uuid === value.uuid) {
              tempmap.fiscalPendCriteriaSeqNum = value.fiscalPendCriteriaSeqNum;
              tempmap.audit_flag_edit = true;
              tempmap.auditUserID = logInUserID;
              tempmap.auditTimeStamp = new Date();
              tempmap.addedAuditUserID = value.addedAuditUserID;
              tempmap.addedAuditTimeStamp = value.addedAuditTimeStamp;
              tempmap.billingProviderIdType = value.billingProviderIdType;
              tempmap.billingProviderId = value.billingProviderId;
              tempmap.billingProviderType = value.billingProviderType;
              tempmap.rank = value.rank;
              tempmap.billingTaxonomy = value.billingTaxonomy;
              tempmap.claimType = value.claimType;
              tempmap.benefitPlan = value.benefitPlan;
              tempmap.COS = value.COS;
              tempmap.dollarCapAmt = value.dollarCapAmt;
			  tempmap.dollarCapAmtFormat = value.dollarCapAmt;
              tempmap.daysToPend = value.daysToPend;
              tempmap.procedureCode = value.procedureCode;
              tempmap.modifiers_1 = value.modifiers_1;
              tempmap.modifiers_2 = value.modifiers_2;
              tempmap.modifiers_3 = value.modifiers_3;
              tempmap.modifiers_4 = value.modifiers_4;
              tempmap.printRA = value.printRA;
              tempmap.mapSetId = value.mapSetId;
              tempmap.void = value.void;
              if (tempmap.void === "Y") {
                tempmap.voidDate = moment().format("L");
              } else {
                tempmap.voidDate = null;
              }
              if (tempmap.billingProviderIdType !== DEFAULT_DD_VALUE) {
                billingProviderIdTypeDropdown.map((var2) => {
                  if (tempmap.billingProviderIdType === var2.code) {
                    tempmap.billingProviderIdTypeDesc = var2.description;
                  }
                });
              } else {
                tempmap.billingProviderIdTypeDesc = DEFAULT_DD_VALUE;
              }
              if (tempmap.billingProviderType !== DEFAULT_DD_VALUE) {
                billingProviderTypeDropdown.map((var2) => {
                  if (tempmap.billingProviderType === var2.code) {
                    tempmap.billingProviderTypeDesc = var2.description;
                  }
                });
              } else {
                tempmap.billingProviderTypeDesc = DEFAULT_DD_VALUE;
              }
              if (tempmap.claimType !== DEFAULT_DD_VALUE) {
                claimTypeDropdown.map((var2) => {
                  if (tempmap.claimType === var2.code) {
                    tempmap.claimTypeDesc = var2.description;
                  }
                });
              } else {
                tempmap.claimTypeDesc = DEFAULT_DD_VALUE;
              }
              if (tempmap.COS !== DEFAULT_DD_VALUE) {
                COSDropdown.map((var2) => {
                  if (tempmap.COS === var2.code) {
                    tempmap.COSDesc = var2.description;
                    tempmap.cosCodeLongDesc = var2.longDescription;
                  }
                });
              } else {
                tempmap.COSDesc = null;
              }
              if (tempmap.benefitPlan !== DEFAULT_DD_VALUE) {
                benefitPlanDropdown.map((var2) => {
                  if (tempmap.benefitPlan === var2.code) {
                    tempmap.benefitPlanDesc = var2.description;
                    tempmap.benefitPlanDesc =
                      var2.code + "-" + var2.description;
                  }
                });
              } else {
                tempmap.benefitPlanDesc = "";
                tempmap.benefitPlan = "";
              }
              if (tempmap.mapSetId !== DEFAULT_DD_VALUE) {
                mapSetIdDropdown.map((var2) => {
                  if (tempmap.mapSetId === var2.code) {
                    tempmap.mapSetIdDesc = `${tempmap.mapSetId}-${var2.description}`;
                  }
                });
              } else {
                tempmap.mapSetId = "";
              }
            }
          });
          setMainTableDetails(dummyTable);
          setSuccessMessages([]);
          handleClose();
          setValue(resetValue);
        } else {
          value.fiscalPendCriteriaSeqNum = null;
          value.id = generateUUID();
          value.uuid = generateUUID();
          value.auditUserID = logInUserID;
          value.auditTimeStamp = new Date();
          value.addedAuditUserID = logInUserID;
          value.addedAuditTimeStamp = new Date();
          value.audit_flag = true;
		  value.dollarCapAmtFormat = value.dollarCapAmt;
          if (value.void === "Y") {
            value.voidDate = moment().format("L");
          } else {
            value.voidDate = null;
          }
          if (value.billingProviderIdType !== DEFAULT_DD_VALUE) {
            billingProviderIdTypeDropdown.map((var2) => {
              if (value.billingProviderIdType === var2.code) {
                value.billingProviderIdTypeDesc = var2.description;
              }
            });
          } else {
            value.billingProviderIdTypeDesc = DEFAULT_DD_VALUE;
          }
          if (value.billingProviderType !== DEFAULT_DD_VALUE) {
            billingProviderTypeDropdown.map((var2) => {
              if (value.billingProviderType === var2.code) {
                value.billingProviderTypeDesc = var2.description;
              }
            });
          } else {
            value.billingProviderTypeDesc = DEFAULT_DD_VALUE;
          }
          if (value.claimType !== DEFAULT_DD_VALUE) {
            claimTypeDropdown.map((var2) => {
              if (value.claimType === var2.code) {
                value.claimTypeDesc = var2.description;
              }
            });
          } else {
            value.claimTypeDesc = DEFAULT_DD_VALUE;
          }
          if (value.COS !== DEFAULT_DD_VALUE) {
            COSDropdown.map((var2) => {
              if (value.COS === var2.code) {
                value.COSDesc = var2.description;
                value.cosCodeLongDesc = var2.longDescription;
              }
            });
          } else {
            value.COSDesc = null;
          }
          if (value.benefitPlan !== DEFAULT_DD_VALUE) {
            benefitPlanDropdown.map((var2) => {
              if (value.benefitPlan === var2.code) {
                value.benefitPlanDesc = var2.code + "-" + var2.description;
              }
            });
          } else {
            value.benefitPlanDesc = "";
            value.benefitPlan = "";
          }
          if (value.mapSetId !== DEFAULT_DD_VALUE) {
            mapSetIdDropdown.map((var2) => {
              if (value.mapSetId === var2.code) {
                value.mapSetIdDesc = `${value.mapSetId}-${var2.description}`;
              }
            });
          } else {
            value.mapSetIdDesc = null;
            value.mapSetId = DEFAULT_DD_VALUE ? "" : value.mapSetId;
          }
          mainTableDetails.push(value);
          setSuccessMessages([]);
          handleClose();
          setValue(resetValue);
          setErrorMessages([]);
        }
      } else {
        setErrorMessages(DialogueErrorArray);
      }
  };
  const editRow = (data) => () => {
    setDialogueRankError(false);
    setDollarCapAmtError(false);
    setBillingProviderIdError(false);
    setBillingProviderTypeError(false);
    setMapSetIdError(false);
    setErrorMessages([]);
    setAuditLogRow(data);
    if (data.void === "Y") {
      setDialogueVoid(true);
    } else {
      setDialogueVoid(false);
    }
    setValue({
      ...data,
      mapSetId: data.mapSetId === "" ? DEFAULT_DD_VALUE : data.mapSetId,
      benefitPlan:
        data.benefitPlan === "" ? DEFAULT_DD_VALUE : data.benefitPlan
    });
    const tempdata = {};
    Object.entries(data).forEach(([key, detail]) => {
      if (detail === null) {
        tempdata[key] = "";
      } else {
        tempdata[key] = detail;
      }
    });
    setEditFlag(true);
    setRowEditData(tempdata);
    setOpnFiscalPendDtl(true);
    setErrorMessages([]);
    setSuccessMessages([]);
  };
  const notesValidation = () => {
    let flag = false;
    if (mainValue.description === "" || mainValue.description === "") {
      flag = flag || true;
    }
    if (mainValue.rank === "" || mainValue.rank === "") {
      flag = flag || true;
    }
    if (
      mainValue.beginDate === null ||
      mainValue.beginDate === "" ||
      !selectedBeginDate
    ) {
      flag = flag || true;
    } else if (mainValue.beginDate === "Invalid date") {
      flag = flag || true;
    } else if (
      validateDateMinimumValue(mainValue.beginDate) === true &&
      mainValue.beginDate !== "Invalid date"
    ) {
      flag = flag || true;
    }
    if (
      mainValue.endDate === null ||
      mainValue.endDate === "" ||
      !selectedEndDate
    ) {
      flag = flag || true;
    } else if (mainValue.endDate === "Invalid date") {
      flag = flag || true;
    } else if (validateDateMinimumValue(mainValue.endDate) === true) {
      flag = flag || true;
    } else if (
      compareTwoDatesGreaterThanOrEqual(
        new Date(mainValue.endDate),
        new Date(mainValue.beginDate)
      ) === true
    ) {
      flag = flag || true;
    }
    return flag;
  };
  useEffect(() => {
    setDisableNotes(notesValidation());
  }, [mainValue]);

  const handleClickOpenAddDialogueBox = () => {
    setEditFlag(false);
    setModifierError(false);
    setDialogueRankError(false);
    setDollarCapAmtError(false);
    setBillingProviderIdError(false);
    setBillingProviderIdTypeError(false);
    setMapSetIdError(false);
    setValue(resetValue);
    const tempErrorArray = [];
    setErrorMessages([]);
    setSuccessMessages([]);
    setEditFlag(false);
    setValue(resetValue);
    if (mainValue.description === null || mainValue.description === "") {
      tempErrorArray.push(ErrorConstants.DES_REQ);
      setDesErrorDes(ErrorConstants.DES_REQ);
      setDesError(true);
    } else {
      setDesError(false);
    }
    if (mainValue.rank === null || mainValue.rank === "") {
      tempErrorArray.push(ErrorConstants.RANK_REQ);
      setRankErrorDes(ErrorConstants.RANK_REQ);
      setRankError(true);
    } else {
      setRankError(false);
    }
    if (
      mainValue.beginDate === null ||
      mainValue.beginDate === "" ||
      !selectedBeginDate
    ) {
      tempErrorArray.push(ErrorConstants.BEGIN_DATE_REQ);
      setBeginDateErrorDes(ErrorConstants.BEGIN_DATE_REQ);
      setBeginDateError(true);
    } else if (mainValue.beginDate === "Invalid date") {
      tempErrorArray.push(ErrorConstants.BEGIN_DATE_VALID);
      setBeginDateErrorDes(ErrorConstants.BEGIN_DATE_VALID);
      setBeginDateError(true);
    } else if (
      validateDateMinimumValue(mainValue.beginDate) === true &&
      mainValue.beginDate !== "Invalid date"
    ) {
      tempErrorArray.push(AppConstants.DATE_ERROR_1964);
      setBeginDateErrorDes(AppConstants.DATE_ERROR_1964);
      setBeginDateError(true);
    } else {
      setBeginDateError(false);
    }
    if (
      mainValue.endDate === null ||
      mainValue.endDate === "" ||
      !selectedEndDate
    ) {
      tempErrorArray.push(ErrorConstants.END_DATE_REQ);
      setEndDateErrorDes(ErrorConstants.END_DATE_REQ);
      setEndDateError(true);
    } else if (mainValue.endDate === "Invalid date") {
      tempErrorArray.push(ErrorConstants.END_DATE_VALID);
      setEndDateErrorDes(ErrorConstants.END_DATE_VALID);
      setEndDateError(true);
    } else if (validateDateMinimumValue(mainValue.endDate) === true) {
      tempErrorArray.push(AppConstants.DATE_ERROR_1964);
      setEndDateErrorDes(AppConstants.DATE_ERROR_1964);
      setEndDateError(true);
    } else if (
      compareTwoDatesGreaterThanOrEqual(
        new Date(mainValue.endDate),
        new Date(mainValue.beginDate)
      ) === true
    ) {
      tempErrorArray.push(ErrorConstants.END_DATE_LESSER);
      setEndDateErrorDes(ErrorConstants.END_DATE_LESSER);
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
    if (tempErrorArray.length === 0) {
      setOpnFiscalPendDtl(true);
    } else {
      setErrorMessages(tempErrorArray);
    }
  };

  const handleClose = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(allowNavigation);
    setErrorMessages([]);
    setModifierError(false);
    setOpen(false);
    setOpnFiscalPendDtl(false);
    setEditFlag(false);
    setDialogueRankError(false);
    setDollarCapAmtError(false);
    setBillingProviderIdError(false);
    setBillingProviderTypeError(false);
    setMapSetIdError(false);
    setValue(resetValue);
    setRowEditData({});
    setDialogueVoid(false);
  };
  const handleReset = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    setErrorMessages([]);
    if (editFlag) {
      setModifierError(false);
      setDialogueRankError(false);
      setDollarCapAmtError(false);
      setBillingProviderIdError(false);
      setBillingProviderTypeError(false);
      setBillingProviderIdTypeError(false);
      setMapSetIdError(false);
      setValue(rowEditData);
    } else {
      setModifierError(false);
      setDialogueRankError(false);
      setDollarCapAmtError(false);
      setBillingProviderIdError(false);
      setBillingProviderIdTypeError(false);
      setMapSetIdError(false);
      setValue(resetValue);
    }
  };

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeMain = (name) => (event) => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitalFocusCheck(true);
    setSuccessMessages([]);
    if (name === "description") {
      setMainValue({ ...mainValue, [name]: event.target.value });
    } else if (name === "rank") {
      setMainValue({ ...mainValue, [name]: event.target.value });
    } else if (name === "include") {
      if (event.target.checked) {
        setMainValue({ ...mainValue, [name]: "Y" });
      } else {
        setMainValue({ ...mainValue, [name]: "N" });
      }
    } else {
      setMainValue({ ...mainValue, [name]: event.target.value });
    }
  };
  const handleDecimalValue = (name) => (event) => {
    setAllowNavigation(true);
    setFocusCheck(true);
    setSuccessMessages([]);
    if (name === "dollarCapAmt") {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValue({ ...value, [name]: event.target.value });
        }
      } else {
          setValue({ ...value, [name]: event.target.value });
        }
    } else {
      setValue({ ...value, [name]: event.target.value });
    }
  };
  const handleChangeDialogue = (name) => (event) => {
    setAllowNavigation(true);
    setFocusCheck(true);
    setSuccessMessages([]);
    if (name === "rank") {
      setValue({ ...value, [name]: event.target.value });
    } else if (
      name === "modifiers_1" ||
      name === "modifiers_2" ||
      name === "modifiers_3" ||
      name === "modifiers_4"
    ) {
      setValue({ ...value, [name]: event.target.value.toUpperCase() });
      setModifierError(false);
    } else if (name === "mapSetId") {
      setValue({ ...value, [name]: event.target.value });
      setMapSetIdError(false);
    } else if (name === "billingProviderId") {
      setValue({ ...value, [name]: event.target.value });
      setBillingProviderIdError(false);
    } else if (name === "billingProviderIdType") {
      setValue({ ...value, [name]: event.target.value });
      setBillingProviderIdTypeError(false);
    } else if (name === "procedureCode") {
      setValue({ ...value, [name]: event.target.value.toUpperCase() });
    } else if (
      !(
        name === "modifiers_1" ||
        name === "modifiers_2" ||
        name === "modifiers_3" ||
        name === "modifiers_4" ||
        name === "procedureCode"
      )
    ) {
      setValue({ ...value, [name]: event.target.value });
    } else {
      setValue({ ...value, [name]: event.target.value });
    }
  };
  const handleBeginDateChange = (date) => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedBeginDate(date);
    setMainValue({
      ...mainValue,
      beginDate: moment(new Date(date).getTime()).format("MM/DD/YYYY")
    });
  };
  const handleEndDateChange = (date) => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedEndDate(date);
    setMainValue({
      ...mainValue,
      endDate: moment(new Date(date).getTime()).format("MM/DD/YYYY")
    });
  };

  const MasterSave = () => {
    setSuccessMessages([]);
    const tempMainError = [];
    if (mainValue.description === null || mainValue.description === "") {
      tempMainError.push(ErrorConstants.DES_REQ);
      setDesErrorDes(ErrorConstants.DES_REQ);
      setDesError(true);
    } else {
      setDesError(false);
    }
    if (mainValue.rank === null || mainValue.rank === "") {
      tempMainError.push(ErrorConstants.RANK_REQ);
      setRankErrorDes(ErrorConstants.RANK_REQ);
      setRankError(true);
    } else {
      setRankError(false);
    }
    if (
      mainValue.beginDate === null ||
      mainValue.beginDate === "" ||
      !selectedBeginDate
    ) {
      tempMainError.push(ErrorConstants.BEGIN_DATE_REQ);
      setBeginDateErrorDes(ErrorConstants.BEGIN_DATE_REQ);
      setBeginDateError(true);
    } else if (mainValue.beginDate === "Invalid date") {
      tempMainError.push(ErrorConstants.BEGIN_DATE_VALID);
      setBeginDateErrorDes(ErrorConstants.BEGIN_DATE_VALID);
      setBeginDateError(true);
    } else if (
      validateDateMinimumValue(mainValue.beginDate) === true &&
      mainValue.beginDate !== "Invalid date"
    ) {
      tempMainError.push(AppConstants.DATE_ERROR_1964);
      setBeginDateErrorDes(AppConstants.DATE_ERROR_1964);
      setBeginDateError(true);
    } else {
      setBeginDateError(false);
    }
    if (
      mainValue.endDate === null ||
      mainValue.endDate === "" ||
      !selectedEndDate
    ) {
      tempMainError.push(ErrorConstants.END_DATE_REQ);
      setEndDateErrorDes(ErrorConstants.END_DATE_REQ);
      setEndDateError(true);
    } else if (mainValue.endDate === "Invalid date") {
      tempMainError.push(ErrorConstants.END_DATE_VALID);
      setEndDateErrorDes(ErrorConstants.END_DATE_VALID);
      setEndDateError(true);
    } else if (validateDateMinimumValue(mainValue.endDate) === true) {
      tempMainError.push(AppConstants.DATE_ERROR_1964);
      setEndDateErrorDes(AppConstants.DATE_ERROR_1964);
      setEndDateError(true);
    } else if (
      compareTwoDatesGreaterThanOrEqual(
        new Date(mainValue.endDate),
        new Date(mainValue.beginDate)
      ) === true
    ) {
      tempMainError.push(ErrorConstants.END_DATE_LESSER);
      setEndDateErrorDes(ErrorConstants.END_DATE_LESSER);
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }

    let duplicateRecord = 0;
    const dummyTableDetail = JSON.parse(JSON.stringify(mainTableDetails));
    for (let i = 0; i < mainTableDetails.length - 1; i++) {
      for (let j = i + 1; j < mainTableDetails.length; j++) {
        if (
          JSON.stringify(dummyTableDetail[i].billingProviderIdType) ===
            JSON.stringify(dummyTableDetail[j].billingProviderIdType) &&
          JSON.stringify(dummyTableDetail[i].billingProviderId) ===
            JSON.stringify(dummyTableDetail[j].billingProviderId) &&
          JSON.stringify(dummyTableDetail[i].billingProviderType) ===
            JSON.stringify(dummyTableDetail[j].billingProviderType) &&
          JSON.stringify(dummyTableDetail[i].billingTaxonomy) ===
            JSON.stringify(dummyTableDetail[j].billingTaxonomy) &&
          JSON.stringify(dummyTableDetail[i].claimType) ===
            JSON.stringify(dummyTableDetail[j].claimType) &&
          JSON.stringify(dummyTableDetail[i].benefitPlan) ===
            JSON.stringify(dummyTableDetail[j].benefitPlan) &&
          JSON.stringify(dummyTableDetail[i].COS) ===
            JSON.stringify(dummyTableDetail[j].COS) &&
          JSON.stringify(dummyTableDetail[i].daysToPend) ===
            JSON.stringify(dummyTableDetail[j].daysToPend) &&
          JSON.stringify(dummyTableDetail[i].procedureCode) ===
            JSON.stringify(dummyTableDetail[j].procedureCode) &&
          JSON.stringify(dummyTableDetail[i].modifiers_1) ===
            JSON.stringify(dummyTableDetail[j].modifiers_1) &&
          JSON.stringify(dummyTableDetail[i].modifiers_2) ===
            JSON.stringify(dummyTableDetail[j].modifiers_2) &&
          JSON.stringify(dummyTableDetail[i].modifiers_3) ===
            JSON.stringify(dummyTableDetail[j].modifiers_3) &&
          JSON.stringify(dummyTableDetail[i].modifiers_4) ===
            JSON.stringify(dummyTableDetail[j].modifiers_4) &&
          JSON.stringify(dummyTableDetail[i].printRA) ===
            JSON.stringify(dummyTableDetail[j].printRA) &&
          JSON.stringify(dummyTableDetail[i].mapSetId) ===
            JSON.stringify(dummyTableDetail[j].mapSetId)
        ) {
          duplicateRecord++;
        }
      }
    }
    if (duplicateRecord > 0) {
      tempMainError.push(ErrorConstants.DUPLICATEDETAIL);
    }
    if (mainTableDetails.length === 0) {
      tempMainError.push(ErrorConstants.ATLEASTONEDETAIL);
    }
    if (tempMainError.length === 0) {
      requestObject.auditUserID = logInUserID;
      requestObject.auditTimeStamp = new Date();
      requestObject.addedAuditUserID = auditId;
      requestObject.addedAuditTimeStamp = auditIdTimeStamp;
      requestObject.fiscalPendDetailsVO.auditUserID = logInUserID;
      requestObject.fiscalPendDetailsVO.auditTimeStamp = new Date();
      requestObject.fiscalPendDetailsVO.addedAuditUserID =
        auditId === "" ? logInUserID : auditId;
      requestObject.fiscalPendDetailsVO.addedAuditTimeStamp =
        auditIdTimeStamp === "" ? new Date() : auditIdTimeStamp;
      requestObject.fiscalPendDetailsVO.beginDate = mainValue.beginDate;
      requestObject.fiscalPendDetailsVO.endDate = mainValue.endDate;
      requestObject.fiscalPendDetailsVO.fundCode =
        mainValue.fundCode !== DEFAULT_DD_VALUE ? mainValue.fundCode : null;
      requestObject.fiscalPendDetailsVO.rankNumber = mainValue.rank;
      requestObject.fiscalPendDetailsVO.description = mainValue.description;
      requestObject.fiscalPendDetailsVO.fiscalPendID = mainValue.fiscalPendId;
      requestObject.fiscalPendDetailsVO.lobCode = AppConstants.DEFAULT_LOB_VALUE;
      requestObject.fiscalPendDetailsVO.financialTxnIndicator =
        mainValue.include;
      requestObject.deleteFiscalPendCriterias = fiscalPendDeleteData;
      requestObject.fiscalPendDetailsVO.versionNo = mainValue.versionNo
        ? mainValue.versionNo
        : "3";
      requestObject.fiscalPendDetailsVO.fiscalPendProcessingIndicator = pendInd;
      if (!mainVoid) {
        if (mainValue.void === "Y") {
          requestObject.fiscalPendDetailsVO.voidDate = moment().format("L");
        } else {
          requestObject.fiscalPendDetailsVO.voidDate = null;
        }
      } else {
        requestObject.fiscalPendDetailsVO.voidDate = voidDateRef
          ? moment(new Date(voidDateRef)).format("L")
          : voidDateRef;
      }
      const requestFiscalPendDetails = [];
      mainTableDetails.map((varData) => {
        varData = removeLeadingTrailingSpaces(varData);
        const sampleObj = JSON.parse(JSON.stringify(fiscalPendData));
        sampleObj.fiscalPendCriteriaSeqNum = varData.fiscalPendCriteriaSeqNum;
        sampleObj.auditUserID = varData.auditUserID;
        sampleObj.auditTimeStamp = varData.auditTimeStamp;
        sampleObj.addedAuditUserID = varData.addedAuditUserID;
        sampleObj.addedAuditTimeStamp = varData.addedAuditTimeStamp;
        if (varData.audit_flag_edit) {
          sampleObj.auditTimeStamp = new Date();
        }
        if (varData.audit_flag) {
          sampleObj.addedAuditTimeStamp = new Date();
          sampleObj.auditTimeStamp = new Date();
        }
        sampleObj.providerAltIDTypeCode =
          varData.billingProviderIdType !== DEFAULT_DD_VALUE
            ? varData.billingProviderIdType
            : "";
        sampleObj.providerAltID = varData.billingProviderId;
        sampleObj.providerTypeCode =
          varData.billingProviderType !== DEFAULT_DD_VALUE
            ? varData.billingProviderType
            : "";
        sampleObj.rankNumber = varData.rank;
        sampleObj.providerTaxonomyCode = varData.billingTaxonomy;
        sampleObj.claimTypeCode =
          varData.claimType !== DEFAULT_DD_VALUE ? varData.claimType : "";
        sampleObj.benefitPlanID =
          varData.benefitPlan !== DEFAULT_DD_VALUE
            ? varData.benefitPlan
            : "";
        sampleObj.categoryOfServiceCode =
          varData.COS !== DEFAULT_DD_VALUE ? varData.COS : "";
        sampleObj.capitationAmount = varData.dollarCapAmt.replace(/,/g, "");
        sampleObj.daysToPend = varData.daysToPend;
        sampleObj.procedureCode = varData.procedureCode;
        sampleObj.firstModifierCode =
          varData.modifiers_1 === "" ? null : varData.modifiers_1;
        sampleObj.secondModifierCode =
          varData.modifiers_2 === "" ? null : varData.modifiers_2;
        sampleObj.thirdModifierCode =
          varData.modifiers_3 === "" ? null : varData.modifiers_3;
        sampleObj.fourthModifierCode =
          varData.modifiers_4 === "" ? null : varData.modifiers_4;
        sampleObj.printRAIndicator = varData.printRA;
        sampleObj.voidDateInd = varData.void;
        sampleObj.voidDate = varData.voidDate;
        if (mainValue.void === "Y") {
          if (varData.void === "N") {
            sampleObj.voidDateInd = "Y";
            sampleObj.voidDate = moment().format("L");
          }
        }
        sampleObj.mapsetID =
          varData.mapSetId !== DEFAULT_DD_VALUE ? varData.mapSetId : "";
        requestFiscalPendDetails.push(sampleObj);
      });
      requestObject.fiscalPendDetails = requestFiscalPendDetails;
      requestObject.fiscalPendCriterias = requestFiscalPendDetails;
      requestObject.voidNoteInd = mainVoid || false;
      requestObject.fiscalPendDetailsVO = removeLeadingTrailingSpaces(
        requestObject.fiscalPendDetailsVO
      );

      if (editPageFlag) {
        if (!allowNavigation) {
          NoSaveMessage();
        } else {
          onFiscalPendUpdate(requestObject);
        }
      } else {
        requestObject.addedAuditUserID = logInUserID;
        requestObject.addedAuditTimeStamp = new Date();

        onFiscalPendAdd(requestObject);
      }
    } else {
      setErrorMessages(tempMainError);
    }
  };
  useEffect(() => {
    if (benefitPlanIdDropdown && benefitPlanIdDropdown.bpIDList) {
      setBenefitPlanDropdown(benefitPlanIdDropdown.bpIDList);
    }
  }, [benefitPlanIdDropdown]);

  useEffect(() => {
    if (mapIdDropdown && mapIdDropdown.mapIDList) {
      const mapList = JSON.parse(
        JSON.stringify(
          mapIdDropdown.mapIDList.sort((a, b) =>
            a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1
          )
        )
      );
      setMapSetIdDropdown(mapList);
    }
  }, [mapIdDropdown]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["Financial#3200"]) {
        setFundCodeDropDown(dropdown.listObj["Financial#3200"]);
      }
      if (dropdown.listObj["Provider#1017"]) {
        setBillingProviderIdTypeDropdown(dropdown.listObj["Provider#1017"]);
      }
      if (dropdown.listObj["Provider#1062"]) {
        setBillingProviderTypeDropdown(dropdown.listObj["Provider#1062"]);
      }
      if (dropdown.listObj["Claims#1038"]) {
        setClaimTypeDropdown(dropdown.listObj["Claims#1038"]);
      }
      if (dropdown.listObj["Claims#1038"]) {
        setClaimTypeDropdown(dropdown.listObj["Claims#1038"]);
      }
      if (dropdown.listObj["Claims#1020"]) {
        setCOSDropdown(dropdown.listObj["Claims#1020"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
      const dummyVar = JSON.parse(JSON.stringify(mainTableDetails));
      dummyVar.map((sampleObj) => {
        if (sampleObj.billingProviderIdType !== DEFAULT_DD_VALUE) {
          // dropdown.listObj["Provider#1017"].map((var2) => {
          //   if (sampleObj.billingProviderIdType === var2.code) {
          //     sampleObj.billingProviderIdTypeDesc = var2.description;
          //   }
          // });
          const billingProviderIdType =
                addDropdowns &&
                addDropdowns['P1#P_ALT_ID_TY_CD'] &&
                addDropdowns['P1#P_ALT_ID_TY_CD'].filter(
                  value => value.code === sampleObj.billingProviderIdType
                );
          sampleObj.billingProviderIdTypeDesc =
              billingProviderIdType && billingProviderIdType.length > 0
                  ? billingProviderIdType[0].description
                  : sampleObj.billingProviderIdType ? sampleObj.billingProviderIdType : '';
        } else {
          sampleObj.billingProviderIdTypeDesc = DEFAULT_DD_VALUE;
        }
        if (sampleObj.billingProviderType !== DEFAULT_DD_VALUE) {
          // dropdown.listObj["Provider#1062"].map((var2) => {
          //   if (sampleObj.billingProviderType === var2.code) {
          //     sampleObj.billingProviderTypeDesc = var2.description;
          //   }
          // });
          const billingProviderType =
          addDropdowns &&
          addDropdowns['P1#P_TY_CD'] &&
          addDropdowns['P1#P_TY_CD'].filter(
            value => value.code === sampleObj.billingProviderType
          );
          sampleObj.billingProviderTypeDesc =
          billingProviderType && billingProviderType.length > 0
              ? billingProviderType[0].description
              : sampleObj.billingProviderType ? sampleObj.billingProviderType : '';
        } else {
          sampleObj.billingProviderTypeDesc = DEFAULT_DD_VALUE;
        }
        if (sampleObj.claimType !== DEFAULT_DD_VALUE) {
          // dropdown.listObj["Claims#1038"].map((var2) => {
          //   if (sampleObj.claimType === var2.code) {
          //     sampleObj.claimTypeDesc = var2.description;
          //   }
          // });
          const claimType =
                addDropdowns &&
                addDropdowns['C1#C_TY_CD'] &&
                addDropdowns['C1#C_TY_CD'].filter(
                  value => value.code === sampleObj.claimType
                );
                sampleObj.claimTypeDesc =
                claimType && claimType.length > 0
                    ? claimType[0].description
                    : sampleObj.claimType ? sampleObj.claimType : '';
        } else {
          sampleObj.claimTypeDesc = DEFAULT_DD_VALUE;
        }
        if (sampleObj.COS !== DEFAULT_DD_VALUE) {
          // dropdown.listObj["Claims#1020"].map((var2) => {
          //   if (sampleObj.COS === var2.code) {
          //     sampleObj.COSDesc = var2.description;
          //     sampleObj.cosCodeLongDesc = var2.longDescription;
          //   }
          // });
          const COS =
          addDropdowns &&
          addDropdowns['C1#C_COS_CD'] &&
          addDropdowns['C1#C_COS_CD'].filter(
            value => value.code === sampleObj.COS
          );
          sampleObj.COSDesc =
          COS && COS.length > 0
              ? COS[0].description
              : sampleObj.COS ? sampleObj.COS : '';
        } else {
          sampleObj.COSDesc = null;
        }
        if (mapSetIdDropdown.length > 0) {
          if (sampleObj.mapSetId !== DEFAULT_DD_VALUE) {
            mapSetIdDropdown.map((var2) => {
              if (sampleObj.mapSetId === var2.code) {
                sampleObj.mapSetIdDesc = `${sampleObj.mapSetId}-${var2.description}`;
              }
            });
          } else {
            sampleObj.mapSetIdDesc = null;
          }
        }
      });
      setMainTableDetails(dummyVar);
    }
  }, [dropdown, addDropdowns]);

  function Confirm () {
    Swal.fire({
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-primary",
        container: "unsaved-changes-popup2"
      },
      allowOutsideClick: false,
      buttonsStyling: false,
      heightAuto: false,
      icon: "warning",
      html: "<h4>Are you sure you want to delete?</h4>",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel"
    }).then((result) => {
      if (result.value) {
        deleteConfirm();
      } else {
      }
    });
  }
  useEffect(() => {
    if (mainValue.include === "Y") {
      setAntSwitchName("Yes");
    } else {
      setAntSwitchName("No");
    }
  }, [mainValue.include]);
  // add notes
  let notesDataArray = [];

  const addNotes = (data) => {
    setAllowNavigation(true);

    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessages([]);
    setErrorMessages([]);
    setDesError(false);
    setRankError(false);
    setBeginDateError(false);
    setEndDateError(false);
  };

  // #region
  // Audit Log Implementation starts
  const [showLogTable, setShowLogTable] = React.useState(false);
  const onClickAuditLog = () => {
    const tableName = "r_fscl_pend_hdr_tb";

    const keyValue = {
      r_lob_cd: mainValue.lob,
      r_fscl_pend_id: mainValue.fiscalPendId
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById("audit-table")) { document.getElementById("audit-table").scrollIntoView(); }
  }, [showLogTable]);
  const auditLogData = useSelector(
    (state) => state.fiscalPendSearchState.auditDetails
  );
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = (rowData) => {
    return rowData.afterValue || rowData.beforeValue;
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const auditLogTableData = useSelector(
    (state) => state.fiscalPendSearchState.auditRowDetails
  );
  const [auditTableData, setAuditTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);

  const onClickAuditDialogLog = (row) => {
    const tableName = "r_fscl_pend_crit_tb";
    const keyValue = {
      r_lob_cd: mainValue.lob,
      r_fscl_pend_id: mainValue.fiscalPendId,
      r_fscl_pend_crit_seq_num: row.fiscalPendCriteriaSeqNum
    };
    dispatch(
      getAuditLogRowDetailsAction(tableName, keyValue, "auditLogTableData")
    );
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById("audit-dialog-table")) { document.getElementById("audit-dialog-table").scrollIntoView(); }
  }, [showLogDialogTable]);
  // Audit Log Implementation ends
  // #endregion
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initalFocusCheck, setInitalFocusCheck] = React.useState(false);
  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);

  const ValuesFromFiscPend = props.history && props.history.location && props.history.location.state && props.history.location.state.values;

      const cancelMinorSection = () => {
        setAllowNavigation(false);
        setTimeout(() => {
          setDialogOpen(false);
          setFocusCheck(false);
          if (window.location.pathname === '/FiscalPendCriteriaAdd') {
              setDialogOpen(false);
              setFocusCheck(false);
              props.history.push({
                pathname: '/FiscalPendCriteriaSearch',
                tableRender: false
              });
          } else if (window.location.pathname === '/FiscalPendCriteriaEdit') {
            props.history.push({
              pathname: '/FiscalPendCriteriaSearch',
              tableRender: !props.history.location?.state?.isFromAdd,
              state: { values: ValuesFromFiscPend }
            });
          }
        }, 100);
        };

      const majorCancel = () => {
        setAllowNavigation(false);
        setErrorMessages([]);
        if (focusCheck) {
          setDialogOpen(true);
        } else {
          setDialogOpen(false);
          if (window.location.pathname === '/FiscalPendCriteriaEdit') {
             props.history.push({
            pathname: '/FiscalPendCriteriaSearch',
            tableRender: !props.history.location?.state?.isFromAdd,
            state: { values: ValuesFromFiscPend }
          });
} else if (window.location.pathname === '/FiscalPendCriteriaAdd') {
            props.history.push({
              pathname: '/FiscalPendCriteriaSearch',
              tableRender: false
            });
          }
        }
      };

  const fundCode = fundCodeDropDown.find(value => value.code === mainValue.fundCode);
      const bpIDTypeCode = billingProviderIdTypeDropdown.find(value1 => value1.code === value.billingProviderIdType);
      const bpTypeCode = billingProviderTypeDropdown.find(value1 => value1.code === value.billingProviderType);
      const claimTypeCode = claimTypeDropdown.find(value1 => value1.code === value.claimType);
      const bplanCode = benefitPlanDropdown.find(value1 => value1.code === value.benefitPlan);
      const cosCode = COSDropdown.find(value1 => value1.code === value.COS);
  const mapSetIDCode = mapSetIdDropdown.find(value1 => value1.code === value.mapSetId);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

      <div className="tabs-container " ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {successMessages.length > 0 && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={successMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            {!editPageFlag
              ? "Add Fiscal Pend Criteria"
              : "Edit Fiscal Pend Criteria"}
          </h1>
          <div className="float-right mt-2">
            <Button
              color="primary"
              className="btn btn-success"
              onClick={MasterSave}
              disabled={!userInquiryPrivileges ? !userInquiryPrivileges : false}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            {editPageFlag ? (
              <Button
                className={
                  auditButtonToggle
                    ? "btn btn-primary ml-1"
                    : "btn btn-text1 btn-transparent ml-1"
                }
                onClick={() => {
                  setAuditButtonToggle(!auditButtonToggle);
                  setShowLogTable(true);
                }}
              >
                <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                Audit Log
              </Button>
            ) : null}

              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>

          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md margin-right-60">
                <TextField
                  id="standard-id"
                  fullWidth
                  label="Fiscal Pend ID"
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  value={mainValue.fiscalPendId}
                  inputProps={{ maxlength: 4 }}
                  onChange={handleChangeMain("fiscalPendId")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-description"
                  fullWidth
                  required
                  label="Description"
                  data-testid="test_desc"
                  InputProps={{
                    readOnly: !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : dataToEdit || mainVoid,
                  className: !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : dataToEdit || mainVoid
                      ? "Mui-disabled"
                      : ""
                  }}
                  onChange={handleChangeMain("description")}
                  inputProps={{ maxLength: 40 }}
                  value={mainValue.description}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={desError ? desErrorDes : null}
                  error={desError ? desErrorDes : null}
                />
              </div>
              <NativeDropDown
                id="standard-select-fundcode"
                  label="Fund Code"
                  disabled={
                      !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : dataToEdit || mainVoid
                  }
                value={fundCode !== undefined && editPageFlag ? mainValue.fundCode : !editPageFlag ? mainValue.fundCode : DEFAULT_DD_VALUE}
                  inputProps={{ maxLength: 5 }}
                  onChange={handleChangeMain("fundCode")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  title={getLongDescription(
                    fundCodeDropDown,
                    mainValue.fundCode
                  )}
                dropdownList={fundCodeDropDown.map((option) => (
                  <option key={option.code} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-rank"
                  fullWidth
                  required
                  label="Rank"
                  data-testid="test_rank"
                  InputProps={{
                    readOnly: !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : dataToEdit || mainVoid,
                    className: !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : dataToEdit || mainVoid
                      ? "Mui-disabled"
                      : ""
                  }}
                  inputProps={{ maxlength: 6 }}
                  onChange={handleChangeMain("rank")}
                  value={mainValue.rank}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(
                      AppConstants.NUMBER_ONLY_REGEX,
                      ""
                    );
                  }}
                  helperText={rankError ? rankErrorDes : null}
                  error={rankError ? rankErrorDes : null}
                />
              </div>
            </div>
            <div className="form-wrapper ">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date margin-right-60">
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    id="date-picker-begindate"
                    required
                    InputProps={{
                      readOnly: !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : dataToEdit || mainVoid,
                      className: !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : dataToEdit || mainVoid
                        ? "Mui-disabled"
                        : ""
                    }}
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : dataToEdit || mainVoid
                    }
                    label="Paid Date Begin Date"
                    data-testid="test_begin_date"
                    onChange={handleBeginDateChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="mm/dd/yyyy"
                    format="MM/dd/yyyy"
                    value={selectedBeginDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    helperText={beginDateError ? beginDateErrorDes : null}
                    error={beginDateError ? beginDateErrorDes : null}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    id="date-picker-enddate"
                    required
                    label="Paid Date End Date"
                    data-testid="test_end_date"
                    disabled={
                      !userInquiryPrivileges ? !userInquiryPrivileges : mainVoid
                    }
                    InputProps={{
                      readOnly: !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : mainVoid,
                      className: !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : mainVoid
                        ? "Mui-disabled"
                        : ""
                    }}
                    onChange={handleEndDateChange}
                    format="MM/dd/yyyy"
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="mm/dd/yyyy"
                    value={selectedEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    helperText={endDateError ? endDateErrorDes : null}
                    error={endDateError ? endDateErrorDes : null}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="mui-custom-form input-md fullwidth">
                <label className="MuiFormLabel-root small-label fiscal-label">
                  Include Financial Payout Transaction<span>*</span>
                </label>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <AntSwitch
                        onChange={handleChangeMain("include")}
                        checked={mainValue.include === "Y"}
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : dataToEdit || mainVoid
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      className="MuiFormLabel-root small-label"
                      style={{ marginTop: "9px" }}
                    >
                      {antSwitchName}
                    </Grid>
                  </Grid>
                </Typography>
              </div>
              {editPageFlag ? (
                <div className="mui-custom-form input-md">
                  <label
                    className="MuiFormLabel-root small-label"
                    htmlFor="fiscalyes"
                  >
                    Void<span>*</span>
                  </label>
                  <div className="sub-radio">
                    <Radio
                      value="Y"
                      name="void"
                      id="fiscalyes"
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : dataToEdit || mainVoid
                      }
                      checked={mainValue.void === "Y"}
                      onChange={handleChangeMain("void")}
                    />
                    <span className="text-black"> Yes </span>
                    <Radio
                      defaultChecked="true"
                      value="N"
                      className="ml-2"
                      name="void"
                      id="fiscalyes"
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : dataToEdit || mainVoid
                      }
                      checked={mainValue.void === "N"}
                      onChange={handleChangeMain("void")}
                    />
                    <span className="text-black"> No </span>
                  </div>
                </div>
              ) : null}
            </div>
          </form>
          <div className="tab-panelbody m-0">
            <div className="tab-holder my-3">
              <AppBar position="static">
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="Fiscal Pend Details" />
                  <Tab label="Notes" />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                {tabValue === 0 && (
                  <div className="my-3 tab-holder float-right">
                    <Button
                      className="btn-text-main-delete btn-transparent  ml-1"
                      onClick={RowDeleteClick}
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : mainVoid
                      }
                      title={"Delete"}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                      Delete
                    </Button>
                    <button
                      className="btn-text-main-add btn-success ml-1"
                      onClick={handleClickOpenAddDialogueBox}
                      data-testid="test_add_btn"
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : mainVoid || dataToEdit
                      }
                      title={"Add Fiscal Pend Detail"}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add
                    </button>
                  </div>
                )}
                <div className="clearfix"></div>
                <div>
                  <Dialog className="custom-dialog dialog-800" open={open}>
                    <DialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                      disableBackdropClick
                    >
                      {editFlag
                        ? "Edit Fiscal Pend Details"
                        : "Add Fiscal Pend Details"}
                    </DialogTitle>
                    <DialogContent>
                      <form autoComplete="off">
                        <div
                          className="form-wrapper"
                          style={{ padding: "5px 5px" }}
                        >
                          <NativeDropDown
                            className="override-width-23 override-m-1"
                            id="standard-select-bpidtype"
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              value={value.billingProviderIdType}
                              onChange={handleChangeDialogue(
                                "billingProviderIdType"
                              )}
                              label="Billing Provider ID Type"
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxLength: 3 }}
                            errTxt={
                                billingProviderIdTypeError
                                  ? billingProviderIdTypeErrorDes
                                  : null
                              }
                            dropdownList={billingProviderIdTypeDropdown.map((option) => (
                              <option key={option.code} value={option.code}>
                                  {option.description}
                              </option>
                              ))}
                          />
                          <div className="mui-custom-form override-width-23 override-m-1">
                            <TextField
                              id="standard-bpid"
                              fullWidth
                              label="Billing Provider ID"
                              value={value.billingProviderId}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue(
                                "billingProviderId"
                              )}
                              inputProps={{ maxlength: 15 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              helperText={
                                billingProviderIdError
                                  ? billingProviderIdErrorDes
                                  : null
                              }
                              error={
                                billingProviderIdError
                                  ? billingProviderIdErrorDes
                                  : null
                              }
                            />
                          </div>
                          <NativeDropDown
                              id="standard-select-bptype"
                            className="override-width-23 override-m-1"
                              value={value.billingProviderType}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue(
                                "billingProviderType"
                              )}
                            errTxt={
                                billingProviderTypeError
                                  ? billingProviderTypeErrorDes
                                  : null
                              }
                              label="Billing Provider Type"
                              InputLabelProps={{
                                shrink: true
                              }}
                            dropdownList={billingProviderTypeDropdown.map((option) => (
                              <option key={option.code} value={option.code}>
                                  {option.description}
                              </option>
                              ))}
                          />
                          <div className="mui-custom-form override-width-23 override-m-1">
                            <TextField
                              required
                              fullWidth
                              id="standard-rankdetail"
                              label="Rank"
                              value={value.rank}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("rank")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxlength: 6 }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  AppConstants.NUMBER_ONLY_REGEX,
                                  ""
                                );
                              }}
                              helperText={
                                dialogueRankError ? dialogueRankErrorDes : null
                              }
                              error={
                                dialogueRankError ? dialogueRankErrorDes : null
                              }
                            />
                          </div>
                          <div className="mui-custom-form override-width-23 override-m-1">
                            <TextField
                              id="standard-billingtaxonomy"
                              fullWidth
                              label="Billing Taxonomy"
                              value={value.billingTaxonomy}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("billingTaxonomy")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxlength: 10 }}
                            />
                          </div>
                          <NativeDropDown
                            className="override-width-23 override-m-1"
                            id="standard-select-claimtype"
                              label="Claim Type"
                              value={value.claimType}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("claimType")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxlength: 1 }}
                            dropdownList={claimTypeDropdown.map((option) => (
                              <option key={option.code} value={option.code}>
                                  {option.description}
                              </option>
                              ))}
                          />
                          <NativeDropDown
                            className="override-width-23 override-m-1"
                            id="standard-select-benefitplan"
                              label="Benefit Plan"
                              value={value.benefitPlan}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("benefitPlan")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxLength: 30 }}
                            dropdownList={benefitPlanDropdown.map((option) => (
                              <option key={option.code} value={option.code}>
                                  {option.code}-
                                  {option.description.replace("#", "")}
                              </option>
                              ))}
                          />
                          <NativeDropDown
                            className="override-width-23 override-m-1"
                            id="standard-select-cos"
                              label="COS"
                              value={value.COS}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("COS")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              title={getLongDescription(COSDropdown, value.COS)}
                            dropdownList={COSDropdown.map((option) => (
                              <option key={option.code} value={option.code}>
                                  {option.description}
                              </option>
                              ))}
                          />
                          <div className="mui-custom-form override-width-23 override-m-1">
                            <TextField
                              required
                              fullWidth
                              id="standard-capamount"
                              label="Cap Amount"
                              value={value.dollarCapAmt}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                  ""
                                );
                              }}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              inputProps={{
                                maxlength: 10
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              onChange={handleDecimalValue("dollarCapAmt")}
                              helperText={
                                dollarCapAmtError ? dollarCapAmtErrorDes : null
                              }
                              error={
                                dollarCapAmtError ? dollarCapAmtErrorDes : null
                              }
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="mui-custom-form override-width-23 override-m-1">
                            <TextField
                              id="standard-daystopend"
                              fullWidth
                              label="Days To Pend"
                              value={value.daysToPend}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("daysToPend")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  AppConstants.NUMBER_ONLY_REGEX,
                                  ""
                                );
                              }}
                              inputProps={{ maxlength: 3 }}
                            />
                          </div>
                          <div className="mui-custom-form override-width-23 override-m-1">
                            <TextField
                              id="standard-proccode"
                              fullWidth
                              label="Procedure Code"
                              value={value.procedureCode}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("procedureCode")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxlength: 7 }}
                            />
                          </div>
                          <div className="mui-custom-form override-width-23 override-m-1">
                            <label
                              className="MuiFormLabel-root small-label db"
                              htmlFor="standard-modifiers"
                              style={{ marginBottom: "15px" }}
                            >
                              Modifiers
                            </label>
                            <input
                              id="standard-modifiers"
                              value={value.modifiers_1}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("modifiers_1")}
                              inputProps={{ maxlength: 2 }}
                              className="input-94"
                              maxLength="2"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                            {"\u00A0"}
                            <input
                              id="standard-modifiers"
                              value={value.modifiers_2}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("modifiers_2")}
                              inputProps={{ maxlength: 2 }}
                              className="input-94"
                              maxLength="2"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                            {"\u00A0"}
                            <input
                              id="standard-modifiers"
                              value={value.modifiers_3}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("modifiers_3")}
                              maxLength="2"
                              className="input-94"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                            {"\u00A0"}
                            <input
                              id="standard-modifiers"
                              value={value.modifiers_4}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              onChange={handleChangeDialogue("modifiers_4")}
                              inputProps={{ maxlength: 2 }}
                              className="input-94"
                              maxLength="2"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                            {modifierError ? (
                              <p
                                style={{
                                  maxWidth: "143px",
                                  padding: "6px 0px 0px 0px"
                                }}
                                className="danger-text"
                              >
                                {modifierErrorDes}{" "}
                              </p>
                            ) : null}
                          </div>
                          <div className="form-wrapper">
                            {editPageFlag ? (
                              <div
                                className="mui-custom-form override-width-23 override-m-1"
                                style={{
                                  lineHeight: "100%"
                                }}
                              >
                                <label
                                  className="MuiFormLabel-root small-label"
                                  htmlFor="voidy"
                                >
                                  Void<span>*</span>
                                </label>
                                <div className="sub-radio">
                                  <Radio
                                    value="Y"
                                    id="voidy"
                                    name="void"
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : dialogueVoid || mainVoid || dataToEdit
                                    }
                                    checked={value.void === "Y"}
                                    onChange={handleChangeDialogue("void")}
                                  />
                                  <span className="text-black"> Yes</span>
                                  <Radio
                                    defaultChecked="true"
                                    id="voidy"
                                    value="N"
                                    className="ml-2"
                                    name="void"
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : dialogueVoid || mainVoid
                                    }
                                    checked={value.void === "N"}
                                    onChange={handleChangeDialogue("void")}
                                  />
                                  <span className="text-black"> No</span>
                                </div>
                              </div>
                            ) : null}
                            <div
                              className="mui-custom-form override-width-23 override-m-1"
                              style={{ lineHeight: "100%" }}
                            >
                              <label
                                className="MuiFormLabel-root small-label"
                                htmlFor="printra"
                              >
                                Print RA<span>*</span>
                              </label>
                              <div className="sub-radio">
                                <Radio
                                  value="Y"
                                  id="printra"
                                  name="printRA"
                                  checked={value.printRA === "Y"}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("printRA")}
                                />
                                <span className="text-black"> Yes</span>
                                <Radio
                                  defaultChecked="true"
                                  value="N"
                                  id="printra"
                                  className="ml-2"
                                  name="printRA"
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  checked={value.printRA === "N"}
                                  onChange={handleChangeDialogue("printRA")}
                                />
                                <span className="text-black"> No</span>
                              </div>
                            </div>
                            <div
                              className="custom-label-or override-width-5 override-m-1"
                              style={{ padding: "31px 3px 0px 3px" }}
                            >
                              <label>OR</label>
                            </div>
                            <NativeDropDown
                              className="override-width-23 override-m-1"
                              id="standard-select-mapsetid"
                              label="Mapset ID"
                                inputProps={{ maxLength: 30 }}
                                value={value.mapSetId}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : dialogueVoid || mainVoid || dataToEdit
                                }
                                onChange={handleChangeDialogue("mapSetId")}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              errTxt={
                                  mapSetIdError ? mapSetIdErrorDes : null
                                }
                              dropdownList={mapSetIdDropdown.map((option) => (
                                <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.code}-{option.description}
                                </option>
                                ))}
                            />
                          </div>
                        </div>
                      </form>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className="btn btn-success ml-1"
                        onClick={handleClickOpenDialogueBoxSave}
                        data-testid="Add_btn"
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : dialogueVoid || mainVoid || dataToEdit
                        }
                        title={editFlag ? "Update" : "Add"}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        {editFlag ? "Update" : "Add"}
                      </Button>
                      <Button
                        className="bt-reset btn-transparent  ml-1"
                        onClick={handleReset}
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : dialogueVoid || mainVoid || dataToEdit
                        }
                      >
                        <i className="fa fa-undo" aria-hidden="true"></i>
                        Reset
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                {tabValue === 0 && (
                  <div className="tab-holder mb-3 p-0 tableNoWrap">
                    <TableComponent
                      fixedTable
                      headCells={editPageFlag ? editHeadCells : headCells}
                      tableData={mainTableDetails || []}
                      onTableRowDelete={rowDeleteFiscalPend}
                      onTableRowClick={editRow}
                      defaultSortColumn={headCells[0].id}
                      isSearch={false}
                      // Audit Log Changes
                      onTableCellButtonClick={onClickAuditDialogLog}
                      setAuditLogRow={setAuditLogRow}
                      auditRowData={auditTableData}
                      setAuditRowData={setAuditTableData}
                      setShowLogTable={setShowLogDialogTable}
                      showLogDialogTable={showLogDialogTable}
                      uniqueKey="fiscalPendCriteriaSeqNum"
                      setClose={setClose}
                      close={close}
                    />
                    {opnFiscalPendDtl ? (
                      <div className="tabs-container" id="form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {!editFlag
                              ? "Add Fiscal Pend Detail"
                              : "Edit Fiscal Pend Detail"}
                          </div>
                          <div className="float-right mt-1">
                            {!editFlag ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-success ml-1"
                                onClick={handleClickOpenDialogueBoxSave}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : dialogueVoid || mainVoid || dataToEdit
                                }
                                title={"Add"}
                              >
                                <i className="fa fa-check" aria-hidden="true"></i>
                                Add
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-success ml-1"
                                  onClick={handleClickOpenDialogueBoxSave}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : dialogueVoid || mainVoid || dataToEdit
                                }
                                title={"Update"}
                              >
                                <i className="fa fa-check" aria-hidden="true"></i>
                                update
                              </Button>
                            )}
                            <Button
                              className="bt-reset btn-transparent  ml-1"
                              onClick={handleReset}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : dialogueVoid || mainVoid || dataToEdit
                              }
                              title={"Reset"}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleClose}
                              title={"Cancel"}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <div
                              className="form-wrapper"
                              style={{ padding: "5px 5px" }}
                            >
                              <NativeDropDown
                                className="override-width-23 override-m-1"
                                id="standard-select-bpidtype"
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                value={bpIDTypeCode !== undefined ? value.billingProviderIdType : DEFAULT_DD_VALUE}
                                  onChange={handleChangeDialogue(
                                    "billingProviderIdType"
                                  )}
                                  label="Billing Provider ID Type"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxLength: 3 }}
                                errTxt={
                                    billingProviderIdTypeError
                                      ? billingProviderIdTypeErrorDes
                                      : null
                                  }
                                dropdownList={billingProviderIdTypeDropdown.map(
                                    (option) => (
                                    <option
                                        key={option.code}
                                        value={option.code}
                                      >
                                        {option.description}
                                    </option>
                                    )
                                  )}
                              />
                              <div className="mui-custom-form override-width-23 override-m-1">
                                <TextField
                                  id="standard-bpid"
                                  fullWidth
                                  label="Billing Provider ID"
                                  value={value.billingProviderId}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue(
                                    "billingProviderId"
                                  )}
                                  inputProps={{ maxlength: 15 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  helperText={
                                    billingProviderIdError
                                      ? billingProviderIdErrorDes
                                      : null
                                  }
                                  error={
                                    billingProviderIdError
                                      ? billingProviderIdErrorDes
                                      : null
                                  }
                                />
                              </div>
                              <NativeDropDown
                                className="override-width-23 override-m-1"
                                  id="standard-select-bptype"
                                value={bpTypeCode !== undefined ? value.billingProviderType : DEFAULT_DD_VALUE}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue(
                                    "billingProviderType"
                                  )}
                                errTxt={
                                    billingProviderTypeError
                                      ? billingProviderTypeErrorDes
                                      : null
                                  }
                                  label="Billing Provider Type"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                dropdownList={billingProviderTypeDropdown.map((option) => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                  </option>
                                  ))}
                              />
                              <div className="mui-custom-form override-width-23 override-m-1">
                                <TextField
                                  required
                                  fullWidth
                                  id="standard-rankdetail"
                                  label="Rank"
                                  value={value.rank}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("rank")}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxlength: 6 }}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      AppConstants.NUMBER_ONLY_REGEX,
                                      ""
                                    );
                                  }}
                                  helperText={
                                    dialogueRankError
                                      ? dialogueRankErrorDes
                                      : null
                                  }
                                  error={
                                    dialogueRankError
                                      ? dialogueRankErrorDes
                                      : null
                                  }
                                />
                              </div>
                              <div className="mui-custom-form override-width-23 override-m-1">
                                <TextField
                                  id="standard-billingtaxonomy"
                                  fullWidth
                                  label="Billing Taxonomy"
                                  value={value.billingTaxonomy}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue(
                                    "billingTaxonomy"
                                  )}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxlength: 10 }}
                                />
                              </div>
                              <NativeDropDown
                                className="override-width-23 override-m-1"
                                id="standard-select-claimtype"
                                  label="Claim Type"
                                value={claimTypeCode !== undefined ? value.claimType : DEFAULT_DD_VALUE}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("claimType")}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxlength: 1 }}
                                dropdownList={claimTypeDropdown.map((option) => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                  </option>
                                  ))}
                              />
                              <NativeDropDown
                                className="override-width-23 override-m-1"
                                id="standard-select-benefitplan"
                                  label="Benefit Plan"
                                value={bplanCode !== undefined ? value.benefitPlan : DEFAULT_DD_VALUE}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("benefitPlan")}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxLength: 30 }}
                                dropdownList={benefitPlanDropdown.map((option) => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.code}-
                                      {option.description.replace("#", "")}
                                  </option>
                                  ))}
                              />
                              <NativeDropDown
                                className="override-width-23 override-m-1"
                                id="standard-select-cos"
                                  label="COS"
                                value={cosCode !== undefined ? value.COS : DEFAULT_DD_VALUE}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("COS")}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  title={getLongDescription(
                                    COSDropdown,
                                    value.COS
                                  )}
                                dropdownList={COSDropdown.map((option) => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                  </option>
                                  ))}
                              />
                              <div className="mui-custom-form override-width-23 override-m-1">
                                <TextField
                                  required
                                  fullWidth
                                  id="standard-capamount"
                                  label="Cap Amount"
                                  data-testid="test_cap_amount"
                                  value={value.dollarCapAmt}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                      ""
                                    );
                                  }}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  inputProps={{
                                    maxlength: 10
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    )
                                  }}
                                  onChange={handleDecimalValue(
                                    "dollarCapAmt"
                                  )}
                                  helperText={
                                    dollarCapAmtError
                                      ? dollarCapAmtErrorDes
                                      : null
                                  }
                                  error={
                                    dollarCapAmtError
                                      ? dollarCapAmtErrorDes
                                      : null
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form override-width-23 override-m-1">
                                <TextField
                                  id="standard-daystopend"
                                  fullWidth
                                  label="Days To Pend"
                                  value={value.daysToPend}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("daysToPend")}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      AppConstants.NUMBER_ONLY_REGEX,
                                      ""
                                    );
                                  }}
                                  inputProps={{ maxlength: 3 }}
                                />
                              </div>
                              <div className="mui-custom-form override-width-23 override-m-1">
                                <TextField
                                  id="standard-proccode"
                                  fullWidth
                                  label="Procedure Code"
                                  value={value.procedureCode}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue(
                                    "procedureCode"
                                  )}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxlength: 7 }}
                                />
                              </div>
                              <div className="mui-custom-form override-width-23 override-m-1">
                                <label
                                  className="MuiFormLabel-root small-label db"
                                  htmlFor="standard-modifiers"
                                  style={{ marginBottom: "15px" }}
                                >
                                  Modifiers
                                </label>
                                <input
                                  id="standard-modifiers"
                                  value={value.modifiers_1}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("modifiers_1")}
                                  inputProps={{ maxlength: 2 }}
                                  className="input-94"
                                  maxLength="2"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                                {"\u00A0"}
                                <input
                                  id="standard-modifiers"
                                  value={value.modifiers_2}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("modifiers_2")}
                                  inputProps={{ maxlength: 2 }}
                                  className="input-94"
                                  maxLength="2"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                                {"\u00A0"}
                                <input
                                  id="standard-modifiers"
                                  value={value.modifiers_3}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("modifiers_3")}
                                  maxLength="2"
                                  className="input-94"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                                {"\u00A0"}
                                <input
                                  id="standard-modifiers"
                                  value={value.modifiers_4}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : dialogueVoid || mainVoid || dataToEdit
                                  }
                                  onChange={handleChangeDialogue("modifiers_4")}
                                  inputProps={{ maxlength: 2 }}
                                  className="input-94"
                                  maxLength="2"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                                {modifierError ? (
                                  <p
                                    style={{
                                      maxWidth: "143px",
                                      padding: "6px 0px 0px 0px"
                                    }}
                                    className="danger-text"
                                  >
                                    {modifierErrorDes}{" "}
                                  </p>
                                ) : null}
                              </div>
                              <div className="form-wrapper">
                                {editPageFlag ? (
                                  <div
                                    className="mui-custom-form override-width-23 override-m-1"
                                    style={{
                                      lineHeight: "100%"
                                    }}
                                  >
                                    <label
                                      className="MuiFormLabel-root small-label"
                                      htmlFor="voidy"
                                    >
                                      Void<span>*</span>
                                    </label>
                                    <div className="sub-radio">
                                      <Radio
                                        value="Y"
                                        id="voidy"
                                        name="void"
                                        disabled={
                                          !userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : dialogueVoid ||
                                              mainVoid ||
                                              dataToEdit
                                        }
                                        checked={value.void === "Y"}
                                        onChange={handleChangeDialogue("void")}
                                      />
                                      <span className="text-black"> Yes</span>
                                      <Radio
                                        defaultChecked="true"
                                        id="voidy"
                                        value="N"
                                        className="ml-2"
                                        name="void"
                                        disabled={
                                          !userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : dialogueVoid || mainVoid
                                        }
                                        checked={value.void === "N"}
                                        onChange={handleChangeDialogue("void")}
                                      />
                                      <span className="text-black"> No</span>
                                    </div>
                                  </div>
                                ) : null}
                                <div
                                  className="mui-custom-form override-width-23 override-m-1"
                                  style={{ lineHeight: "100%" }}
                                >
                                  <label
                                    className="MuiFormLabel-root small-label"
                                    htmlFor="printra"
                                  >
                                    Print RA<span>*</span>
                                  </label>
                                  <div className="sub-radio">
                                    <Radio
                                      value="Y"
                                      id="printra"
                                      name="printRA"
                                      checked={value.printRA === "Y"}
                                      disabled={
                                        !userInquiryPrivileges
                                          ? !userInquiryPrivileges
                                          : dialogueVoid ||
                                            mainVoid ||
                                            dataToEdit
                                      }
                                      onChange={handleChangeDialogue("printRA")}
                                    />
                                    <span className="text-black"> Yes</span>
                                    <Radio
                                      defaultChecked="true"
                                      value="N"
                                      id="printra"
                                      className="ml-2"
                                      name="printRA"
                                      disabled={
                                        !userInquiryPrivileges
                                          ? !userInquiryPrivileges
                                          : dialogueVoid ||
                                            mainVoid ||
                                            dataToEdit
                                      }
                                      checked={value.printRA === "N"}
                                      onChange={handleChangeDialogue("printRA")}
                                    />
                                    <span className="text-black"> No</span>
                                  </div>
                                </div>
                                <div
                                  className="custom-label-or override-width-5 override-m-1"
                                  style={{ padding: "31px 3px 0px 3px" }}
                                >
                                  <label>OR</label>
                                </div>
                                <NativeDropDown
                                  className="override-width-23 override-m-1"
                                  id="standard-select-mapsetid"
                                  label="Mapset ID"
                                    inputProps={{ maxLength: 30 }}
                                  value={mapSetIDCode !== undefined ? value.mapSetId : DEFAULT_DD_VALUE}
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : dialogueVoid || mainVoid || dataToEdit
                                    }
                                    onChange={handleChangeDialogue("mapSetId")}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  errTxt={
                                      mapSetIdError ? mapSetIdErrorDes : null
                                    }
                                  dropdownList={mapSetIdDropdown.map((option) => (
                                    <option
                                        key={option.code}
                                        value={option.code}
                                      >
                                        {option.code}-{option.description}
                                    </option>
                                    ))}
                                />
                              </div>
                            </div>
                          </form>
                          {showLogDialogTable ? (
                            <div id="audit-dialog-table">
                              <AuditTableComponent
                                print
                                auditRowData={auditTableData}
                                setAuditRowData={setAuditTableData}
                                setShowLogTable={setShowLogDialogTable}
                                setClose={setClose}
                              />{" "}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {tabValue === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <Notes
                      disableNotes={disableNotes}
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      isNotesTable={true}
                      setFocusCheck ={setFocusCheck}
                      setErrorMessages={setErrorMessages}
                      userInquiryPrivileges={userInquiryPrivileges}
                    />
                  </div>
                )}
              </TabPanel>
              {showLogTable ? (
                <div id="audit-table">
                  <AuditTableComponent
                    print
                    auditRowData={auditRowData}
                    setAuditRowData={setAuditRowData}
                    setShowLogTable={setShowLogTable}
                  />{" "}
                </div>
              ) : null}
              <Footer print />
              <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
