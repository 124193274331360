/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/financialPayout/financialPayoutActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_FINANCIAL_PAYOUT:
      return { ...state, payload: action.fcns };
    case actionTypes.PAYEE_SEARCH_FINANCIAL_PAYOUT:
      return {...state, payloadPayees: action.payees };
    case actionTypes.PAYEE_OUTSTANDING_SEARCH_FINANCIAL_PAYOUT:
      return { ...state, payloadPayeesOutstanding: action.payeesOutstanding };
    case actionTypes.PAYEE_ID_TYPE_DROPDOWN:
      return { ...state, payeeIdTypeDropdown: action.payeeIdTypeDropdown };
    case actionTypes.MCN_SEARCH_FINANCIAL_PAYOUT:
      return { ...state,payloadMcns: action.mcns };
    case actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE:
      return { ...state, payeeIDTypeCodeDetails: action.payeeIDTypeCodeDetails };
    case actionTypes.SEARCH_FINANCIAL_PAYEE:
      return {...state, payeeRes: action.payeeRes };
    case actionTypes.PAYOUT_ADD_ACTION:
      return { ...state, financialPayoutAddResponse: action.financialPayoutAddResponse };
    case actionTypes.PAYOUT_UPDATE_ACTION:
      return { ...state, financialPayoutUpdateResponse: action.financialPayoutUpdateResponse };
    case actionTypes.NEW_PAYOUT_RESPONSE:
      return { ...state, newPayoutResponse: action.newPayoutResponse };
    case actionTypes.PAYOUT_DETAILS:
      return { ...state, payoutDetails: action.payoutDetails };
    case actionTypes.PAYOUT_MEMBER_DETAILS:
      return { ...state, payoutMemberDetails: action.payoutMemberDetails };
    case actionTypes.GET_SINGLE_PAYOUT_DETAILS:
      return { ...state, singlePayoutDetails: action.singlePayoutDetails };
    case actionTypes.RESET_SINGLE_PAYOUT_DETAILS:
      return { ...state, singlePayoutDetails: null };
    case actionTypes.DROPDOWNDATA:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.RESETDATA:
      return action.resetData;
    default: return state;
  }
};

export default reducer;
