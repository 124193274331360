/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useCallback } from "react";
import TableComponent from "../Table/Table";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GET_APP_DROPDOWNS } from "../Dropdowns/actions";
import * as Dropdowns from "../Dropdowns/dropdowns";
import NativeDropDown from "../Dropdowns/NativeDropDown";

const headCells = [
  {
    id: "auditTimeStamp",
    numeric: false,
    isDateTime: true,
    disablePadding: true,
    label: "Date/Time",
    width: "20%"
  },
  {
    id: "userId",
    numeric: false,
    disablePadding: false,
    label: "User Id",
    width: "15%"
  },
  {
    id: "noteText",
    numeric: false,
    disablePadding: false,
    label: "Note",
    isNote: true,
    width: "45%"
  },
  {
    id: "usageTypeCodeDesc",
    numeric: false,
    disablePadding: false,
    label: "Usage Type Code",
    width: "40%"
  }
];

function Notes(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const {
    addNotes,
    notesTableData,
    noteSetListInput,
    setNoteSetListInput,
    usageTypeCodeData,
    setNoteAddFlag,
    noteaddFlag,
    setEditNoteData,
    disableFields,
    disableNotes,
    setErrorMessages,
    userInquiryPrivileges,
    isFocusCheck,
    setFocusCheck,
    paymentValues
  } = props;
  
  // notes table data
  const [notesTable, setNotesTable] = React.useState([]);
  // open add note popup
  const [openAddNote, setOpenAddNote] = React.useState(false);
  const dispatch = useDispatch();
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  useEffect(() => {
    if (openAddNote) {
      document.getElementById("notes-div").scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [openAddNote]);

  useEffect(()=>{
    onDropdowns([Dropdowns.NOTES_USG_CODE,
      Dropdowns.F_RSN_CD,
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PROVIDER_ID_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.FUND_CODE,
      Dropdowns.CATEGORY_OF_SERVICE_VALIDVALUE,
      Dropdowns.AGNCY_CODE,
      Dropdowns.PROVIDER_TYPE_CODE,
      Dropdowns.CLAIM_TYPE,
      Dropdowns.R_FUNC_AREA_CD,
      Dropdowns.MEMBER_TYPE_DD,
      Dropdowns.COS,
      Dropdowns.DATE_FORMAT,
      Dropdowns.MAP_TYP_CODE,
      Dropdowns.ACTIVITY_TYPE,
      Dropdowns.RECOUPMENT,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.BANK_ACCT_TYPE,
      Dropdowns.EFT_STAT_CODE,
      Dropdowns.CONTACT_TYPE,
      Dropdowns.STATE_CODE,
      Dropdowns.ADDRESS_USAGE_TYPE,
      Dropdowns.PAY_STATUS,
      Dropdowns.BNKRPTCY_ID,
      Dropdowns.ADR_USG_STAT_CD,
      Dropdowns.RECOUPMENT,
	  Dropdowns.F_PAYMENT_REQ_TY_CD
    ]);
  },[])
 
  // Total Characters
  const [totalCharacters, setTotalCharacters] = React.useState(320);
  // Words Count
  const [wordCount, setWordCount] = React.useState(totalCharacters || 0);

  const [{ usageTypeError, textError }, setShowError] = React.useState(false);
  const [
    { usageTypeErrorText, textErrorText },
    setShowErrorText
  ] = React.useState("");
  const logInUserID = useSelector(state => state.sharedState?.logInUserID);
  // Input Values
  const [values, setValues] = React.useState({
    usageTypeCode: DEFAULT_DD_VALUE,
    text: ""
  });
  // current note
  const [currentNote, setCurrentNote] = React.useState(
    notesTableData && notesTableData[0] ? notesTableData[0].noteText : null
  ); 
  const editRow = row => event => {
    setFocusCheck(true);
    setCurrentNote(row.noteText);
    if (setEditNoteData) {
      setEditNoteData(row);
    }
    setNoteSetListInput({
      ...noteSetListInput,
      usageTypeCode: row.usageTypeCode,
      noteText: row.noteText
    });
  };
  // add notes method

  const addNote = () => {
    if (props.setSuccessMessages) {
      props.setSuccessMessages();
    }
    if (setErrorMessages) {
      setErrorMessages([]);
    }
    setOpenAddNote(true);
    setNoteSetListInput({
      ...noteSetListInput,
      usageTypeCode: DEFAULT_DD_VALUE,
      noteText: "",
      usageTypeDesc: ""
    });
  };
  // handle Close add note popup

  // word count
  const checkWordCount = e => {
    const textValue = e.target.value;
    const leftChars = totalCharacters - textValue.length;
    setWordCount(leftChars);
    setValues({ ...values, text: textValue });
  };

  // handle change input values
  const handleChangeValue = name => event => {
    setFocusCheck(true);
    setNoteSetListInput({
      ...noteSetListInput,
      [name]: event.target.value    
    });
    if (name === "usageTypeCode") {
      setNoteSetListInput({
        ...noteSetListInput,
        [name]: event.target.value,
        usageTypeDesc: mapCodeDescription(
          event.target.value,
          usageTypeCodeData
        )       
      });
    }
  };

  // to close popup
  const handleCloseAddNote = () => {
    if (setErrorMessages) {
      setErrorMessages([]);
    }
    setOpenAddNote(false);
    setWordCount(320);
    setShowError(false);
  };

  // to save data to table
  const handleClickOpenDialogueBoxSave = () => {
    const value = noteSetListInput;
    var usageTypeError;
    var textError = false;
    var usageTypeErrorText;
    var textErrorText = "";
    let tempErrorArray = [];
    if (noteSetListInput.usageTypeCode === DEFAULT_DD_VALUE) {
      usageTypeError = true;
      usageTypeErrorText = "Usage Type Code is required.";
      tempErrorArray.push(usageTypeErrorText);
    }
    if (noteSetListInput.noteText === "") {
      textError = true;
      textErrorText = "Text is required.";
      tempErrorArray.push(textErrorText);
    }
    if (tempErrorArray && tempErrorArray.length > 0) {
      if (setErrorMessages) {
        setErrorMessages(tempErrorArray);
      }
    }
    if (!usageTypeError && !textError) {
      let dt = new Date();
      value.auditTimeStamp = dt;
      value.addedAuditTimeStamp = dt;
      value.auditUserID = logInUserID;
      value.addedAuditUserID = logInUserID;
      value.noteText = noteSetListInput.noteText
        ? noteSetListInput.noteText.trim()
        : noteSetListInput.noteText;
      setCurrentNote(value.noteText);
      addNotes(value);
      handleCloseAddNote();
    }
    setShowError({
      usageTypeError: usageTypeError,
      textError: textError
    });
    setShowErrorText({
      usageTypeErrorText: usageTypeErrorText,
      textErrorText: textErrorText
    });
  };

  // to reset popup
  const handleReset = () => {
    setFocusCheck(false);
    if (setErrorMessages) {
      setErrorMessages([]);
    }
    setNoteSetListInput({
      ...noteSetListInput,
      usageTypeCode: DEFAULT_DD_VALUE,
      noteText: ""
    });
    setWordCount(320);
    setShowError([]);
    setShowErrorText([]);
  };

  // current note initial setting

  useEffect(() => {
    if (notesTable.length === 0) {
      setCurrentNote("");
    }    
    const tempArray = JSON.parse(JSON.stringify(notesTableData)).sort((a, b) =>
      new Date(a.auditTimeStamp) > new Date(b.auditTimeStamp) ? -1 : 1
    );
    if (tempArray[0]) {
      setCurrentNote(tempArray[0].noteText);
    }
  }, [notesTableData, notesTableData && notesTableData.length]);

  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };
    
  const getTableData = useCallback(() => {
    return notesTableData?.map((each, index) => {
      return {
        ...each,
        index: index,
        usageTypeCodeDesc:
          addDropdowns?.["G1#G_NOTE_TY_CD"]?.find(
            (value) => value.code === each.usageTypeCode
          )?.description ||
          each.usageTypeCode ||
          ""        
      };
    });
  }, [notesTableData]);

  return (
    <div>
      <div className="tab-holder">
        <div
          className="mui-custom-form no-margin form-control-text notes-text float-left"
          id="notes-history-00"
        >
          Current Note
        </div>
        <div className="float-right my-3">
          <button
            class="btn-text-main-add btn-success ml-1"
            onClick={addNote}
			data-testId = 'test_add_btn'
            disabled={((userInquiryPrivileges !== undefined &&!userInquiryPrivileges)? !userInquiryPrivileges : null) || paymentValues?.hasBeenProcessed === 'Y'}
            title={'Add New Note'}
          >
            <i class="fa fa-plus" aria-hidden="true" title={"Add New Note"}></i>
            Add
          </button>
        </div>
      </div>
      {/* added */}
      <div className="clearfix"></div>
      <div className="tab-holder notes-table-content" print>
        {currentNote}
      </div>
      <div class="clearfix"></div>
      <div
        className="tab-holder form-control-text notes-text-history"
        id="notes-history-01"
      >
        Notes History
      </div>
      <div class="tab-holder table-no-wrap table-p-5">
        <TableComponent
          print={props.print}
          headCells={headCells}
          isSearch={true}
          tableData={getTableData() || []}
          onTableRowClick={editRow}
          defaultSortColumnDesc={true}
          defaultSortColumn={"auditTimeStamp"}
          isNotesTable={props.isNotesTable || false}
        />
      </div>
      {openAddNote ? (
        <div className="tabs-container" id="notes-div">
          <div className="tab-header  mini-tab-header">
            <div className="tab-heading float-left">{"Add New Note"}</div>{" "}
            <div className="float-right mt-1">
              <Button
                className="btn btn-success ml-1"
                title={'Add'}
                onClick={handleClickOpenDialogueBoxSave}
                disabled = {paymentValues?.hasBeenProcessed === 'Y'}
				data-testid="note-save-btn"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                {"Add"}
              </Button>
              <Button
                className="bt-reset btn-transparent  ml-1'"
                onClick={handleReset}
                disabled = {paymentValues?.hasBeenProcessed === 'Y'}
              >
                <i class="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary btn btn-outlined ml-1"
                onClick={handleCloseAddNote}
                data-testid="test_notes_cancel"
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="form-border my-3">
            <form autoComplete="off">
              <div className="form-wrapper" style={{ minWidth: "480px" }}>
                <NativeDropDown
                  className="override-width-25"
                    id="usageTypeCode"
                  isRequired
                    value={noteSetListInput.usageTypeCode}
                    onChange={handleChangeValue("usageTypeCode")}
                    label="Usage Type Code"		
					inputProps={{"data-testid" : 'usage-type-code'}}			
                  errTxt={usageTypeError ? usageTypeErrorText : null}
                  dropdownList={usageTypeCodeData
                      ? JSON.parse(
                          JSON.stringify(
                            usageTypeCodeData.sort((a, b) =>
                              a.code.toLocaleUpperCase() <
                              b.code.toLocaleUpperCase()
                                ? -1
                                : 1
                            )
                          )
                        ).map(option => (
                          <option key={option.code} value={option.code}>
                            {option.description}
                          </option>
                        ))
                      : null}
                />
                <div className="mui-custom-form with-textarea override-width-95">
                  <Form.Group>
                    <Form.Label className="MuiFormLabel-root small-label">
                      Note{" "}
                      <span class="Mui-required" id="text-label-01">
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      aria-describedby="text-label-01"
                      aria-label="form-controll-01"
                      as="textarea"
                      rows="4"
                      name="Note"
                      value={noteSetListInput.noteText}
                      onChange={handleChangeValue("noteText")}
                      onKeyUp={checkWordCount}
					  data-testid="note-text"
                      maxLength={totalCharacters}
                      style={{ width: "100%" }}
                    />
                    {textError > 0 ? (
                      <p
                        class="MuiFormHelperText-root Mui-error Mui-required"
                        role="alert"
                      >
                        {textErrorText}
                      </p>
                    ) : null}
                  </Form.Group>
                  <div
                    style={{
                      border: "1px solid rgb(169, 169, 169)",
                      borderRadius: ".25rem",
                      paddingLeft: "5px",
                      width: "300px",
                      alignText: "center"
                    }}
                  >
                    Characters Remaining:{wordCount}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default React.memo(Notes);
