/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { appReducer } from "./Modules/ApplicationConfiguration/services/store";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import "./index.scss";
import App from "./App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import thunk from "redux-thunk";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import accRecReducer from "./Modules/AccountReceivables/Store/Reducers/accRecReducer";
import mapSetReducer from "./Modules/ApplicationConfiguration/Store/Reducers/mapSet/mapSetReducer";
import bankAccountReducer from "./Modules/BankAccountAndLockbox/Store/Reducers/BankAccountAndLockBoxSearchReducers";
import budgetMaintenanceReducer from "./Modules/BudgetMaintenance/Store/Reducers/budgetMaintenance/budgetMaintenanceReducer";
import accountReceivableReducer from "./Modules/AccountReceivables/Store/Reducers/accountReceivable/accountReceivableReducer";
import accountReceivableTransferReducer from "./Modules/AccountReceivables/Store/Reducers/accountReceivable/ARTransferReducer";
import accountReceivableAdjustReducer from "./Modules/AccountReceivables/Store/Reducers/accountReceivable/ARAdjustReducer";
import fundCodeReducer from "./Modules/FundCode/Store/Reducers/FundCodeSearchReducers";
import financialEntityReducer from "./Modules/FinancialEntities/Store/Reducers/FinancialEntityReducer";
import FinancialReceiptReducer from "./Modules/FinancialReceipt/Store/Reducers/FinancialReceipt/financialReceiptReducer";
import sharedReducer from "./SharedModules/store/Reducers/Reducers";
import financialPayoutReducer from "./Modules/FinancialPayOut/Store/Reducers/FinancialPayout/financialPayoutReducer";
import accountPayableReducer from "./Modules/AccountPayables/Store/Reducers/AccountPayableReducer";
import fiscalPendReducer from "./Modules/FiscalPendCriteria/Store/Reducers/FiscalPendAddReducer";
import fiscalPendSearchReducer from "./Modules/FiscalPendCriteria/Store/Reducers/FiscalPendCriteriaSearchReducer";
import FinancialInquiryReducer from "./Modules/FinancialInquiry/Store/Reducers/FinancialInquiry/FinancialInquiryReducer";
import writeOffReducer from "./Modules/WriteOffAndReEstablishment/Store/Reducers/WriteOffReducers";
import correctionSearchReducer from "./Modules/1099Correction/Store/Reducers/CorrectionSearchReducer";
import eventLoggingSearchReducer from "./Modules/ProjectControl/Store/Reducers/EventLoggingSearchReducer";
import GlobalAuditSearch from "./Modules/ProjectControl/GlobalAuditSearch/GlobalAuditSearchReducer";
import receiptReissueReducer from "./Modules/ReceiptReissue/Store/Reducers/ReceiptReissueReducers";
import securityReducer from "./Modules/Security/Components/Store/Reducer/SecurityReducer";
import emarReducer from "./Modules/EMAR/Store/Reducers/EmarReducer";
import waiverReducer from "./Modules/WaiverLine/Store/Reducers/WaiverReducer";
import frcReducer from "./Modules/FRC/Store/Reducers";
import cms64Reducer from "./Modules/CMS64/Store/Reducers/EmarReducer";
import textAudit from './Modules/ApplicationConfiguration/Store/Reducers/AppConfigCommon/appConfigCommonReducer';
import dataUploadReducer from "./Modules/MassUpload/Store/Reducers/DataUploadReducer";
import eventlogsReducer from './Modules/ProjectControl/EventLogging/reducer';
import reducer from "./SharedModules/Dropdowns/reducer";
import refreshToken from "./SharedModules/store/Reducers/refreshReducers";
import changePwdReducer from './Modules/Security/Components/changePwdReducer';
import userLoginPrivileges from './SharedModules/store/Reducers/userLoginPrivileges';
import paymentStatusInquiryReducer from './Modules/PaymentStatusInquiry/Store/Reducer/PaymentStatusInquiryReducer';
import paymentCriteriaReducer from './Modules/PaymentCriteria/Store/Reducers/PaymentCriteriaReducers';
import { REMOVE_L2_KEY_DATA_FROM_STORE } from './SharedModules/store/Actions/ActionTypes';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  textAuditState: textAudit,
  appConfigState: appReducer,
  accRecState: accRecReducer,
  mapSetState: mapSetReducer,
  bankAccountState: bankAccountReducer,
  budgetMaintenanceState: budgetMaintenanceReducer,
  accountReceivableState: accountReceivableReducer,
  accountReceivableTransferState: accountReceivableTransferReducer,
  accountReceivableAdjustState: accountReceivableAdjustReducer,
  fundCodeState: fundCodeReducer,
  financialEntityState: financialEntityReducer,
  financialReceiptState: FinancialReceiptReducer,
  financialPayoutState: financialPayoutReducer,
  sharedState: sharedReducer,
  accountPayableState: accountPayableReducer,
  fiscalPendState: fiscalPendReducer,
  fiscalPendSearchState: fiscalPendSearchReducer,
  correctionSearchState: correctionSearchReducer,
  financialInquiryState: FinancialInquiryReducer,
  paymentStatusInquiryState: paymentStatusInquiryReducer,
  writeOffState: writeOffReducer,
  eventLoggingSearchState: eventLoggingSearchReducer,
  receiptReissueState: receiptReissueReducer,
  securityStae: securityReducer,
  emarState: emarReducer,
  waiverState: waiverReducer,
  frcState: frcReducer,
  cms64State: cms64Reducer,
  dataUploadState: dataUploadReducer,
  eventLog:eventlogsReducer,
  GlobalAuditSearch: GlobalAuditSearch,
  appDropDowns: reducer,
  refreshToken:refreshToken,
  changePassword:changePwdReducer,
  userLoginPrivileges:userLoginPrivileges,
  paymentCriteriaState:paymentCriteriaReducer,
});

const HigherOrderReducer = (reducers) => {
  return (state = {}, action) => {
    switch (action.type) {
      case REMOVE_L2_KEY_DATA_FROM_STORE:
        return {
          ...state,
          [action.l1Key]: {
            ...state[action.l1Key],
            [action.l2Key]: action.data
          }
        };
      default:
        return reducers(state, action);
    }
  };
};

const store = createStore(
  HigherOrderReducer(rootReducer),
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={ store }>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
