/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

/* Search Account Constants */
export const MIN_CHAR_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const INVALID_DATE_FORMAT = 'Please enter the date in correct format.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const INVALID_FORMAT = 'Please enter the data in correct format.';
/* Add/Update Bank Account Constants */
export const NAME_ERROR = 'is required.';
export const DATE_INVALID = 'entered is invalid.';
export const UPDATE_SUCCESS_MESSAGE = 'System successfully saved the information.';
export const SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const ROUTING_NUMBER_ERROR = 'Routing Number must be 9 digits.';
export const ROUTING_VALIDATION_ERROR = 'Please enter the data in correct format for Routing/Bank ABA #.'
/* Account Constants */
export const ACCOUNT_BEGIN_DATE_ERROR = 'Bank Account begin date must be less than or equal to end date. Please re-enter.';
export const ACCOUNT_BEGIN_DATE_SMALLER_ERROR = 'Bank Account begin date must be greater than or equal to the current system date. Please re-enter.';

/* LockBox Constants */
export const ONE_LOCKBOX_REQ = 'At least One LockBox Information has to be defined.';
export const DUPLICATE_VALUE_LOCKBOX_NUMBER = 'Lockbox details are overlapping with the existing Lockbox.';
export const LOCKBOX_BEGIN_DATE_GREATER_ERROR = 'For each Lockbox the begin date must be less than or equal to the end date. Please re-enter.';
export const LOCKBOX_BEGIN_DATE_SMALLER_ERROR = 'For each Lockbox the begin date must be greater than or equal to the current system date. Please re-enter.';
export const LOCKBOX_DATES_MATCH_ERROR = 'For each lockbox the begin and end dates must be within the bank accounts begin and end dates. Please re-enter.';

/* Address Constants */
export const INVALID_ADDRESS = 'Invalid Service Location Address.';
export const ONE_ADDRESS_REQ = 'At least One Address Information has to be defined.';
export const ADDRESS_DATES_MATCH_ERROR = 'Address Begin and End dates must be within Bank Accounts Begin and End Date.';
export const ADDRESS_BEGIN_DATE_GREATER_ERROR = 'Address End Date must be greater than or equal to the Address Begin Date. Please re-enter.';
export const ADDRESS_TYPE_REQUIRED = 'Address Type is required.';
export const BEGINDATE_REQUIRED = 'Begin Date is required.';
export const END_DATE_REQUIRED = 'End Date is required.';
export const ADDRESS_LINE_1_REQUIRED = 'Address Line 1 is required.';
export const SIGNIFICANT_TYPE_CODE_REQUIRED = 'Significance Type Code is required.';
export const INVALID_ZIPCODE = 'Zip Code is invalid.';
export const VERIFIED_ADDRESS = 'Please verify or Enter a valid Address.';
export const STATUS_REQUIRED = 'Status is required.';
export const CITY_REQUIRED = 'City is required.';
export const STATE_REQUIRED = 'State is required.';
export const ZIP_CODE_REQUIRED = 'Zip Code is required.';

/* Contact Constants */
export const ONE_CONTACT_REQ = 'At least One Contact Information has to be defined.';
export const BEGIN_DATE_MATCH_ERROR = 'Contact Begin and End dates must be within Bank Accounts Begin and End Date.';
export const CONTACT_BEGIN_DATE_GREATER_ERROR = 'Contact End Date must be greater than or equal to the Contact Begin Date. Please re-enter.';
export const DEATH_DATE_ERROR = 'Date Of Death can not be lesser than Date Of Birth.';
export const ACTIVE_CONTACT_ERROR = 'One active contact should be present for an active bank account.';

