/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LockBoxTable from "./LockBoxTable";
import AddressTable from "./AddressTable";
import ContactTable from "./ContactsTable";
import * as ErrorConstants from "./ErrorConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuditLogDetailsAction,
  getAuditLogLockBoxDetailsAction,
  getAuditLogAddressDetailsAction,
  getAuditLogContactDetailsAction,
  getDropdownDataAction,
  getDropdownTypeDataAction,
  resetBankAccountAndLockBox,
  updateBankAccountAndLockBox,
  searchLockBoxDetailsAction
} from "../Store/Actions/BankAccountAndLockBoxSearchActions";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import * as DateUtilities from "../../../SharedModules/DateUtilities/DateUtilities";
import {
  verifyAddressAPI
} from "../../../SharedModules/store/Actions/SharedAction";
import { Prompt } from "react-router-dom";
import moment from "moment";

import * as AppConstants from "../../../SharedModules/AppConstants";
import dropdownCriteria from "./BankAccountAndLockBoxAddEdit.json";
import dropdownTypeCriteria from "./AccountType.json";
import Footer from "../../../SharedModules/Layout/footer";
import NavHOC from "../../../SharedModules/Navigation/NavHOC";
import Notes from "../../../SharedModules/Notes/Notes";
import NoSaveMessage from "../../../SharedModules/Errors/NoSaveMessage";
import SuccessMessages from "../../../SharedModules/Errors/SuccessMessage";
import Radio from "@material-ui/core/Radio";
import AuditTableComponent from "../../../SharedModules/AuditLog/AuditTableComponent";
import { removeLeadingTrailingSpaces } from "../../../SharedModules/CommonUtilities/commonUtilities";
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from "../../../SharedModules/Dropdowns/NativeDropDown";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: "left"
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default NavHOC({
  Wrapped: BankAccountLockBoxEdit,
  action: searchLockBoxDetailsAction,
  url: "/BankAccountLockBoxEdit",
  selector: "bankAccountState.lockBoxData"
});

function BankAccountLockBoxEdit (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const _payload_combine =
    (props.payloadData || props.location.state) &&
    props.location.state.payloadData;
  const dispatch = useDispatch();
  const classes = useStyles();
  const onReset = () => dispatch(resetBankAccountAndLockBox());
  const dropDownDispatch = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const dropDownTypeDispatch = dropdownValues =>
    dispatch(getDropdownTypeDataAction(dropdownValues));
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);

  // audit log implementation starts here
  const [showLogTable, setShowLogTable] = useState(false);
  const [auditButtonToggle, setAuditButtonToggle] = useState(false);
  const onClickAuditLog = () => {
    const tableName = "f_bank_acct_tb";
    const keyValue = {
      f_bank_routng_num: _payload_combine
        ? _payload_combine.routingNumber
        : null,
      f_bank_acct_num: _payload_combine ? _payload_combine.accountNumber : null
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById("audit-table")) {
 document.getElementById("audit-table").scrollIntoView({
        behavior: "smooth"
      });
}
  }, [showLogTable]);
  const auditLogData = useSelector(
    state => state.bankAccountState.auditDetails
  );
  const [auditRowData, setAuditRowData] = useState([]);
  const filterAuditTable = rowData => {
    return rowData.afterValue || rowData.beforeValue;
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);
  // lock box table
  const [showLogDialogTable, setShowLogDialogTable] = useState(false);
  const auditLogTableData = useSelector(
    state => state.bankAccountState.auditLockBoxDetails
  );
  const [auditTableData, setAuditTableData] = useState([]);
  const [close, setClose] = useState(true);
  const [auditLogRow, setAuditLogRow] = useState(null);
  const onClickAuditDialogLog = row => {
    const auditRequest = [
    {
      tableName: 'f_lock_box_tb',
      keyValue: {
        f_lock_box_sk: row.lockBoxId
      },
      columnNames: "f_lock_box_beg_dt,f_lock_box_end_dt,f_lock_box_num,f_lock_box_desc"
    }];

    dispatch(
      getAuditLogLockBoxDetailsAction(auditRequest, "auditLogTableData")
    );
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById("audit-dialog-table")) {
 document.getElementById("audit-dialog-table").scrollIntoView({
        behavior: "smooth"
      });
}
  }, [showLogDialogTable]);
  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);
  // adress
  const [showLogAddressTable, setShowLogAddressTable] = useState(false);
  const auditLogAddressTableData = useSelector(
    state => state.bankAccountState.auditAddressDetails
  );
  const [auditAddressTableData, setAuditAddressTableData] = useState([]);
  const [auditLogAddressRow, setAuditLogAddressRow] = useState(null);

  const onClickAuditAddressLog = row => {
    const auditReq = [
      {
        tableName: "g_adr_usg_tb",
        keyValue: {
          g_cmn_enty_sk: row.commonEntitySK,
          g_adr_usg_ty_cd: row.addressUsageTypeCode,
          g_adr_sk: row.addressSK,
          g_adr_usg_seq_num: row.addressUsageSequenceNumber
        }
      },
      {
        tableName: 'g_adr_tb',
        keyValue: {
          g_adr_sk: row.addressSK
        },
        columnNames: "g_line1_adr,g_city_nam,g_us_state_cd,g_zip5_cd,g_line4_adr,g_cnty_cd,g_line2_adr,g_town_cd,g_cntry_cd"
      }
    ];

    dispatch(
      getAuditLogAddressDetailsAction(auditReq, "auditLogTableData")
    );
    setShowLogAddressTable(true);
  };

  useEffect(() => {
    if (auditLogAddressRow && auditButtonToggle) {
      setAuditAddressTableData([]);
      onClickAuditAddressLog(auditLogAddressRow);
    } else {
      setAuditAddressTableData([]);
    }
  }, [auditLogAddressRow, auditButtonToggle]);
  useEffect(() => {
    if (auditLogAddressTableData) {
      setAuditAddressTableData(
        auditLogAddressTableData.filter(filterAuditTable)
      );
    }
  }, [auditLogAddressTableData]);

  useEffect(() => {
    if (showLogAddressTable && document.getElementById("audit-Address-table")) { document.getElementById("audit-Address-table").scrollIntoView(); }
  }, [showLogAddressTable]);
  // contact
  const [showLogContactsTable, setShowLogContactsTable] = useState(false);
  const auditLogContactsTableData = useSelector(
    state => state.bankAccountState.auditContactDetails
  );
  const [auditContactsTableData, setAuditContactsTableData] = useState(
    []
  );
  const [auditLogContactsRow, setAuditLogContactsRow] = useState(null);

  const onClickAuditContactsLog = row => {
    const auditReq = [
      {
        tableName: 'g_cmn_enty_rep_xref_tb',
        keyValue: {
          g_cmn_enty_sk: row.commonEntitySK,
          g_rep_sk: row.representativeSK,
          g_rep_xref_ty_cd: row.contactType
        }
      }, {
        tableName: 'g_rep_tb',
        keyValue: {
          g_rep_sk: row.representativeSK
        },
        columnNames: 'g_first_nam,g_last_nam,g_gender_cd,g_dob_dt,g_title_nam,g_nam_prefx_cd,g_mid_nam,g_sfx_nam,g_ssn,g_dod_dt'
      }
    ];
    dispatch(
      getAuditLogContactDetailsAction(auditReq, "auditLogTableData")
    );
    setShowLogContactsTable(true);
  };
  useEffect(() => {
    if (auditLogContactsRow && auditButtonToggle) {
      setAuditContactsTableData([]);
      onClickAuditContactsLog(auditLogContactsRow);
    } else {
      setAuditContactsTableData([]);
    }
  }, [auditLogContactsRow, auditButtonToggle]);
  useEffect(() => {
    if (auditLogContactsTableData) {
      setAuditContactsTableData(
        auditLogContactsTableData.filter(filterAuditTable)
      );
    }
  }, [auditLogContactsTableData]);

  useEffect(() => {
    if (showLogContactsTable && document.getElementById("audit-Contacts-table")) { document.getElementById("audit-Contacts-table").scrollIntoView(); }
  }, [showLogContactsTable]);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
      setShowLogAddressTable(false);
      setShowLogContactsTable(false);
    }
  }, [auditButtonToggle]);

  // Audit Log Implementation ends
  const [allowNavigation, setAllowNavigation] = useState(false);
  const [addressTypeList, setaddressTypeList] = useState([]);
  const [significanceTypeList, setsignificanceTypeList] = useState([]);
  const [addStatusList, setaddStatusList] = useState([]);
  const [addStateList, setaddStateList] = useState([]);
  const [addCountryList, setaddCountryList] = useState([]);
  const [accountTypeList, setaccountTypeList] = useState([]);
  const [changeReasonList, setchangeReasonList] = useState([]);
  const [redirect, setRedirect] = useState(0);
  const [contactPrefixList, setcontactPrefixList] = useState([]);
  const [countyList, setcountyList] = useState([]);
  const [contactSuffixList, setcontactSuffixList] = useState([]);
  const [contactStatusList, setcontactStatusList] = useState([]);
  const [contactTypeList, setcontactTypeList] = useState([]);
  const [contactSignificanceList, setcontactSignificanceList] = useState(
    []
  );
  const [genderList, setgenderList] = useState([]);
  const dropdown = useSelector(state => state.bankAccountState.dropdownData);
  const typeDropdown = useSelector(
    state => state.bankAccountState.typeDropdownData
  );
  const updateAPICall = saveObj =>
    dispatch(updateBankAccountAndLockBox(saveObj));
  const saveAPIResult = useSelector(
    state => state.bankAccountState.updateBankAccountAndLockBox
  );
  const [disableEndDate, setDisableEndDate] = useState(false);
  const onUpdateSuccess = values =>
    dispatch(searchLockBoxDetailsAction(values));
  const payloadData = useSelector(state => state.bankAccountState.lockBoxData);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const validateAddress = values => {
    dispatch(verifyAddressAPI(values));
  };
  let validateAddressResult = useSelector(
    state => state.sharedState.addressValidatedData
  );
  const [addressError, setAddressError] = useState([]);

  const toPrintRef = useRef();
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [successMessages, setSuccessMessages] = useState([]);

  // Notes
  const [notesTableData, setNotesTableData] = useState([]);
  const [notesInput, setNotesInput] = useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: DateUtilities.generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = useState([]);
  const [editNoteData, setEditNoteData] = useState({});
  const [filteredAddressData, setFilteredAddressData] = useState([]);
  const [filteredContactData, setFilteredContactData] = useState([]);
  const [lockBoxTableData, setLockBoxTableData] = useState(null);
  const [addressTableData, setAddressTableData] = useState(null);
  const [addContactsData, setaddContactsData] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSearchDate, setSelectedSearchDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [
    selectedLockBoxBeginDate,
    setSelectedLockBoxBeginDate
  ] = useState(null);
  const [selectedLockBoxEndDate, setSelectedLockBoxEndDate] = useState(
    null
  );
  const [
    selectedAddAddressBeginDate,
    setSelectedAddAddressBeginDate
  ] = useState(null);
  const [
    selectedAddAddressEndDate,
    setSelectedAddAddressEndDate
  ] = useState(null);
  const [
    selectedContactBeginDate,
    setSelectedContactBeginDate
  ] = useState(null);
  const [selectedContactEndDate, setSelectedContactEndDate] = useState(
    null
  );
  const [selectedContactDOB, setSelectedContactDOB] = useState(null);
  const [selectedContactDOD, setSelectedContactDOD] = useState(null);
  const [rowLockBoxData, setrowLockBoxData] = useState([{}]);
  const [rowContactData, setrowContactData] = useState([{}]);
  const [rowAddressData, setrowAddressData] = useState([{}]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentLockbox, setCurrentLockbox] = useState(null);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [openLockBox, setOpenLockBox] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [focusCheck, setFocusCheck] = useState(false);
  const [initalFocusCheck, setInitalFocusCheck] = useState(false);
  const [isEditLockBox, setIsEditLockbox] = useState(false);

  const getValues = _payloadData => ({
    id: "",
    code: "",
    shortDescription: _payloadData.functionalArea || "",
    longDescription: "",
    constantText: "",
    sourceTable: "",
    sourceCodeDescription: "",
    sourceCode: "",
    associatedselectedOption: "",
    selectedOption: false,
    routingBank: _payloadData.routingNumber || "",
    accountType: _payloadData.accountType || "",
    accountNumber: _payloadData.accountNumber || "",
    accountName: _payloadData.accountName || "",
    bankName: _payloadData.bankName || ""
  });
  const [values, setValues] = React.useState(getValues({}));
  let errorMessagesArray = [];
  const [errorMessages, seterrorMessages] = useState([]);
  const [
    {
      showLockBoxNameError,
      showLockBoxNumberError,
      showLockBoxBeginDateError,
      showStatusrror,
      showContactTypeError,
      showContactBeginDateError,
      showSignificanceError,
      showContactFName,
      showContactMName,
      showContactLName,
      showLockBoxEndDateError,
      showEndDateError,
      showLockBoxBeginDateGreaterError,
      showLockBoxBeginDateSmallerError,
      showAddressEndDateError,
      mainAccountTypeEror,
      mainAccountNumberError,
      mainRoutingError,
      mainBeginDateError,
      showContactDateMatchError,
      isLockBoxEndDateValid,
      showContactEndDateMatchError,
      showLockBoxBeginDateMatchError,
      showLockBoxEndDateMatchError,
      showAddressBeginDateMatchError,
      showAddressEndDateMatchError,
      invalidLockBoxBeginDateError,
      invalidLockBoxEndDateError,
      invalidMainBeginDateError,
      invalidMainEndDateError,
      invalidAddressBeginDateError,
      invalidAddressEndDateError,
      invalidContactBeginDateError,
      invalidContactEndDateError,
      isLockBoxBeginDateValid,
      isMainBeginDateValid,
      isMainBeginDateSmaller,
      isMainEndDateValid,
      isAddressBeginDateValid,
      isContactBeginDateValid,
      invalidDateOfBirthError,
      invalidDateOfDeathError,
      showDeathDateError,
      showLockBoxDuplicateNumberError,
      mainAccountBeginDateInvalid,
      showAddressBeginDateGreaterError,
      isAddressEndDateValid,
      isContactEndDateValid,
      showContactBeginDateGreaterError,
      showAddressTypeError,
      showAdressBeginDateError,
      showAddressLine1Error,
      showSignificantTypeCodeError,
      showZipcodeError,
      overLapDateError,
      bankNameError,
      showZipCodeReqError,
      showStatusError,
      showStateError,
      showCityError,
      routingNumberError
    },
    setShowError
  ] = useState(false);
  const [
    {
      showAddressTypeErrorMsg,
      showAdressBeginDateErrorMsg,
      showAddressEndDateErrorMsg,
      showAddressLine1ErrorMsg,
      showSignificantTypeCodeErrorMsg,
      showZipcodeErrorMsg,
      showZipcodereqErrorMsg,
      showStateErrorMsg,
      showCityErrorMsg,
      showStatusErrorMsg
    },
    setAddressErrorMessage
  ] = useState("");
  const [showMsg, setShowMsg] = useState(true);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(
    false
  );
  const [showAdditionalDetails1, setShowAdditionalDetails1] = useState(
    false
  );
  const [disableNotes, setDisableNotes] = useState(true);
  const [dataElement, setDataElement] = useState({
    id: "",
    code: "",
    shortDescription: "",
    longDescription: "",
    constantText: "",
    associatedselectedOption: ""
  });
  const [lockBoxDetails, setlockBoxDetails] = useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: null,
    addedAuditTimeStamp: null,
    id: "",
    beginDate: "",
    endDate: "",
    lockBoxNumber: "",
    lockBoxName: ""
  });
  const [templockBoxDetails, settemplockBoxDetails] = useState({
    id: "",
    beginDate: "",
    endDate: "",
    lockBoxNumber: "",
    lockBoxName: ""
  });
  const [tempAddAddress, settempAddAddress] = useState({});
  const [addAddress, setAddAddress] = useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    addressUsageTypeCode: DEFAULT_DD_VALUE,
    addressSK: null,
    beginDate: "",
    endDate: "",
    addressUsageSigCode: DEFAULT_DD_VALUE,
    changeReasonCode: DEFAULT_DD_VALUE,
    statusCode: DEFAULT_DD_VALUE,
    addressRepCrossReferences: [],
    addressUsageSequenceNumber: 1,
    addressUsageSK: null,
    commonEntitySK: null,
    addressUsageVersionNumber: "0",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    statusDesc: null,
    addressTypeDesc: null,
    countyDesc: null,
    latitudeNumber: "",
    longitudeNumber: "",
    city: "",
    state: DEFAULT_DD_VALUE,
    zipCode5: "",
    zipCode4: "",
    town: "",
    country: DEFAULT_DD_VALUE,
    county: DEFAULT_DD_VALUE,
    uspsAddressVerifyCode: null,
    uspsAddressLine1: null,
    uspsAddressLine2: null,
    addressVersionNumber: 0
  });
  const [tempContactDetails, settempContactDetails] = useState({});
  const [contactDetails, setContactDetails] = useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: null,
    addedAuditTimeStamp: null,
    versionNo: 0,
    dbRecord: true,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    contactType: DEFAULT_DD_VALUE,
    addressType: null,
    contactSSN: "",
    contactDob: null,
    contactDod: null,
    contactStatusDesc: null,
    contactTypeDesc: null,
    contactTitle: "",
    significanceTypeCode: DEFAULT_DD_VALUE,
    status: null,
    contactAddrBeginDate: null,
    contactAddrEndDate: null,
    gender: DEFAULT_DD_VALUE,
    streetaddress: null,
    zipcode: null,
    dummyInd: null,
    addressUsageSequenceNumber: null,
    statusCode: DEFAULT_DD_VALUE,
    changeReason: null,
    beginDate: null,
    endDate: null,
    addressTypeRowIndex: null,
    latitudeNumber: null,
    longitudeNumber: null,
    uspsAddressLine1: null,
    uspsAddressLine2: null,
    uspsAddressVerifyCode: null,
    addressUsageSK: null,
    prefix: DEFAULT_DD_VALUE,
    lastName: "",
    firstName: "",
    middleName: "",
    suffix: DEFAULT_DD_VALUE,
    phone: null,
    phoneExtn: null,
    email: null,
    addressOne: null,
    addressTwo: null,
    addressThree: null,
    addressFour: null,
    county: null,
    country: null,
    town: null,
    addressVerification: null,
    uspsVerifiedCode: null,
    latitude: null,
    longitude: null,
    city: null,
    state: null,
    zipOne: null,
    zipTwo: null,
    representativeSK: null,
    eaddressSK: null,
    phoneSK: null,
    addressSK: null,
    countryCode: null,
    countyCode: null,
    addressBeginDateStr: null,
    addressEndDateStr: null,
    mailReturnedIndicator: false,
    addressVoidIndicator: false,
    addressPendingIndicator: false,
    phoneBeginDateStr: null,
    phoneEndDateStr: null,
    phoneVoidIndicator: false,
    phoneOutOfServiceIndicator: false,
    emailAddressBeginDateStr: null,
    emailAddressEndDateStr: null,
    emailAddressVoidIndicator: false,
    emailAddressBouncedIndicator: false,
    contactTypeRowIndex: null,
    commonEntityVersionNumber: 0,
    repCommonEntitySK: null,
    renderContactSection: false,
    renderAddContactSection: false,
    renderEditContactSection: false,
    renderDeleteContactSection: false,
    contactKey: null,
    contactRowIndex: null,
    renderAdditionalContactSection: false,
    renderMailingAddressSection: false,
    commonEntityCrossRefVersionNumber: 0,
    representativeVersionNumber: 0,
    addressUsageVersionNumber: 0,
    addressVersionNumber: 0,
    returnedMailVersionNumber: 0,
    phoneUsageVersionNumber: 0,
    phoneVersionNumber: 0,
    emailUsageVersionNumber: 0,
    emailVersionNumber: 0,
    renderPlusMailingAddressSection: false,
    renderPlusAdditionalContactSection: false,
    nameVrsnNo: 0,
    cmnEntityTypVrsnNo: 0,
    commonEntitySK: null,
    repCommonEntity: null,
    disableContactType: false
  });
  const [lockBoxBeginDateChange, setLockBoxBeginDateChange] = useState(
    false
  );
  const [lockBoxEndDateChange, setLockBoxEndDateChange] = useState(false);
  const [
    retainSelectedLockBoxBeginDate,
    setRetainedLockBoxBeginDate
  ] = useState(null);
  const [
    retainSelectedLockBoxEndDate,
    setRetainedLockBoxEndDate
  ] = useState(null);
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const [considerUSPS, setConsiderUSPS] = useState("N");
  const handleChangeUSPS = () => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setConsiderUSPS(event.target.value);
    setShowError(false);
  };
  const [uspsAddres, setUspsAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode5: ""
  });
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    onReset();
    dropDownDispatch(dropdownCriteria);
    dropDownTypeDispatch(dropdownTypeCriteria);
    validateAddressResult = null;
    onDropdowns([
      Dropdowns.BANK_ACCT_TYPE,
      Dropdowns.EFT_STAT_CODE,
      Dropdowns.CONTACT_TYPE,
      Dropdowns.STATE_CODE,
      Dropdowns.ADDRESS_USAGE_TYPE,
      Dropdowns.ADR_USG_STAT_CD
    ]);
  }, []);

  useEffect(() => {
    if (validateAddressResult) {
      if (validateAddressResult.systemFailue) {
        setAddressError([ErrorConstants.SYSTEM_ERROR]);
        const errorArray = JSON.parse(JSON.stringify(errorMessages));
        errorArray.push(ErrorConstants.SYSTEM_ERROR);
        seterrorMessages(errorArray);
        setUspsAddress({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode5: ""
        });
        setVerified(false);
        window.scrollTo({
          top: 100,
          behavior: "smooth"
        });
      }
      if (
        validateAddressResult.AddressValidateResponse &&
        !validateAddressResult.systemFailue
      ) {
        if (validateAddressResult.AddressValidateResponse.Address) {
          if (validateAddressResult.AddressValidateResponse.Address.Error) {
            setAddressError([
              validateAddressResult.AddressValidateResponse.Address.Error
                .Description
            ]);
            const errorArray = JSON.parse(JSON.stringify(errorMessages));
            errorArray.push(
              validateAddressResult.AddressValidateResponse.Address.Error
                .Description
            );
            seterrorMessages(errorArray);
            setUspsAddress({
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              zipCode5: ""
            });
            setVerified(false);
            window.scrollTo({
              top: 100,
              behavior: "smooth"
            });
          } else {
            setUspsAddress({
              addressLine1:
                validateAddressResult.AddressValidateResponse.Address.Address2,
              addressLine2:
                validateAddressResult.AddressValidateResponse.Address.Address1,
              city: validateAddressResult.AddressValidateResponse.Address.City,
              state:
                validateAddressResult.AddressValidateResponse.Address.State,
              zipCode5:
                validateAddressResult.AddressValidateResponse.Address.Zip5
            });
            setAddressError([]);
            seterrorMessages([]);
            setVerified(true);
            setShowAdditionalDetails1(true);
          }
        } else {
          setAddressError([ErrorConstants.INVALID_ADDRESS]);
          const errorArray = JSON.parse(JSON.stringify(errorMessages));
          errorArray.push(ErrorConstants.INVALID_ADDRESS);
          seterrorMessages(errorArray);
          setUspsAddress({
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode5: ""
          });
          setVerified(false);
          window.scrollTo({
            top: 100,
            behavior: "smooth"
          });
        }
      } else {
        setAddressError([ErrorConstants.SYSTEM_ERROR]);
        const errorArray = JSON.parse(JSON.stringify(errorMessages));
        errorArray.push(ErrorConstants.SYSTEM_ERROR);
        seterrorMessages(errorArray);
        setUspsAddress({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode5: ""
        });
        setVerified(false);
        window.scrollTo({
          top: 100,
          behavior: "smooth"
        });
      }
    }
  }, [validateAddressResult]);
  useEffect(() => {
    if (redirect === 1) {
      if (payloadData !== "") {
        setLockBoxTableData(payloadData[0].lockBoxList);
        setAddressTableData(payloadData[0].bankAddressVOList);
        setaddContactsData(payloadData[0].contactList);
        setSelectedDate(payloadData[0].beginDate);
        if (new Date() > new Date(payloadData[0].endDate)) {
          setDisableEndDate(true);
        }
        setSelectedEndDate(payloadData[0].endDate);
        setSearchData(payloadData[0]);
        setChangedEndDate(false);
        setValues({
          id: "",
          code: "",
          shortDescription: searchData.functionalArea,
          longDescription: "",
          constantText: "",
          sourceTable: "",
          sourceCodeDescription: "",
          sourceCode: "",
          associatedselectedOption: "",
          selectedOption: false,
          routingBank: payloadData[0].routingNumber,
          accountType: payloadData[0].accountType,
          accountNumber: payloadData[0].accountNumber,
          accountName: payloadData[0].accountName,
          bankName: payloadData[0].bankName
        });
        props.history.push({
          pathname: "/BankAccountLockBoxEdit",
          state: { payloadData: payloadData[0], values: ValuesFromBankAccount }
        });
      }
      if (payloadData === "") {
        seterrorMessages([ErrorConstants.SYSTEM_ERROR]);
      }
    }
  }, [payloadData]);
  const handleSaveAddressFinal = () => {
    setAddressError([]);
    errorMessagesArray = [];
    seterrorMessages([]);
    var invalidAddressBeginDateError;
    var showAddressEndDateMatchError;
    var isAddressEndDateValid;
    var showAddressBeginDateMatchError;
    var addressEndDateLesserError;
    var showAddressBeginDateGreaterError;
    var invalidAddressEndDateError;
    var isAddressBeginDateValid = false;
    var showAddressTypeError;
    var showAdressBeginDateError;
    var showAddressEndDateError;
    var showStatusError = false;
    var showCityError = false;
    var showZipCodeReqError = false;
    var showStateError = false;
    var showAddressLine1Error = false;
    var showSignificantTypeCodeError = false;
    var showZipcodeError = false;
    var showZipcodeErrorMsg;
    var showAddressTypeErrorMsg = false;
    var showAdressBeginDateErrorMsg = false;
    var showAddressEndDateErrorMsg = false;
    var showAddressLine1ErrorMsg;
    var showSignificantTypeCodeErrorMsg;
    var showZipcodereqErrorMsg = "";
    var showStatusErrorMsg = "";
    var showCityErrorMsg = "";
    var showStateErrorMsg = "";
    if (addAddress.statusCode === DEFAULT_DD_VALUE || statusCodeVal === undefined) {
      showStatusError = true;
      showStatusErrorMsg = ErrorConstants.STATUS_REQUIRED;
      errorMessagesArray.push(showStatusErrorMsg);
    }
    if (addAddress.state === DEFAULT_DD_VALUE || stateCode === undefined) {
      showStateError = true;
      showStateErrorMsg = ErrorConstants.STATE_REQUIRED;
      errorMessagesArray.push(showStateErrorMsg);
    }
    if (addAddress.city === "") {
      showCityError = true;
      showCityErrorMsg = ErrorConstants.CITY_REQUIRED;
      errorMessagesArray.push(showCityErrorMsg);
    }
    if (addAddress.zipCode5 === "") {
      showZipCodeReqError = true;
      showZipcodereqErrorMsg = ErrorConstants.ZIP_CODE_REQUIRED;
      errorMessagesArray.push(showZipcodereqErrorMsg);
    }
    if (addAddress.addressUsageTypeCode === DEFAULT_DD_VALUE) {
      showAddressTypeError = true;
      showAddressTypeErrorMsg = ErrorConstants.ADDRESS_TYPE_REQUIRED;
      errorMessagesArray.push(ErrorConstants.ADDRESS_TYPE_REQUIRED);
    }
    if (
      !addAddress.addressLine1 ||
      (addAddress.addressLine1 && !addAddress.addressLine1.trim())
    ) {
      showAddressLine1Error = true;
      showAddressLine1ErrorMsg = ErrorConstants.ADDRESS_LINE_1_REQUIRED;
      errorMessagesArray.push(ErrorConstants.ADDRESS_LINE_1_REQUIRED);
    }
    if (addAddress.addressUsageSigCode === DEFAULT_DD_VALUE || signCode === undefined) {
      showSignificantTypeCodeError = true;
      errorMessagesArray.push(ErrorConstants.SIGNIFICANT_TYPE_CODE_REQUIRED);
      showSignificantTypeCodeErrorMsg =
        ErrorConstants.SIGNIFICANT_TYPE_CODE_REQUIRED;
    }
    if (!selectedAddAddressBeginDate) {
      showAdressBeginDateError = true;
      errorMessagesArray.push(ErrorConstants.BEGINDATE_REQUIRED);
      showAdressBeginDateErrorMsg = ErrorConstants.BEGINDATE_REQUIRED;
    }
    if (selectedAddAddressBeginDate) {
      addAddress.beginDate =
        new Date(selectedAddAddressBeginDate).getMonth() +
        1 +
        "/" +
        new Date(selectedAddAddressBeginDate).getDate() +
        "/" +
        new Date(selectedAddAddressBeginDate).getFullYear();
    }
    if (
      addAddress.beginDate &&
      addAddress.beginDate.toString() === "NaN/NaN/NaN"
    ) {
      addAddress.beginDate = "";
    }
    if (selectedAddAddressEndDate) {
      addAddress.endDate =
        new Date(selectedAddAddressEndDate).getMonth() +
        1 +
        "/" +
        new Date(selectedAddAddressEndDate).getDate() +
        "/" +
        new Date(selectedAddAddressEndDate).getFullYear();
    }
    // Begin Date Validation
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() === "Invalid Date"
    ) {
      invalidAddressBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedAddAddressBeginDate
      );
      if (isDateValid) {
        isAddressBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedAddAddressBeginDate)
        ) <
          new Date(
            DateUtilities.getDateInMMDDYYYYFormat(new Date(selectedDate))
          )
      ) {
        showAddressBeginDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // End Date Validation
    var tempSelectedAddressEndDate = new Date(selectedAddAddressEndDate);
    if (!selectedAddAddressEndDate) {
      addAddress.endDate = "12/31/9999";
      setSelectedAddAddressEndDate(new Date("12/31/9999"));
      tempSelectedAddressEndDate = new Date("12/31/9999");
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() === "Invalid Date"
    ) {
      invalidAddressEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() !== "Invalid Date"
    ) {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(
        tempSelectedAddressEndDate
      );
      if (isDateValid) {
        isAddressEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedAddressEndDate
          )
        ) >
          new Date(
            DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
          )
      ) {
        showAddressEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // Begin Date + End Date Comparison
    if (
      selectedAddAddressBeginDate &&
      tempSelectedAddressEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedAddAddressBeginDate
        )
      ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedAddressEndDate
          )
        )
    ) {
      showAddressBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR);
    }

    if (verified === false && considerUSPS === "Y") {
      setAddressError([ErrorConstants.VERIFIED_ADDRESS]);
      errorMessagesArray.push(ErrorConstants.VERIFIED_ADDRESS);
    } else {
      setAddressError([]);
    }
    const regex = /^^[a-zA-Z0-9 ]*$/;
    if (
      addAddress &&
      addAddress.zipCode5 &&
      addAddress.zipCode5.toString().trim() &&
      !regex.test(addAddress.zipCode5)
    ) {
      showZipcodeError = true;
      errorMessagesArray.push(ErrorConstants.INVALID_ZIPCODE);
      showZipcodeErrorMsg = ErrorConstants.INVALID_ZIPCODE;
    }
    if (errorMessagesArray.length === 0 && (verified || considerUSPS === "N")) {
      if (selectedRow === null) {
        addAddress.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
          addAddress.beginDate
        );
        addAddress.endDate = DateUtilities.getDateInMMDDYYYYFormat(
          addAddress.endDate
        );
        addAddress.auditUserID = logInUserID;
        addAddress.auditTimeStamp = DateUtilities.getUTCTimeStamp();
        addAddress.addedAuditUserID = logInUserID;
        addAddress.addedAuditTimeStamp = DateUtilities.getUTCTimeStamp();
        addAddress.id = DateUtilities.generateUUID();
        const tempAddAddress = JSON.parse(JSON.stringify(addAddress));
        if (considerUSPS === "Y") {
          tempAddAddress.addressLine1 = uspsAddres.addressLine1;
          tempAddAddress.addressLine2 = uspsAddres.addressLine2;
          tempAddAddress.city = uspsAddres.city;
          tempAddAddress.state = uspsAddres.state;
          tempAddAddress.zipCode5 = uspsAddres.zipCode5;
        }
        if (considerUSPS === "Y") {
          setAddressTableData(addressTableData => [
            ...addressTableData,
            tempAddAddress
          ]);
        } else {
          setAddressTableData(addressTableData => [
            ...addressTableData,
            addAddress
          ]);
        }
      } else {
        addressTableData.map(element => {
          if (element.id === selectedRow || element.uuid === selectedRow) {
            if (selectedAddAddressBeginDate) {
              const tempDate = new Date(selectedAddAddressBeginDate);
              addAddress.beginDate =
                tempDate.getMonth() +
                1 +
                "/" +
                tempDate.getDate() +
                "/" +
                tempDate.getFullYear();
              if (
                addAddress.beginDate &&
                addAddress.beginDate.toString() === "NaN/NaN/NaN"
              ) {
                addAddress.beginDate = "";
              }
            }
            if (selectedAddAddressEndDate) {
              const tempDate = new Date(selectedAddAddressEndDate);
              addAddress.endDate =
                tempDate.getMonth() +
                1 +
                "/" +
                tempDate.getDate() +
                "/" +
                tempDate.getFullYear();
            } else {
              addAddress.endDate = new Date("12/31/9999");
            }
            element.auditUserID = logInUserID;
            element.auditTimeStamp = DateUtilities.getUTCTimeStamp();
            element.addedAuditUserID = element.addedAuditUserID
              ? element.addedAuditUserID
              : logInUserID;
            element.addedAuditTimeStamp = element.addedAuditTimeStamp
              ? element.addedAuditTimeStamp
              : DateUtilities.getUTCTimeStamp();
            element.addressUsageTypeCode = addAddress.addressUsageTypeCode;
            element.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
              addAddress.beginDate
            );
            element.endDate = DateUtilities.getDateInMMDDYYYYFormat(
              addAddress.endDate
            );
            element.addressUsageSigCode = addAddress.addressUsageSigCode;
            element.changeReasonCode = addAddress.changeReasonCode;
            element.statusCode = addAddress.statusCode;
            if (considerUSPS === "Y") {
              element.addressLine1 = uspsAddres.addressLine1;
              element.addressLine2 = uspsAddres.addressLine2;
              element.city = uspsAddres.city;
              element.state = uspsAddres.state;
              element.zipCode5 = uspsAddres.zipCode5;
            } else {
              element.addressLine1 = addAddress.addressLine1;
              element.addressLine2 = addAddress.addressLine2;
              element.city = addAddress.city;
              element.state = addAddress.state;
              element.zipCode5 = addAddress.zipCode5;
            }
            element.addressLine3 = addAddress.addressLine3;
            element.addressLine4 = addAddress.addressLine4;
            element.uspsAddress1 = addAddress.uspsAddress1;
            element.uspsAddressVerifyCode = addAddress.uspsAddressVerifyCode;
            element.latitudeNumber = addAddress.latitudeNumber;
            element.longitudeNumber = addAddress.longitudeNumber;
            element.county = addAddress.county;
            element.country = addAddress.country;
            element.town = addAddress.town;
            element = removeLeadingTrailingSpaces(element);
          }
        });
        filterAddressData(addressTableData);
      }
      setOpen(false);
      setAddAddress({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressUsageTypeCode: DEFAULT_DD_VALUE,
        addressSK: null,
        beginDate: "",
        endDate: "",
        addressUsageSigCode: DEFAULT_DD_VALUE,
        changeReasonCode: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        addressRepCrossReferences: [],
        addressUsageSequenceNumber: 1,
        addressUsageSK: null,
        commonEntitySK: null,
        addressUsageVersionNumber: "0",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        statusDesc: null,
        addressTypeDesc: null,
        countyDesc: null,
        latitudeNumber: "",
        longitudeNumber: "",
        city: "",
        state: DEFAULT_DD_VALUE,
        zipCode5: "",
        zipCode4: "",
        town: "",
        country: DEFAULT_DD_VALUE,
        county: DEFAULT_DD_VALUE,
        uspsAddressVerifyCode: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        addressVersionNumber: 0
      });
      setConsiderUSPS("Y");
      setSelectedAddAddressEndDate(null);
      setSelectedRow(null);
      errorMessagesArray = [];
      setShowAdditionalDetails1(false);
      setUspsAddress({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode5: ""
      });
      setVerified(false);
    }
    setShowError({
      invalidAddressBeginDateError: invalidAddressBeginDateError,
      isAddressEndDateValid: isAddressEndDateValid,
      invalidAddressEndDateError: invalidAddressEndDateError,
      isAddressBeginDateValid: isAddressBeginDateValid,
      showAddressEndDateMatchError: showAddressEndDateMatchError,
      showAddressBeginDateMatchError: showAddressBeginDateMatchError,
      addressEndDateLesserError: addressEndDateLesserError,
      showAddressBeginDateGreaterError: showAddressBeginDateGreaterError,
      showAddressTypeError: showAddressTypeError,
      showAddressLine1Error: showAddressLine1Error,
      showSignificantTypeCodeError: showSignificantTypeCodeError,
      showAdressBeginDateError: showAdressBeginDateError,
      showAddressEndDateError: showAddressEndDateError,
      showZipcodeError: showZipcodeError,
      showZipCodeReqError: showZipCodeReqError,
      showStatusError: showStatusError,
      showCityError: showCityError,
      showStateError: showStateError
    });
    setAddressErrorMessage({
      showAddressTypeErrorMsg: showAddressTypeErrorMsg,
      showAddressLine1ErrorMsg: showAddressLine1ErrorMsg,
      showSignificantTypeCodeErrorMsg: showSignificantTypeCodeErrorMsg,
      showAdressBeginDateErrorMsg: showAdressBeginDateErrorMsg,
      showAddressEndDateErrorMsg: showAddressEndDateErrorMsg,
      showZipcodeErrorMsg: showZipcodeErrorMsg,
      showZipcodereqErrorMsg: showZipcodereqErrorMsg,
      showCityErrorMsg: showCityErrorMsg,
      showStatusErrorMsg: showStatusErrorMsg,
      showStateErrorMsg: showStateErrorMsg
    });
    seterrorMessages(errorMessagesArray);
  };

  useEffect(() => {
    if (saveAPIResult && !saveAPIResult.systemFailure) {
      if (saveAPIResult.respcode === "01") {
        const updateAccountValues = {
          lob: [AppConstants.DEFAULT_LOB_VALUE],
          bankRoutingNumber: values.routingBank,
          bankRoutingNumberStartsWith: false,
          bankName: null,
          bankNameStartsWithOrContains: null,
          bankAccountNumber: values.accountNumber,
          accountNumberStartsWith: false,
          accountName: null,
          accountNameStartsWithOrContains: null,
          beginDate: moment(selectedDate).format("MM/DD/YYYY"),
          endDate: moment(selectedEndDate).format("MM/DD/YYYY"),
          lockBoxNumber: lockBoxTableData[0].lockBoxNumber,
          lockBoxNumberStartsWith: false,
          lockBoxName: lockBoxTableData[0].lockBoxName,
          lockBoxNameStartsWithOrContains: null
        };
        setRedirect(1);
        onUpdateSuccess(updateAccountValues);
        setSuccessMessages([ErrorConstants.UPDATE_SUCCESS_MESSAGE]);
        setFocusCheck(false);
      } else if (
        saveAPIResult.respcode === "02" &&
        saveAPIResult.errorMessages !== null
      ) {
        const duplicateErrorMessage = saveAPIResult.errorMessages;
        duplicateErrorMessage.map(errMsg => {
          errorMessagesArray.push(errMsg);
        });
        seterrorMessages(errorMessagesArray);
      }
    }
    if (saveAPIResult && saveAPIResult.systemFailure) {
      errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [saveAPIResult]);

  useEffect(() => {
    if (allowNavigation) {
      setSuccessMessages([]);
    }
  }, [allowNavigation]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["General#1041"]) {
        setsignificanceTypeList(dropdown.listObj["General#1041"]);
      }
      if (dropdown.listObj["General#1020"]) {
        setchangeReasonList(dropdown.listObj["General#1020"]);
      }
      if (dropdown.listObj["General#1000"]) {
        setaddStatusList(dropdown.listObj["General#1000"]);
      }
      if (dropdown.listObj["General#1019"]) {
        setaddStateList(dropdown.listObj["General#1019"]);
      }
      if (dropdown.listObj["General#1007"]) {
        setcountyList(dropdown.listObj["General#1007"]);
      }
      if (dropdown.listObj["General#1006"]) {
        const cntyList = JSON.parse(
          JSON.stringify(
            dropdown.listObj["General#1006"].sort((a, b) =>
              a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1
            )
          )
        );
        setaddCountryList(cntyList);
      }
      if (dropdown.listObj["General#1076"]) {
        setcontactStatusList(dropdown.listObj["General#1076"]);
      }
      if (dropdown.listObj["General#1018"]) {
        setcontactTypeList(dropdown.listObj["General#1018"]);
      }
      if (dropdown.listObj["General#1004"]) {
        setcontactSignificanceList(dropdown.listObj["General#1004"]);
      }
      if (dropdown.listObj["General#1011"]) {
        setcontactPrefixList(dropdown.listObj["General#1011"]);
      }
      if (dropdown.listObj["General#1053"]) {
        setcontactSuffixList(dropdown.listObj["General#1053"]);
      }
      if (dropdown.listObj["General#1010"]) {
        setgenderList(dropdown.listObj["General#1010"]);
      }
      if (dropdown.listObj["General#6001"]) {
        setaddressTypeList(dropdown.listObj["General#6001"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (typeDropdown && typeDropdown.listObj) {
      if (typeDropdown.listObj["Financial#F_BANK_ACCT_TY_CD"]) {
        setaccountTypeList(typeDropdown.listObj["Financial#F_BANK_ACCT_TY_CD"]);
      }
    }
  }, [typeDropdown]);

  // DropdownFilter starts

  const filterAddressData = addressTableData => {
    if (addressTableData && addressTableData.length > 0) {
      addressTableData.map((data) => {
        const addressUsageType =
              addDropdowns &&
              addDropdowns['G1#G_ADR_USG_TY_CD'] &&
              addDropdowns['G1#G_ADR_USG_TY_CD'].filter(
              value => value.code === data.addressUsageTypeCode
        );
        data.addressUsageTypeCodeDesc =
          addressUsageType && addressUsageType.length > 0 ? addressUsageType[0].description
          : data.addressUsageTypeCode ? data.addressUsageTypeCode : '';
        const statusCode =
            addDropdowns &&
            addDropdowns['G1#G_ADR_USG_STAT_CD'] &&
            addDropdowns['G1#G_ADR_USG_STAT_CD'].filter(
              value => value.code === data.statusCode
            );
        data.statusCodeDesc =
            statusCode && statusCode.length > 0
              ? statusCode[0].description
              : data.statusCode ? data.statusCode : '';
        const stateCode =
            addDropdowns &&
            addDropdowns['G1#G_US_STATE_CD'] &&
            addDropdowns['G1#G_US_STATE_CD'].filter(
              value => value.code === data.state
            );
            data.stateCodeDesc =
            stateCode && stateCode.length > 0
              ? stateCode[0].description
              : data.state ? data.state : '';
      });
    }
    setFilteredAddressData(addressTableData);
  };
  const filterContactData = addContactsData => {
    if (addContactsData && addContactsData.length > 0) {
      addContactsData.map((data) => {
        // data.statusCodeDesc = dropDownFilter("contactStatus", data.statusCode);
        const statusCode =
            addDropdowns &&
            addDropdowns['F1#F_EFT_STAT_CD'] &&
            addDropdowns['F1#F_EFT_STAT_CD'].filter(
              value => value.code === data.statusCode
            );
            data.statusCodeDesc =
          statusCode && statusCode.length > 0
              ? statusCode[0].description
              : data.statusCode ? data.statusCode : '';
        // data.contactTypeDesc = dropDownFilter("contactType", data.contactType);
        const contactType =
            addDropdowns &&
            addDropdowns['G1#G_REP_XREF_TY_CD'] &&
            addDropdowns['G1#G_REP_XREF_TY_CD'].filter(
              value => value.code === data.contactType
            );
            data.contactTypeDesc =
            contactType && contactType.length > 0
              ? contactType[0].description
              : data.contactType ? data.contactType : '';
      });
    }
    setFilteredContactData(addContactsData);
  };
  // DropdownFilter ends

  useEffect(() => {
    if (_payload_combine) {
      !lockBoxTableData && setLockBoxTableData(_payload_combine.lockBoxList);
      !addressTableData &&
        setAddressTableData(_payload_combine.bankAddressVOList);
      !addContactsData && setaddContactsData(_payload_combine.contactList);
      !notesTableData &&
        setNotesTableData(
          _payload_combine.noteSetVO
            ? _payload_combine.noteSetVO.notesList
            : notesTableData
        );
      !searchData && setSearchData(_payload_combine);
      !values.bankName && setValues(getValues(_payload_combine));
      !selectedDate && setSelectedDate(_payload_combine.beginDate);
      !selectedSearchDate && setSelectedSearchDate(_payload_combine.beginDate);
      !selectedEndDate && setSelectedEndDate(_payload_combine.endDate);
      if (!selectedEndDate && new Date() > new Date(_payload_combine.endDate)) {
        setDisableEndDate(true);
      }
    }
  }, [props.payloadData, props.location.state.payloadData, _payload_combine]);

  useEffect(() => {
    if (addressTableData) {
      addressTableData.map(var1 => {
        if (!var1.uuid) {
          var1.uuid = DateUtilities.generateUUID();
        }
      });
      filterAddressData(addressTableData);
    }
  }, [addressTableData, selectedRow, addStatusList, addressTypeList, addDropdowns]);

  useEffect(() => {
    if (addContactsData) {
      filterContactData(addContactsData);
    }
  }, [addContactsData, selectedRow, contactStatusList, contactTypeList, addDropdowns]);

  const handleDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedDate(date);
  };

  let sampleDatalockbox = lockBoxTableData;
  let sampleDataAddress = addressTableData;
  let sampleDataContacts = addContactsData;
  function rowDeleteClickAddress () {
    for (let i = 0; i < rowAddressData.rowAddressData.length; i++) {
      sampleDataAddress = sampleDataAddress.filter(
        addressTable => addressTable.id !== rowAddressData.rowAddressData[i]
      );
    }
    setAddressTableData(sampleDataAddress);
    setSelectedRow(null);
  }
  function rowDeleteClickContact () {
    for (let i = 0; i < rowContactData.rowContactData.length; i++) {
      sampleDataContacts = sampleDataContacts.filter(
        ContactTable => ContactTable.id !== rowContactData.rowContactData[i]
      );
    }
    setaddContactsData(sampleDataContacts);
    setSelectedRow(null);
  }
  function rowDeleteClick () {
    for (let i = 0; i < rowLockBoxData.rowLockBoxData.length; i++) {
      sampleDatalockbox = sampleDatalockbox.filter(
        lockBoxTable => lockBoxTable.id !== rowLockBoxData.rowLockBoxData[i]
      );
    }
    setLockBoxTableData(sampleDatalockbox);
    setSelectedRow(null);
  }
  const rowDeleteLockBox = data => {
    setrowLockBoxData({ ...rowLockBoxData, rowLockBoxData: data });
  };
  const rowDeleteContact = data => {
    setrowContactData({ ...rowContactData, rowContactData: data });
  };
  const rowDeleteAddress = data => {
    setrowAddressData({ ...rowAddressData, rowAddressData: data });
  };

  useEffect(() => {
    setSelectedRow(null);
  }, [lockBoxTableData]);
  useEffect(() => {
    setSelectedRow(null);
  }, [addressTableData]);
  useEffect(() => {
    setSelectedRow(null);
  }, [addContactsData]);
  const [changedEndDate, setChangedEndDate] = useState(false);
  const handleEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setChangedEndDate(true);
    setSelectedEndDate(date);
  };
  const handleLockBoxBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedLockBoxBeginDate(date);
    setLockBoxBeginDateChange(true);
  };
  const handleLockBoxEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedLockBoxEndDate(date);
    setLockBoxEndDateChange(true);
  };

  const handleAddAddressBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedAddAddressBeginDate(date);
  };

  const handleAddAddressEndDateChange = date => {
    setAllowNavigation(true);
    setFocusCheck(true);
    setSelectedAddAddressEndDate(date);
  };

  const handleContactBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactBeginDate(date);
  };

  const handleContactEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactEndDate(date);
  };

  const handleContactDOB = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactDOB(date);
  };

  const handleContactDOD = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactDOD(date);
  };

  const handleLockBoxTableAdd = value => () => {
    setAuditTableData([]);
    seterrorMessages([]);
    setShowError(false);
    setAuditLogRow(value);
    setIsEditLockbox(true);
    setSuccessMessages([]);
    setOpenLockBox(true);
    setCurrentLockbox(value);
    setLockBoxBeginDateChange(false);
    setLockBoxEndDateChange(false);
    if (value.id || value.lockBoxKey) {
      setSelectedRow(value.id ? value.id : value.lockBoxKey);
    }
    setRetainedLockBoxBeginDate(value.lockBoxBeginDate);
    setRetainedLockBoxEndDate(value.lockBoxEndDate);

    setSelectedLockBoxBeginDate(
      new Date(
        value.lockBoxBeginDate ? value.lockBoxBeginDate : value.beginDate
      )
    );
    setSelectedLockBoxEndDate(
      new Date(value.lockBoxEndDate ? value.lockBoxEndDate : value.endDate)
    );
    setlockBoxDetails({
      beginDate: value.lockBoxBeginDate
        ? value.lockBoxBeginDate
        : value.beginDate,
      endDate: value.lockBoxEndDate ? value.lockBoxEndDate : value.endDate,
      lockBoxNumber: value.lockBoxNumber,
      lockBoxName: value.lockBoxName,
      id: value.id
    });
    settemplockBoxDetails({
      id: value.id,
      beginDate: value.lockBoxBeginDate
        ? value.lockBoxBeginDate
        : value.beginDate,
      endDate: value.lockBoxEndDate ? value.lockBoxEndDate : value.endDate,
      lockBoxNumber: value.lockBoxNumber,
      lockBoxName: value.lockBoxName
    });
    if (openLockBox && document.getElementById("lockbox_form_pop_up")) {
      document.getElementById("lockbox_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleContactTableAdd = value => () => {
    setAuditContactsTableData([]);
    setShowError(false);
    seterrorMessages([]);
    setAuditLogContactsRow(value);
    seterrorMessages([]);
    setSuccessMessages([]);
    setOpenContact(true);
    if (value.id || value.contactKey) {
      setSelectedRow(value.id ? value.id : value.contactKey);
    }
    setSelectedContactBeginDate(new Date(value.contactAddrBeginDate));
    setSelectedContactEndDate(new Date(value.contactAddrEndDate));
    setSelectedContactDOB(value.contactDob ? new Date(value.contactDob) : null);
    setSelectedContactDOD(value.contactDod ? new Date(value.contactDod) : null);
    setContactDetails({
      auditUserID: null,
      auditTimeStamp: null,
      addedAuditUserID: null,
      addedAuditTimeStamp: null,
      versionNo: 0,
      dbRecord: true,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      contactType: value.contactType ? value.contactType : DEFAULT_DD_VALUE,
      addressType: null,
      contactSSN: value.contactSSN ? value.contactSSN : "",
      contactDob: value.contactDob ? new Date(value.contactDob) : null,
      contactDod: value.contactDod ? new Date(value.contactDod) : null,
      contactStatusDesc: null,
      contactTypeDesc: null,
      contactTitle: value.contactTitle ? value.contactTitle : "",
      significanceTypeCode: value.significanceTypeCode
        ? value.significanceTypeCode
        : DEFAULT_DD_VALUE,
      status: null,
      contactAddrBeginDate: value.contactAddrBeginDate
        ? new Date(value.contactAddrBeginDate)
        : null,
      contactAddrEndDate: value.contactAddrEndDate
        ? new Date(value.contactAddrEndDate)
        : null,
      gender: value.gender ? value.gender : DEFAULT_DD_VALUE,
      streetaddress: null,
      zipcode: null,
      dummyInd: null,
      addressUsageSequenceNumber: null,
      statusCode: value.statusCode,
      changeReason: null,
      beginDate: value.beginDate ? new Date(value.beginDate) : null,
      endDate: value.endDate ? new Date(value.endDate) : null,
      addressTypeRowIndex: null,
      latitudeNumber: null,
      longitudeNumber: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      uspsAddressVerifyCode: null,
      addressUsageSK: null,
      prefix: value.prefix ? value.prefix : DEFAULT_DD_VALUE,
      lastName: value.lastName ? value.lastName : "",
      firstName: value.firstName ? value.firstName : "",
      middleName: value.middleName ? value.middleName : "",
      suffix: value.suffix ? value.suffix : DEFAULT_DD_VALUE,
      phone: null,
      phoneExtn: null,
      email: null,
      addressOne: null,
      addressTwo: null,
      addressThree: null,
      addressFour: null,
      county: null,
      country: null,
      town: null,
      addressVerification: null,
      uspsVerifiedCode: null,
      latitude: null,
      longitude: null,
      city: null,
      state: null,
      zipOne: null,
      zipTwo: null,
      representativeSK: null,
      eaddressSK: null,
      phoneSK: null,
      addressSK: null,
      countryCode: null,
      countyCode: null,
      addressBeginDateStr: null,
      addressEndDateStr: null,
      mailReturnedIndicator: false,
      addressVoidIndicator: false,
      addressPendingIndicator: false,
      phoneBeginDateStr: null,
      phoneEndDateStr: null,
      phoneVoidIndicator: false,
      phoneOutOfServiceIndicator: false,
      emailAddressBeginDateStr: null,
      emailAddressEndDateStr: null,
      emailAddressVoidIndicator: false,
      emailAddressBouncedIndicator: false,
      contactTypeRowIndex: null,
      commonEntityVersionNumber: 0,
      repCommonEntitySK: value.repCommonEntitySK,
      renderContactSection: false,
      renderAddContactSection: false,
      renderEditContactSection: false,
      renderDeleteContactSection: false,
      contactKey: null,
      contactRowIndex: null,
      renderAdditionalContactSection: false,
      renderMailingAddressSection: false,
      commonEntityCrossRefVersionNumber: 0,
      representativeVersionNumber: 0,
      addressUsageVersionNumber: 0,
      addressVersionNumber: 0,
      returnedMailVersionNumber: 0,
      phoneUsageVersionNumber: 0,
      phoneVersionNumber: 0,
      emailUsageVersionNumber: 0,
      emailVersionNumber: 0,
      renderPlusMailingAddressSection: false,
      renderPlusAdditionalContactSection: false,
      nameVrsnNo: 0,
      cmnEntityTypVrsnNo: 0,
      commonEntitySK: null,
      repCommonEntity: null,
      disableContactType: false,
      id: value.id
    });
    settempContactDetails({
      auditUserID: null,
      auditTimeStamp: null,
      addedAuditUserID: null,
      addedAuditTimeStamp: null,
      versionNo: 0,
      dbRecord: true,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      contactType: value.contactType ? value.contactType : DEFAULT_DD_VALUE,
      addressType: null,
      contactSSN: value.contactSSN ? value.contactSSN : "",
      contactDob: value.contactDob ? value.contactDob : null,
      contactDod: value.contactDod ? value.contactDod : null,
      contactStatusDesc: null,
      contactTypeDesc: null,
      contactTitle: value.contactTitle ? value.contactTitle : "",
      significanceTypeCode: value.significanceTypeCode
        ? value.significanceTypeCode
        : DEFAULT_DD_VALUE,
      status: null,
      contactAddrBeginDate: value.contactAddrBeginDate
        ? new Date(value.contactAddrBeginDate)
        : null,
      contactAddrEndDate: value.contactAddrEndDate
        ? new Date(value.contactAddrEndDate)
        : null,
      gender: value.gender ? value.gender : DEFAULT_DD_VALUE,
      streetaddress: null,
      zipcode: null,
      dummyInd: null,
      addressUsageSequenceNumber: null,
      statusCode: value.statusCode,
      changeReason: null,
      beginDate: value.beginDate ? new Date(value.beginDate) : null,
      endDate: value.endDate ? new Date(value.endDate) : null,
      addressTypeRowIndex: null,
      latitudeNumber: null,
      longitudeNumber: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      uspsAddressVerifyCode: null,
      addressUsageSK: null,
      prefix: value.prefix ? value.prefix : DEFAULT_DD_VALUE,
      lastName: value.lastName ? value.lastName : "",
      firstName: value.firstName ? value.firstName : "",
      middleName: value.middleName ? value.middleName : "",
      suffix: value.suffix ? value.suffix : DEFAULT_DD_VALUE,
      phone: null,
      phoneExtn: null,
      email: null,
      addressOne: null,
      addressTwo: null,
      addressThree: null,
      addressFour: null,
      county: null,
      country: null,
      town: null,
      addressVerification: null,
      uspsVerifiedCode: null,
      latitude: null,
      longitude: null,
      city: null,
      state: null,
      zipOne: null,
      zipTwo: null,
      representativeSK: null,
      eaddressSK: null,
      phoneSK: null,
      addressSK: null,
      countryCode: null,
      countyCode: null,
      addressBeginDateStr: null,
      addressEndDateStr: null,
      mailReturnedIndicator: false,
      addressVoidIndicator: false,
      addressPendingIndicator: false,
      phoneBeginDateStr: null,
      phoneEndDateStr: null,
      phoneVoidIndicator: false,
      phoneOutOfServiceIndicator: false,
      emailAddressBeginDateStr: null,
      emailAddressEndDateStr: null,
      emailAddressVoidIndicator: false,
      emailAddressBouncedIndicator: false,
      contactTypeRowIndex: null,
      commonEntityVersionNumber: 0,
      repCommonEntitySK: value.repCommonEntitySK,
      renderContactSection: false,
      renderAddContactSection: false,
      renderEditContactSection: false,
      renderDeleteContactSection: false,
      contactKey: null,
      contactRowIndex: null,
      renderAdditionalContactSection: false,
      renderMailingAddressSection: false,
      commonEntityCrossRefVersionNumber: 0,
      representativeVersionNumber: 0,
      addressUsageVersionNumber: 0,
      addressVersionNumber: 0,
      returnedMailVersionNumber: 0,
      phoneUsageVersionNumber: 0,
      phoneVersionNumber: 0,
      emailUsageVersionNumber: 0,
      emailVersionNumber: 0,
      renderPlusMailingAddressSection: false,
      renderPlusAdditionalContactSection: false,
      nameVrsnNo: 0,
      cmnEntityTypVrsnNo: 0,
      commonEntitySK: null,
      repCommonEntity: null,
      disableContactType: false,
      id: value.id
    });
    if (openContact && document.getElementById("contact_form_pop_up")) {
 document.getElementById("contact_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
    setShowError(false);
  };
  const handleAddressTableAdd = value => () => {
    setAuditAddressTableData([]);
    setShowError(false);
    seterrorMessages([]);
    setAuditLogAddressRow(value);
    setSuccessMessages([]);
    setAddressError([]);
    setOpen(true);
    if (value.id || value.uuid) {
      setSelectedRow(value.id ? value.id : value.uuid);
    }
    setSelectedAddAddressBeginDate(value.beginDate);
    setSelectedAddAddressEndDate(value.endDate);
    setAddAddress({
      auditUserID: logInUserID,
      auditTimeStamp: DateUtilities.getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      addressUsageTypeCode: value.addressUsageTypeCode
        ? value.addressUsageTypeCode
        : DEFAULT_DD_VALUE,
      addressSK: value.addressSK,
      beginDate: value.beginDate,
      endDate: value.endDate,
      addressUsageSigCode: value.addressUsageSigCode
        ? value.addressUsageSigCode
        : DEFAULT_DD_VALUE,
      changeReasonCode: value.changeReasonCode
        ? value.changeReasonCode
        : DEFAULT_DD_VALUE,
      statusCode: value.statusCode ? value.statusCode : DEFAULT_DD_VALUE,
      addressRepCrossReferences: [],
      addressUsageSequenceNumber: 1,
      addressUsageSK: value.addressUsageSK,
      commonEntitySK: value.commonEntitySK,
      addressUsageVersionNumber: "0",
      addressLine1: value.addressLine1 ? value.addressLine1 : "",
      addressLine2: value.addressLine2 ? value.addressLine2 : "",
      addressLine3: value.addressLine3 ? value.addressLine3 : "",
      addressLine4: value.addressLine4 ? value.addressLine4 : "",
      statusDesc: null,
      addressTypeDesc: null,
      countyDesc: null,
      latitudeNumber: value.latitudeNumber ? value.latitudeNumber : null,
      longitudeNumber: value.longitudeNumber ? value.longitudeNumber : null,
      city: value.city ? value.city : "",
      state: value.state ? value.state : DEFAULT_DD_VALUE,
      zipCode5: value.zipCode5 ? value.zipCode5 : "",
      zipCode4: "",
      town: value.town,
      country: value.country ? value.country : DEFAULT_DD_VALUE,
      county: value.county ? value.county : DEFAULT_DD_VALUE,
      uspsAddressVerifyCode: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      addressVersionNumber: 0,
      id: value.id,
      uuid: value.uuid
    });
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    settempAddAddress({
      auditUserID: logInUserID,
      auditTimeStamp: DateUtilities.getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      addressUsageTypeCode: value.addressUsageTypeCode
        ? value.addressUsageTypeCode
        : "",
      addressSK: value.addressSK,
      beginDate: value.beginDate,
      endDate: value.endDate,
      addressUsageSigCode: value.addressUsageSigCode
        ? value.addressUsageSigCode
        : "",
      changeReasonCode: value.changeReasonCode ? value.changeReasonCode : "",
      statusCode: value.statusCode ? value.statusCode : "",
      addressRepCrossReferences: [],
      addressUsageSequenceNumber: 1,
      addressUsageSK: value.addressUsageSK,
      commonEntitySK: value.commonEntitySK,
      addressUsageVersionNumber: "0",
      addressLine1: value.addressLine1 ? value.addressLine1 : "",
      addressLine2: value.addressLine2 ? value.addressLine2 : "",
      addressLine3: value.addressLine3 ? value.addressLine3 : "",
      addressLine4: value.addressLine4 ? value.addressLine4 : "",
      statusDesc: null,
      addressTypeDesc: null,
      countyDesc: null,
      latitudeNumber: value.latitudeNumber ? value.latitudeNumber : null,
      longitudeNumber: value.longitudeNumber ? value.longitudeNumber : null,
      city: value.city ? value.city : "",
      state: value.state ? value.state : "",
      zipCode5: value.zipCode5 ? value.zipCode5 : "",
      zipCode4: "",
      town: value.town,
      country: value.country ? value.country : "",
      county: value.county ? value.county : "",
      uspsAddressVerifyCode: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      addressVersionNumber: 0,
      id: value.id,
      uuid: value.uuid
    });
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    if (open && document.getElementById("address_form_pop_up")) {
 document.getElementById("address_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  const handleClickOpen = () => {
    setAuditAddressTableData([]);
    const errorMessagesArray = validateMasterInputs(false);
    if (errorMessagesArray.length === 0) {
      setOpen(true);
      setDataElement({
        code: "",
        longDescription: "",
        description: ""
      });
      setAddAddress({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressUsageTypeCode: DEFAULT_DD_VALUE,
        addressSK: null,
        beginDate: "",
        endDate: "",
        addressUsageSigCode: DEFAULT_DD_VALUE,
        changeReasonCode: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        addressRepCrossReferences: [],
        addressUsageSequenceNumber: 1,
        addressUsageSK: null,
        commonEntitySK: null,
        addressUsageVersionNumber: "0",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        statusDesc: null,
        addressTypeDesc: null,
        countyDesc: null,
        latitudeNumber: "",
        longitudeNumber: "",
        city: "",
        state: DEFAULT_DD_VALUE,
        zipCode5: "",
        zipCode4: "",
        town: "",
        country: DEFAULT_DD_VALUE,
        county: DEFAULT_DD_VALUE,
        uspsAddressVerifyCode: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        addressVersionNumber: 0
      });
      setUspsAddress({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode5: ""
      });
      setSelectedAddAddressEndDate(null);
      setShowError({
        showAddressBeginDate: false
      });
      setSelectedRow(null);
    } else {
      seterrorMessages(errorMessagesArray);
    }
    if (open && document.getElementById("address_form_pop_up")) {
 document.getElementById("address_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  const handleClickOpenLockBox = () => {
    setAuditTableData([]);
    setIsEditLockbox(false);
    const errorMessagesArray = validateMasterInputs(false);
    if (errorMessagesArray.length === 0) {
      setSelectedLockBoxBeginDate(null);
      setSelectedLockBoxEndDate(null);
      setOpenLockBox(true);
      setlockBoxDetails({
        beginDate: "",
        endDate: "",
        lockBoxNumber: "",
        lockBoxName: ""
      });
      setSelectedRow(null);
    } else {
      seterrorMessages(errorMessagesArray);
    }
    if (openLockBox && document.getElementById("lockbox_form_pop_up")) {
      document.getElementById("lockbox_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleClickOpenContact = () => {
    setAuditContactsTableData([]);
    const errorMessagesArray = validateMasterInputs(false);
    if (errorMessagesArray.length === 0) {
      setOpenContact(true);
      setSelectedContactEndDate(null);
      setSelectedContactDOB(null);
      setSelectedContactDOD(null);
      setContactDetails({
        auditUserID: null,
        auditTimeStamp: null,
        addedAuditUserID: null,
        addedAuditTimeStamp: null,
        versionNo: 0,
        dbRecord: true,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        contactType: DEFAULT_DD_VALUE,
        addressType: null,
        contactSSN: "",
        contactDob: null,
        contactDod: null,
        contactStatusDesc: null,
        contactTypeDesc: null,
        contactTitle: "",
        significanceTypeCode: DEFAULT_DD_VALUE,
        status: null,
        contactAddrBeginDate: null,
        contactAddrEndDate: null,
        gender: DEFAULT_DD_VALUE,
        streetaddress: null,
        zipcode: null,
        dummyInd: null,
        addressUsageSequenceNumber: null,
        statusCode: DEFAULT_DD_VALUE,
        changeReason: null,
        beginDate: null,
        endDate: null,
        addressTypeRowIndex: null,
        latitudeNumber: null,
        longitudeNumber: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        uspsAddressVerifyCode: null,
        addressUsageSK: null,
        prefix: DEFAULT_DD_VALUE,
        lastName: "",
        firstName: "",
        middleName: "",
        suffix: DEFAULT_DD_VALUE,
        phone: null,
        phoneExtn: null,
        email: null,
        addressOne: null,
        addressTwo: null,
        addressThree: null,
        addressFour: null,
        county: null,
        country: null,
        town: null,
        addressVerification: null,
        uspsVerifiedCode: null,
        latitude: null,
        longitude: null,
        city: null,
        state: null,
        zipOne: null,
        zipTwo: null,
        representativeSK: null,
        eaddressSK: null,
        phoneSK: null,
        addressSK: null,
        countryCode: null,
        countyCode: null,
        addressBeginDateStr: null,
        addressEndDateStr: null,
        mailReturnedIndicator: false,
        addressVoidIndicator: false,
        addressPendingIndicator: false,
        phoneBeginDateStr: null,
        phoneEndDateStr: null,
        phoneVoidIndicator: false,
        phoneOutOfServiceIndicator: false,
        emailAddressBeginDateStr: null,
        emailAddressEndDateStr: null,
        emailAddressVoidIndicator: false,
        emailAddressBouncedIndicator: false,
        contactTypeRowIndex: null,
        commonEntityVersionNumber: 0,
        repCommonEntitySK: null,
        renderContactSection: false,
        renderAddContactSection: false,
        renderEditContactSection: false,
        renderDeleteContactSection: false,
        contactKey: null,
        contactRowIndex: null,
        renderAdditionalContactSection: false,
        renderMailingAddressSection: false,
        commonEntityCrossRefVersionNumber: 0,
        representativeVersionNumber: 0,
        addressUsageVersionNumber: 0,
        addressVersionNumber: 0,
        returnedMailVersionNumber: 0,
        phoneUsageVersionNumber: 0,
        phoneVersionNumber: 0,
        emailUsageVersionNumber: 0,
        emailVersionNumber: 0,
        renderPlusMailingAddressSection: false,
        renderPlusAdditionalContactSection: false,
        nameVrsnNo: 0,
        cmnEntityTypVrsnNo: 0,
        commonEntitySK: null,
        repCommonEntity: null,
        disableContactType: false
      });
      setSelectedRow(null);
    } else {
      seterrorMessages(errorMessagesArray);
    }
    if (openContact && document.getElementById("contact_form_pop_up")) {
 document.getElementById("contact_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  const handleClose = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);

    setShowAdditionalDetails1(false);
    handleResetAddress();
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    setOpen(false);
    setSelectedRow(null);
  };

  const handleCloseLockBox = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    handleResetLockBox(lockBoxDetails);
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    setOpenLockBox(false);
    setSelectedRow(null);
  };

  const handleContactClose = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    handleResetContact();
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    setOpenContact(false);
    setSelectedRow(null);
  };

  const handleChangeTabs = (event, newValue) => {
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    setValue(newValue);
  };
  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitalFocusCheck(true);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeLockBox = name => event => {
    setAllowNavigation(true);
    setFocusCheck(true);
    setlockBoxDetails({ ...lockBoxDetails, [name]: event.target.value });
  };

  const handleChangeAddAddress = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setAddAddress({ ...addAddress, [name]: event.target.value });
  };

  const handleAddContact = name => event => {
    setAllowNavigation(true);
    setFocusCheck(true);
    setContactDetails({ ...contactDetails, [name]: event.target.value });
  };
  const handleResetLockBox = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    setOpenLockBox(true);
    if (selectedRow === null) {
      setlockBoxDetails({
        lockBoxNumber: "",
        lockBoxName: ""
      });
      setSelectedLockBoxBeginDate(null);
      setSelectedLockBoxEndDate(null);
    } else {
      setlockBoxDetails(templockBoxDetails);
      setSelectedLockBoxBeginDate(new Date(templockBoxDetails.beginDate));
      setSelectedLockBoxEndDate(new Date(templockBoxDetails.endDate));
    }
    setShowError(false);
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
  };
  const handleResetAddress = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    setConsiderUSPS("Y");
    setUspsAddress({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode5: ""
    });
    setShowAdditionalDetails1(false);
    setAddressError([]);
    if (selectedRow === null) {
      setAddAddress({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressUsageTypeCode: DEFAULT_DD_VALUE,
        addressSK: null,
        beginDate: "",
        endDate: "",
        addressUsageSigCode: DEFAULT_DD_VALUE,
        changeReasonCode: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        addressRepCrossReferences: [],
        addressUsageSequenceNumber: 1,
        addressUsageSK: null,
        commonEntitySK: null,
        addressUsageVersionNumber: "0",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        statusDesc: null,
        addressTypeDesc: null,
        countyDesc: null,
        latitudeNumber: "",
        longitudeNumber: "",
        city: "",
        state: DEFAULT_DD_VALUE,
        zipCode5: "",
        zipCode4: "",
        town: "",
        country: DEFAULT_DD_VALUE,
        county: DEFAULT_DD_VALUE,
        uspsAddressVerifyCode: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        addressVersionNumber: 0
      });
      if (selectedDate) {
        setSelectedAddAddressBeginDate(selectedDate);
      } else {
        setSelectedAddAddressBeginDate(null);
      }
      setSelectedAddAddressEndDate(null);
    } else {
      setAddAddress(tempAddAddress);
      setSelectedAddAddressBeginDate(
        tempAddAddress.beginDate !== ""
          ? new Date(tempAddAddress.beginDate)
          : null
      );
      setSelectedAddAddressEndDate(new Date(tempAddAddress.endDate));
    }
    setShowError(false);
    seterrorMessages([]);
  };

  const handleResetContact = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    if (selectedRow === null) {
      setContactDetails({
        auditUserID: null,
        auditTimeStamp: null,
        addedAuditUserID: null,
        addedAuditTimeStamp: null,
        versionNo: 0,
        dbRecord: true,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        contactType: DEFAULT_DD_VALUE,
        addressType: null,
        contactSSN: "",
        contactDob: null,
        contactDod: null,
        contactStatusDesc: null,
        contactTypeDesc: null,
        contactTitle: "",
        significanceTypeCode: DEFAULT_DD_VALUE,
        status: null,
        contactAddrBeginDate: null,
        contactAddrEndDate: null,
        gender: DEFAULT_DD_VALUE,
        streetaddress: null,
        zipcode: null,
        dummyInd: null,
        addressUsageSequenceNumber: null,
        statusCode: DEFAULT_DD_VALUE,
        changeReason: null,
        beginDate: null,
        endDate: null,
        addressTypeRowIndex: null,
        latitudeNumber: null,
        longitudeNumber: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        uspsAddressVerifyCode: null,
        addressUsageSK: null,
        prefix: DEFAULT_DD_VALUE,
        lastName: "",
        firstName: "",
        middleName: "",
        suffix: DEFAULT_DD_VALUE,
        phone: null,
        phoneExtn: null,
        email: null,
        addressOne: null,
        addressTwo: null,
        addressThree: null,
        addressFour: null,
        county: null,
        country: null,
        town: null,
        addressVerification: null,
        uspsVerifiedCode: null,
        latitude: null,
        longitude: null,
        city: null,
        state: null,
        zipOne: null,
        zipTwo: null,
        representativeSK: null,
        eaddressSK: null,
        phoneSK: null,
        addressSK: null,
        countryCode: null,
        countyCode: null,
        addressBeginDateStr: null,
        addressEndDateStr: null,
        mailReturnedIndicator: false,
        addressVoidIndicator: false,
        addressPendingIndicator: false,
        phoneBeginDateStr: null,
        phoneEndDateStr: null,
        phoneVoidIndicator: false,
        phoneOutOfServiceIndicator: false,
        emailAddressBeginDateStr: null,
        emailAddressEndDateStr: null,
        emailAddressVoidIndicator: false,
        emailAddressBouncedIndicator: false,
        contactTypeRowIndex: null,
        commonEntityVersionNumber: 0,
        repCommonEntitySK: null,
        renderContactSection: false,
        renderAddContactSection: false,
        renderEditContactSection: false,
        renderDeleteContactSection: false,
        contactKey: null,
        contactRowIndex: null,
        renderAdditionalContactSection: false,
        renderMailingAddressSection: false,
        commonEntityCrossRefVersionNumber: 0,
        representativeVersionNumber: 0,
        addressUsageVersionNumber: 0,
        addressVersionNumber: 0,
        returnedMailVersionNumber: 0,
        phoneUsageVersionNumber: 0,
        phoneVersionNumber: 0,
        emailUsageVersionNumber: 0,
        emailVersionNumber: 0,
        renderPlusMailingAddressSection: false,
        renderPlusAdditionalContactSection: false,
        nameVrsnNo: 0,
        cmnEntityTypVrsnNo: 0,
        commonEntitySK: null,
        repCommonEntity: null,
        disableContactType: false
      });
      if (selectedDate) {
        setSelectedContactBeginDate(selectedDate);
      } else {
        setSelectedContactBeginDate(null);
      }
      setSelectedContactEndDate(null);
      setSelectedContactDOB(null);
      setSelectedContactDOD(null);
    } else {
      setContactDetails(tempContactDetails);
      setSelectedContactBeginDate(
        new Date(contactDetails.contactAddrBeginDate)
      );
      setSelectedContactEndDate(new Date(contactDetails.contactAddrEndDate));
      setSelectedContactDOB(
        contactDetails.contactDob ? new Date(contactDetails.contactDob) : null
      );
      setSelectedContactDOD(
        contactDetails.contactDod ? new Date(contactDetails.contactDod) : null
      );
      errorMessagesArray = [];
      seterrorMessages(errorMessagesArray);
    }
    setShowError(false);
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
  };

  const handleSaveLockBox = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var showLockBoxNameError;
    var showLockBoxBeginDateGreaterError;
    var showLockBoxNumberError;
    var showLockBoxBeginDateMatchError;
    var showLockBoxBeginDateError;
    var showLockBoxEndDateError;
    var showLockBoxEndDateMatchError;
    var showLockBoxDuplicateNumberError;
    var validLockBoxBeginDateShow;
    var validLockBoxEndDateShow;
    var isLockBoxBeginDateValid;
    var isLockBoxEndDateValid;
    var invalidLockBoxBeginDateError;
    var invalidLockBoxEndDateError;
    var showLockBoxBeginDateSmallerError = false;
    var overLapDateError;
    if (!lockBoxDetails.lockBoxName) {
      showLockBoxNameError = true;
      errorMessagesArray.push("LockBox Name " + ErrorConstants.NAME_ERROR);
    }
    if (!lockBoxDetails.lockBoxNumber) {
      showLockBoxNumberError = true;
      errorMessagesArray.push("LockBox # " + ErrorConstants.NAME_ERROR);
    }
    // Begin Date Validations
    if (!selectedLockBoxBeginDate) {
      showLockBoxBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.NAME_ERROR);
    }
    if (
      selectedLockBoxBeginDate &&
      selectedLockBoxBeginDate.toString() === "Invalid Date"
    ) {
      invalidLockBoxBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedLockBoxBeginDate &&
      selectedLockBoxBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedLockBoxBeginDate
      );
      if (isDateValid) {
        isLockBoxBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isEditLockBox &&
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate)
        ) < new Date(DateUtilities.getDateInMMDDYYYYFormat(new Date()))
      ) {
        showLockBoxBeginDateSmallerError = true;
        errorMessagesArray.push(
          ErrorConstants.LOCKBOX_BEGIN_DATE_SMALLER_ERROR
        );
      }
      if (
        !isDateValid &&
        !showLockBoxBeginDateSmallerError &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate)
        ) < new Date(DateUtilities.getDateInMMDDYYYYFormat(selectedDate))
      ) {
        showLockBoxBeginDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.LOCKBOX_DATES_MATCH_ERROR);
      }
    }

    // End Date Validations
    var tempSelectedLockBoxEndDate = selectedLockBoxEndDate;
    if (!selectedLockBoxEndDate) {
      const date = new Date("12/31/9999");
      setSelectedLockBoxEndDate(date);
      tempSelectedLockBoxEndDate = date;
    }
    if (
      tempSelectedLockBoxEndDate &&
      tempSelectedLockBoxEndDate.toString() === "Invalid Date"
    ) {
      invalidLockBoxEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempSelectedLockBoxEndDate &&
      tempSelectedLockBoxEndDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        tempSelectedLockBoxEndDate
      );
      if (isDateValid) {
        isLockBoxEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedLockBoxEndDate
          )
        ) >
          new Date(
            DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
          )
      ) {
        showLockBoxEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.LOCKBOX_DATES_MATCH_ERROR);
      }
    }
    // Begin Date & End Date comparison
    if (
      selectedLockBoxBeginDate &&
      tempSelectedLockBoxEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          tempSelectedLockBoxEndDate
        )
      ) <
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            selectedLockBoxBeginDate
          )
        )
    ) {
      showLockBoxBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.LOCKBOX_BEGIN_DATE_GREATER_ERROR);
    }
    // Duplicate Lock Box Check
    if (lockBoxTableData.length > 0) {
      for (var i = 0; i < lockBoxTableData.length; i++) {
        if (selectedRow) {
          if (
            lockBoxDetails.id === undefined &&
            selectedRow !== lockBoxTableData[i].lockBoxKey &&
            lockBoxTableData[i].lockBoxNumber === lockBoxDetails.lockBoxNumber
          ) {
            errorMessagesArray.push(
              ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
            );
            showLockBoxDuplicateNumberError = true;
            break;
          } else if (
            lockBoxDetails.id !== undefined &&
            lockBoxTableData[i].lockBoxKey !== null &&
            selectedRow === lockBoxDetails.id &&
            lockBoxTableData[i].lockBoxNumber === lockBoxDetails.lockBoxNumber
          ) {
            errorMessagesArray.push(
              ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
            );
            showLockBoxDuplicateNumberError = true;
            break;
          } else if (
            lockBoxDetails.id !== undefined &&
            lockBoxTableData[i].lockBoxKey === null &&
            selectedRow !== i + 1 &&
            lockBoxTableData[i].lockBoxNumber === lockBoxDetails.lockBoxNumber
          ) {
            errorMessagesArray.push(
              ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
            );
            showLockBoxDuplicateNumberError = true;
            break;
          }
        } else if (
          lockBoxTableData[i].lockBoxNumber === lockBoxDetails.lockBoxNumber
        ) {
          errorMessagesArray.push(
            ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
          );
          showLockBoxDuplicateNumberError = true;
          break;
        }
      }
    }

    if (selectedLockBoxBeginDate && selectedLockBoxEndDate) {
      for (let i = 0; i < lockBoxTableData.length; i++) {
        if (lockBoxBeginDateChange || lockBoxEndDateChange) {
          if (
            retainSelectedLockBoxBeginDate !==
              DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate) ||
            retainSelectedLockBoxEndDate !==
              DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxEndDate)
          ) {
            if (
              new Date(
                DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate)
              ) >=
                new Date(DateUtilities.getDateInMMDDYYYYFormat(selectedDate)) &&
              new Date(
                DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate)
              ) <=
                new Date(DateUtilities.getDateInMMDDYYYYFormat(selectedEndDate))
            ) {
if ((currentLockbox && currentLockbox.id) && (lockBoxTableData[i] && lockBoxTableData[i].id)) {
            if (((currentLockbox.id &&// will not validate for the same selected row with it's data
                lockBoxTableData[i].id &&
                currentLockbox.id !== lockBoxTableData[i].id))) {
                if (
                  ((currentLockbox.id &&
                    lockBoxTableData[i].id &&
                    currentLockbox.id !== lockBoxTableData[i].id) ||
                    (currentLockbox.lockBoxId &&
                      lockBoxTableData[i].lockBoxId &&
                      currentLockbox.lockBoxId !==
                        lockBoxTableData[i].lockBoxId) ||
                    (currentLockbox.lockBoxId && lockBoxTableData[i].id) ||
                    (currentLockbox.id && lockBoxTableData[i].lockBoxId))

                ) {
                  if (DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) <= lockBoxTableData[i].lockBoxEndDate &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) >= lockBoxTableData[i].lockBoxBeginDate) {
                    overLapDateError = true;
                    showLockBoxBeginDateMatchError = false;
                    errorMessagesArray.push(
                      ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                    );
                    break;
                  }
                }
                if (
                  ((currentLockbox.id &&
                    lockBoxTableData[i].id &&
                    currentLockbox.id !== lockBoxTableData[i].id) ||
                    (currentLockbox.lockBoxId &&
                      lockBoxTableData[i].lockBoxId &&
                      currentLockbox.lockBoxId !==
                        lockBoxTableData[i].lockBoxId) ||
                    (currentLockbox.lockBoxId && lockBoxTableData[i].id) ||
                    (currentLockbox.id && lockBoxTableData[i].lockBoxId))

                ) {
                  if (
                    lockBoxTableData[i].lockBoxBeginDate <=
                    DateUtilities.getDateInMMDDYYYYFormat(
                      selectedLockBoxBeginDate
                    ) &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxBeginDate
                  ) <= lockBoxTableData[i].lockBoxEndDate &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) <= lockBoxTableData[i].lockBoxEndDate &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) <= lockBoxTableData[i].lockBoxEndDate
                  ) {
                  overLapDateError = true;
                  showLockBoxBeginDateMatchError = false;
                  errorMessagesArray.push(
                    ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                  );
                  break;
}
                }
                if (
                  ((currentLockbox.id &&
                    lockBoxTableData[i].id &&
                    currentLockbox.id !== lockBoxTableData[i].id) ||
                    (currentLockbox.lockBoxId &&
                      lockBoxTableData[i].lockBoxId &&
                      currentLockbox.lockBoxId !==
                        lockBoxTableData[i].lockBoxId) ||
                    (currentLockbox.lockBoxId && lockBoxTableData[i].id) ||
                    (currentLockbox.id && lockBoxTableData[i].lockBoxId))

                ) {
                  if ((DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) == lockBoxTableData[i].lockBoxBeginDate ||
                    DateUtilities.getDateInMMDDYYYYFormat(
                      selectedLockBoxEndDate
                    ) == lockBoxTableData[i].lockBoxEndDate)) {
 overLapDateError = true;
                  showLockBoxBeginDateMatchError = false;
                  errorMessagesArray.push(
                    ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                  );
                  break;
}
                }
                if (
                  ((currentLockbox.id &&
                    lockBoxTableData[i].id &&
                    currentLockbox.id !== lockBoxTableData[i].id) ||
                    (currentLockbox.lockBoxId &&
                      lockBoxTableData[i].lockBoxId &&
                      currentLockbox.lockBoxId !==
                        lockBoxTableData[i].lockBoxId) ||
                    (currentLockbox.lockBoxId && lockBoxTableData[i].id) ||
                    (currentLockbox.id && lockBoxTableData[i].lockBoxId)) &&
                  (DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxBeginDate
                  ) == lockBoxTableData[i].lockBoxBeginDate ||
                    DateUtilities.getDateInMMDDYYYYFormat(
                      selectedLockBoxBeginDate
                    ) == lockBoxTableData[i].lockBoxEndDate)
                ) {
                  overLapDateError = true;
                  showLockBoxBeginDateMatchError = false;
                  errorMessagesArray.push(
                    ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                  );
                  break;
                }
              } else if ((currentLockbox.id &&
                lockBoxTableData[i].id &&// will not validate for the same selected row with it's data
                currentLockbox.id !== lockBoxTableData[i].id)) {
                if (
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) <= lockBoxTableData[i].lockBoxEndDate &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) >= lockBoxTableData[i].lockBoxBeginDate
                ) {
                  overLapDateError = true;
                  showLockBoxBeginDateMatchError = false;
                  errorMessagesArray.push(
                    ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                  );
                  break;
                }
                if (
                  lockBoxTableData[i].lockBoxBeginDate <=
                    DateUtilities.getDateInMMDDYYYYFormat(
                      selectedLockBoxBeginDate
                    ) &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxBeginDate
                  ) <= lockBoxTableData[i].lockBoxEndDate &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) <= lockBoxTableData[i].lockBoxEndDate &&
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) <= lockBoxTableData[i].lockBoxEndDate
                ) {
                  overLapDateError = true;
                  showLockBoxBeginDateMatchError = false;
                  errorMessagesArray.push(
                    ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                  );
                  break;
                }

                if (
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) == lockBoxTableData[i].lockBoxBeginDate ||
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxEndDate
                  ) == lockBoxTableData[i].lockBoxEndDate
                ) {
                  overLapDateError = true;
                  showLockBoxBeginDateMatchError = false;
                  errorMessagesArray.push(
                    ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                  );
                  break;
                }
                if (
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxBeginDate
                  ) == lockBoxTableData[i].lockBoxBeginDate ||
                  DateUtilities.getDateInMMDDYYYYFormat(
                    selectedLockBoxBeginDate
                  ) == lockBoxTableData[i].lockBoxEndDate
                ) {
                  overLapDateError = true;
                  showLockBoxBeginDateMatchError = false;
                  errorMessagesArray.push(
                    ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                  );
                  break;
                }
              }
}
            } else {
              overLapDateError = false;
              showLockBoxBeginDateMatchError = true;
              errorMessagesArray.push(ErrorConstants.LOCKBOX_DATES_MATCH_ERROR);
            }
          }
        }
      }
    }
    if (errorMessagesArray.length <= 0) {
      if (selectedRow === null) {
        lockBoxDetails.auditUserID = logInUserID;
        lockBoxDetails.auditTimeStamp = DateUtilities.getUTCTimeStamp();
        lockBoxDetails.addedAuditUserID = logInUserID;
        lockBoxDetails.addedAuditTimeStamp = DateUtilities.getUTCTimeStamp();
        lockBoxDetails.versionNo = 0;
        lockBoxDetails.dbRecord = true;
        lockBoxDetails.sortColumn = null;
        lockBoxDetails.auditKeyList = [];
        lockBoxDetails.auditKeyListFiltered = false;
        lockBoxDetails.lockBoxId = null;
        lockBoxDetails.renderLockBoxSection = false;
        lockBoxDetails.renderDeleteLockBoxHeader = false;
        lockBoxDetails.lockBoxKey = null;
        lockBoxDetails.lockBoxRowIndex = null;
        lockBoxDetails.renderAddLockBoxHeader = false;
        lockBoxDetails.renderEditLockBoxHeader = false;
        lockBoxDetails.disableLockBoxNumber = false;
        lockBoxDetails.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
          selectedLockBoxBeginDate
        );
        lockBoxDetails.endDate = DateUtilities.getDateInMMDDYYYYFormat(
          tempSelectedLockBoxEndDate
        );
        lockBoxDetails.lockBoxBeginDate = lockBoxDetails.beginDate;
        lockBoxDetails.lockBoxEndDate = lockBoxDetails.endDate;
        if (lockBoxTableData.length === 0) {
          let id = lockBoxTableData.length;
          id++;
          lockBoxDetails.id = id;
          setLockBoxTableData(lockBoxTableData => [
            ...lockBoxTableData,
            lockBoxDetails
          ]);
        } else {
          let id = lockBoxTableData.length;
          if (lockBoxTableData[id - 1].id === undefined) {
            id = lockBoxTableData.length;
          } else {
            id = lockBoxTableData[id - 1].id;
          }
          id++;
          lockBoxDetails.id = id;
          setLockBoxTableData(lockBoxTableData => [
            ...lockBoxTableData,
            lockBoxDetails
          ]);
        }
      } else {
        if (lockBoxTableData.length === 0) {
          let id = lockBoxTableData.length;
          id++;
          lockBoxDetails.id = id;
          lockBoxDetails.auditUserID = logInUserID;
          lockBoxDetails.auditTimeStamp = DateUtilities.getUTCTimeStamp();
          lockBoxDetails.addedAuditUserID = logInUserID;
          lockBoxDetails.addedAuditTimeStamp = DateUtilities.getUTCTimeStamp();
          setLockBoxTableData(lockBoxTableData => [
            ...lockBoxTableData,
            lockBoxDetails
          ]);
        } else {
          lockBoxTableData.map(element => {
            if (
              element.id === selectedRow ||
              element.lockBoxKey === selectedRow
            ) {
              lockBoxDetails.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
                selectedLockBoxBeginDate
              );
              lockBoxDetails.endDate = DateUtilities.getDateInMMDDYYYYFormat(
                tempSelectedLockBoxEndDate
              );
              element.auditUserID = logInUserID;
              element.auditTimeStamp = DateUtilities.getUTCTimeStamp();
              element.addedAuditUserID = element.addedAuditUserID
                ? element.addedAuditUserID
                : logInUserID;
              element.addedAuditTimeStamp = element.addedAuditTimeStamp
                ? element.addedAuditTimeStamp
                : DateUtilities.getUTCTimeStamp();
              element.beginDate = lockBoxDetails.beginDate;
              element.endDate = lockBoxDetails.endDate;
              element.lockBoxName = lockBoxDetails.lockBoxName.trim();
              element.lockBoxNumber = lockBoxDetails.lockBoxNumber.trim();
              element.lockBoxBeginDate = lockBoxDetails.beginDate;
              element.lockBoxEndDate = lockBoxDetails.endDate;
            }
          });
        }
      }
      setOpenLockBox(false);
      setlockBoxDetails({
        lockBoxNumber: "",
        lockBoxName: ""
      });
      setSelectedLockBoxBeginDate(null);
      setSelectedLockBoxEndDate(null);
      setSelectedRow(null);
    }
    setShowError({
      showLockBoxNameError: showLockBoxNameError,
      showLockBoxNumberError: showLockBoxNumberError,
      showLockBoxBeginDateError: showLockBoxBeginDateError,
      showLockBoxEndDateError: showLockBoxEndDateError,
      showLockBoxBeginDateGreaterError: showLockBoxBeginDateGreaterError,
      showLockBoxBeginDateSmallerError: showLockBoxBeginDateSmallerError,
      showEndDateError: showEndDateError,
      showLockBoxEndDateMatchError: showLockBoxEndDateMatchError,
      showLockBoxBeginDateMatchError: showLockBoxBeginDateMatchError,
      validLockBoxBeginDateShow: validLockBoxBeginDateShow,
      validLockBoxEndDateShow: validLockBoxEndDateShow,
      invalidLockBoxBeginDateError: invalidLockBoxBeginDateError,
      invalidLockBoxEndDateError: invalidLockBoxEndDateError,
      isLockBoxBeginDateValid: isLockBoxBeginDateValid,
      isLockBoxEndDateValid: isLockBoxEndDateValid,
      showLockBoxDuplicateNumberError: showLockBoxDuplicateNumberError,
      overLapDateError: overLapDateError
    });
    seterrorMessages(errorMessagesArray);
  };

  const handleSaveContact = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var showStatusrror;
    var showContactTypeError;
    var showContactBeginDateError;
    var showContactEndDateError;
    var showSignificanceError;
    var showContactFName;
    var showContactMName;
    var showContactLName;
    var showContactDateMatchError;
    var showContactEndDateMatchError;
    var showContactBeginDateGreaterError;
    var validContactBeginDateShow;
    var validContactEndDateShow;
    var isContactEndDateValid;
    var invalidContactBeginDateError;
    var invalidContactEndDateError;
    var isContactBeginDateValid;
    var invalidDateOfBirthError;
    var invalidDateOfDeathError;
    var showDeathDateError = false;

    if (
      !contactDetails.statusCode ||
      contactDetails.statusCode === DEFAULT_DD_VALUE || contactCodeVal === undefined
    ) {
      showStatusrror = true;
      errorMessagesArray.push("Status " + ErrorConstants.NAME_ERROR);
    }
    if (
      !contactDetails.contactType ||
      contactDetails.contactType === DEFAULT_DD_VALUE
    ) {
      showContactTypeError = true;
      errorMessagesArray.push("Contact Type " + ErrorConstants.NAME_ERROR);
    }
    if (!selectedContactBeginDate) {
      showContactBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.NAME_ERROR);
    }
    if (
      !contactDetails.significanceTypeCode ||
      contactDetails.significanceTypeCode === DEFAULT_DD_VALUE || contactSignCode === undefined
    ) {
      showSignificanceError = true;
      errorMessagesArray.push("Significance " + ErrorConstants.NAME_ERROR);
    }
    if (!contactDetails.firstName) {
      showContactFName = true;
      errorMessagesArray.push("First Name " + ErrorConstants.NAME_ERROR);
    }
    if (!contactDetails.lastName) {
      showContactLName = true;
      errorMessagesArray.push("Last Name " + ErrorConstants.NAME_ERROR);
    }
    // Begin Date Validation
    if (
      selectedContactBeginDate &&
      selectedContactBeginDate.toString() === "Invalid Date"
    ) {
      invalidContactBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedContactBeginDate &&
      selectedContactBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedContactBeginDate
      );
      if (isDateValid) {
        isContactBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            selectedContactBeginDate
          )
        ) <
          new Date(
            DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedDate)
          )
      ) {
        showContactDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.BEGIN_DATE_MATCH_ERROR);
      }
    }
    // End Date Validation
    var tempContactEndDate = new Date(selectedContactEndDate);
    if (!selectedContactEndDate) {
      setSelectedContactEndDate(new Date("12/31/9999"));
      tempContactEndDate = new Date("12/31/9999");
    }
    if (
      tempContactEndDate &&
      tempContactEndDate.toString() === "Invalid Date"
    ) {
      invalidContactEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempContactEndDate &&
      tempContactEndDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        tempContactEndDate
      );
      if (isDateValid) {
        isContactEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(tempContactEndDate)
        ) >
          new Date(
            DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
          )
      ) {
        showContactEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.BEGIN_DATE_MATCH_ERROR);
      }
    }
    // Begin Date + End Date Comparison
    if (
      selectedContactBeginDate &&
      tempContactEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedContactBeginDate
        )
      ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(tempContactEndDate)
        )
    ) {
      showContactBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.CONTACT_BEGIN_DATE_GREATER_ERROR);
    }

    // DOD & DOB Validation
    if (
      selectedContactDOB &&
      selectedContactDOB.toString() === "Invalid Date"
    ) {
      invalidDateOfBirthError = true;
      errorMessagesArray.push("Date of Birth " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedContactDOD &&
      selectedContactDOD.toString() === "Invalid Date"
    ) {
      invalidDateOfDeathError = true;
      errorMessagesArray.push("Date of Death " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedContactDOB &&
      selectedContactDOD &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedContactDOB)
      ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedContactDOD)
        )
    ) {
      showDeathDateError = true;
      errorMessagesArray.push(ErrorConstants.DEATH_DATE_ERROR);
    }

    if (errorMessagesArray.length === 0) {
      for (const key in contactDetails) {
        if (contactDetails[key] === DEFAULT_DD_VALUE) {
          contactDetails[key] = "";
        }
      }
      if (selectedRow === null) {
        setSelectedContactBeginDate(new Date(selectedContactBeginDate));
        setSelectedContactEndDate(new Date(tempContactEndDate));
        if (addContactsData.length === 0) {
          let id = addContactsData.length;
          id++;
          contactDetails.id = id;
          contactDetails.auditUserID = logInUserID;
          contactDetails.auditTimeStamp = DateUtilities.getUTCTimeStamp();
          contactDetails.addedAuditUserID = logInUserID;
          contactDetails.addedAuditTimeStamp = DateUtilities.getUTCTimeStamp();
          contactDetails.contactAddrBeginDate =
            new Date(selectedContactBeginDate).getMonth() +
            1 +
            "/" +
            new Date(selectedContactBeginDate).getDate() +
            "/" +
            new Date(selectedContactBeginDate).getFullYear();
          contactDetails.contactAddrEndDate =
            tempContactEndDate.getMonth() +
            1 +
            "/" +
            tempContactEndDate.getDate() +
            "/" +
            tempContactEndDate.getFullYear();
          if (selectedContactDOB) {
            contactDetails.contactDob =
              selectedContactDOB.getMonth() +
              1 +
              "/" +
              selectedContactDOB.getDate() +
              "/" +
              selectedContactDOB.getFullYear();
          } else {
            setSelectedContactDOB(null);
          }
          if (selectedContactDOD) {
            contactDetails.contactDod =
              selectedContactDOD.getMonth() +
              1 +
              "/" +
              selectedContactDOD.getDate() +
              "/" +
              selectedContactDOD.getFullYear();
          } else {
            setSelectedContactDOD(null);
          }
          setaddContactsData(addContactsData => [
            ...addContactsData,
            contactDetails
          ]);
        } else {
          let id = addContactsData.length;
          if (addContactsData[id - 1].id !== undefined) {
            id = addContactsData[id - 1].id;
          } else {
            id = 0;
          }
          id++;
          contactDetails.id = id;
          contactDetails.auditUserID = logInUserID;
          contactDetails.auditTimeStamp = DateUtilities.getUTCTimeStamp();
          contactDetails.addedAuditUserID = logInUserID;
          contactDetails.addedAuditTimeStamp = DateUtilities.getUTCTimeStamp();
          contactDetails.contactAddrBeginDate =
            new Date(selectedContactBeginDate).getMonth() +
            1 +
            "/" +
            new Date(selectedContactBeginDate).getDate() +
            "/" +
            new Date(selectedContactBeginDate).getFullYear();
          contactDetails.contactAddrEndDate =
            tempContactEndDate.getMonth() +
            1 +
            "/" +
            tempContactEndDate.getDate() +
            "/" +
            tempContactEndDate.getFullYear();
          if (selectedContactDOB) {
            contactDetails.contactDob =
              selectedContactDOB.getMonth() +
              1 +
              "/" +
              selectedContactDOB.getDate() +
              "/" +
              selectedContactDOB.getFullYear();
          } else {
            setSelectedContactDOB(null);
          }
          if (selectedContactDOD) {
            contactDetails.contactDod =
              selectedContactDOD.getMonth() +
              1 +
              "/" +
              selectedContactDOD.getDate() +
              "/" +
              selectedContactDOD.getFullYear();
          } else {
            setSelectedContactDOD(null);
          }
          setaddContactsData(addContactsData => [
            ...addContactsData,
            contactDetails
          ]);
        }
      } else {
        if (addContactsData.length === 0) {
          let id = addContactsData.length;
          id++;
          contactDetails.id = id;
          contactDetails.auditUserID = logInUserID;
          contactDetails.auditTimeStamp = DateUtilities.getUTCTimeStamp();
          contactDetails.addedAuditUserID = contactDetails.addedAuditUserID
            ? contactDetails.addedAuditUserID
            : logInUserID;
          contactDetails.addedAuditTimeStamp = contactDetails.addedAuditTimeStamp
            ? contactDetails.addedAuditTimeStamp
            : DateUtilities.getUTCTimeStamp();
          contactDetails.contactAddrBeginDate =
            new Date(selectedContactBeginDate).getMonth() +
            1 +
            "/" +
            new Date(selectedContactBeginDate).getDate() +
            "/" +
            new Date(selectedContactBeginDate).getFullYear();
          contactDetails.contactAddrEndDate =
            tempContactEndDate.getMonth() +
            1 +
            "/" +
            tempContactEndDate.getDate() +
            "/" +
            tempContactEndDate.getFullYear();
          if (selectedContactDOB) {
            contactDetails.contactDob =
              selectedContactDOB.getMonth() +
              1 +
              "/" +
              selectedContactDOB.getDate() +
              "/" +
              selectedContactDOB.getFullYear();
          } else {
            setSelectedContactDOB(null);
          }
          if (selectedContactDOD) {
            contactDetails.contactDod =
              selectedContactDOD.getMonth() +
              1 +
              "/" +
              selectedContactDOD.getDate() +
              "/" +
              selectedContactDOD.getFullYear();
          } else {
            setSelectedContactDOD(null);
          }
          setaddContactsData(addContactsData => [
            ...addContactsData,
            contactDetails
          ]);
        } else {
          addContactsData.map(element => {
            if (
              element.id === selectedRow ||
              element.contactKey === selectedRow
            ) {
              contactDetails.contactAddrBeginDate =
                selectedContactBeginDate.getMonth() +
                1 +
                "/" +
                selectedContactBeginDate.getDate() +
                "/" +
                selectedContactBeginDate.getFullYear();
              contactDetails.contactAddrEndDate =
                tempContactEndDate.getMonth() +
                1 +
                "/" +
                tempContactEndDate.getDate() +
                "/" +
                tempContactEndDate.getFullYear();
              if (selectedContactDOB) {
                contactDetails.contactDob =
                  selectedContactDOB.getMonth() +
                  1 +
                  "/" +
                  selectedContactDOB.getDate() +
                  "/" +
                  selectedContactDOB.getFullYear();
              } else {
                setSelectedContactDOB(null);
              }
              if (selectedContactDOD) {
                contactDetails.contactDod =
                  selectedContactDOD.getMonth() +
                  1 +
                  "/" +
                  selectedContactDOD.getDate() +
                  "/" +
                  selectedContactDOD.getFullYear();
              } else {
                setSelectedContactDOD(null);
              }
              element.auditUserID = logInUserID;
              element.auditTimeStamp = DateUtilities.getUTCTimeStamp();
              element.addedAuditUserID = element.addedAuditUserID
                ? element.addedAuditUserID
                : logInUserID;
              element.addedAuditTimeStamp = element.addedAuditTimeStamp
                ? element.addedAuditTimeStamp
                : DateUtilities.getUTCTimeStamp();
              element.contactAddrBeginDate =
                contactDetails.contactAddrBeginDate;
              element.contactAddrEndDate = contactDetails.contactAddrEndDate;
              element.statusCode = contactDetails.statusCode;
              element.contactType = contactDetails.contactType;
              element.significanceTypeCode =
                contactDetails.significanceTypeCode;
              element.contactTitle = contactDetails.contactTitle;
              element.prefix = contactDetails.prefix;
              element.firstName = contactDetails.firstName;
              element.middleName = contactDetails.middleName;
              element.lastName = contactDetails.lastName;
              element.suffix = contactDetails.suffix;
              element.contactSSN = contactDetails.contactSSN;
              element.contactDob = contactDetails.contactDob
                ? contactDetails.contactDob
                : null;
              element.contactDod = contactDetails.contactDod
                ? contactDetails.contactDod
                : null;
              element.gender = contactDetails.gender;
            }
          });
          filterContactData(addContactsData);
        }
      }
      setOpenContact(false);
      setContactDetails({
        contactType: DEFAULT_DD_VALUE,
        contactSSN: "",
        contactDob: "",
        contactDod: "",
        contactTitle: "",
        significanceTypeCode: DEFAULT_DD_VALUE,
        gender: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        beginDate: "",
        endDate: "",
        prefix: DEFAULT_DD_VALUE,
        lastName: "",
        firstName: "",
        middleName: "",
        suffix: DEFAULT_DD_VALUE,
        phone: null,
        auditUserID: null,
        auditTimeStamp: null,
        addedAuditUserID: null,
        addedAuditTimeStamp: null,
        versionNo: 0,
        dbRecord: true,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressType: null,
        contactStatusDesc: null,
        contactTypeDesc: null,
        status: null,
        contactAddrBeginDate: null,
        contactAddrEndDate: null,
        streetaddress: null,
        zipcode: null,
        dummyInd: null,
        addressUsageSequenceNumber: null,
        changeReason: null,
        addressTypeRowIndex: null,
        latitudeNumber: null,
        longitudeNumber: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        uspsAddressVerifyCode: null,
        addressUsageSK: null,
        phoneExtn: null,
        email: null,
        addressOne: null,
        addressTwo: null,
        addressThree: null,
        addressFour: null,
        county: null,
        country: null,
        town: null,
        addressVerification: null,
        uspsVerifiedCode: null,
        latitude: null,
        longitude: null,
        city: null,
        state: null,
        zipOne: null,
        zipTwo: null,
        representativeSK: null,
        eaddressSK: null,
        phoneSK: null,
        addressSK: null,
        countryCode: null,
        countyCode: null,
        addressBeginDateStr: null,
        addressEndDateStr: null,
        mailReturnedIndicator: false,
        addressVoidIndicator: false,
        addressPendingIndicator: false,
        phoneBeginDateStr: null,
        phoneEndDateStr: null,
        phoneVoidIndicator: false,
        phoneOutOfServiceIndicator: false,
        emailAddressBeginDateStr: null,
        emailAddressEndDateStr: null,
        emailAddressVoidIndicator: false,
        emailAddressBouncedIndicator: false,
        contactTypeRowIndex: null,
        commonEntityVersionNumber: 0,
        repCommonEntitySK: null,
        renderContactSection: false,
        renderAddContactSection: false,
        renderEditContactSection: false,
        renderDeleteContactSection: false,
        contactKey: null,
        contactRowIndex: null,
        renderAdditionalContactSection: false,
        renderMailingAddressSection: false,
        commonEntityCrossRefVersionNumber: 0,
        representativeVersionNumber: 0,
        addressUsageVersionNumber: 0,
        addressVersionNumber: 0,
        returnedMailVersionNumber: 0,
        phoneUsageVersionNumber: 0,
        phoneVersionNumber: 0,
        emailUsageVersionNumber: 0,
        emailVersionNumber: 0,
        renderPlusMailingAddressSection: false,
        renderPlusAdditionalContactSection: false,
        nameVrsnNo: 0,
        cmnEntityTypVrsnNo: 0,
        commonEntitySK: null,
        repCommonEntity: null,
        disableContactType: false
      });
      setSelectedContactBeginDate(null);
      setSelectedContactEndDate(null);
      setSelectedContactDOB(null);
      setSelectedContactDOD(null);
      setSelectedRow(null);
    }
    setShowError({
      showStatusrror: showStatusrror,
      showContactTypeError: showContactTypeError,
      showContactBeginDateError: showContactBeginDateError,
      showContactEndDateError: showContactEndDateError,
      showSignificanceError: showSignificanceError,
      showContactFName: showContactFName,
      showContactMName: showContactMName,
      showContactLName: showContactLName,
      showContactDateMatchError: showContactDateMatchError,
      showContactEndDateMatchError: showContactEndDateMatchError,
      validContactBeginDateShow: validContactBeginDateShow,
      validContactEndDateShow: validContactEndDateShow,
      invalidContactBeginDateError: invalidContactBeginDateError,
      invalidContactEndDateError: invalidContactEndDateError,
      isContactBeginDateValid: isContactBeginDateValid,
      invalidDateOfBirthError: invalidDateOfBirthError,
      invalidDateOfDeathError: invalidDateOfDeathError,
      showDeathDateError: showDeathDateError,
      isContactEndDateValid: isContactEndDateValid,
      showContactBeginDateGreaterError: showContactBeginDateGreaterError
    });
    seterrorMessages(errorMessagesArray);
  };

  const handleSaveAddress = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var invalidAddressBeginDateError;
    var showAddressEndDateMatchError;
    var isAddressEndDateValid;
    var showAddressBeginDateMatchError;
    var addressEndDateLesserError;
    var showAddressBeginDateGreaterError;
    var invalidAddressEndDateError;
    var isAddressBeginDateValid = false;
    var showAddressTypeError;
    var showAdressBeginDateError;
    var showAddressEndDateError;
    var showAddressLine1Error = false;
    var showSignificantTypeCodeError = false;
    var showZipcodeError = false;
    var showStatusError = false;
    var showCityError = false;
    var showStateError = false;
    var showZipCodeReqError = false;
    var showZipcodeErrorMsg;
    var showAddressTypeErrorMsg = false;
    var showAdressBeginDateErrorMsg = false;
    var showAddressEndDateErrorMsg = false;
    var showAddressLine1ErrorMsg;
    var showSignificantTypeCodeErrorMsg;
    var showStatusErrorMsg = "";
    var showCityErrorMsg = "";
    var showStateErrorMsg = "";
    var showZipcodereqErrorMsg = "";
    if (addAddress.statusCode === DEFAULT_DD_VALUE) {
      showStatusError = true;
      showStatusErrorMsg = ErrorConstants.STATUS_REQUIRED;
      errorMessagesArray.push(showStatusErrorMsg);
    }
    if (addAddress.state === DEFAULT_DD_VALUE) {
      showStateError = true;
      showStateErrorMsg = ErrorConstants.STATE_REQUIRED;
      errorMessagesArray.push(showStateErrorMsg);
    }
    if (addAddress.city === "") {
      showCityError = true;
      showCityErrorMsg = ErrorConstants.CITY_REQUIRED;
      errorMessagesArray.push(showCityErrorMsg);
    }
    if (addAddress.zipCode5 === "") {
      showZipCodeReqError = true;
      showZipcodereqErrorMsg = ErrorConstants.ZIP_CODE_REQUIRED;
      errorMessagesArray.push(showZipcodereqErrorMsg);
    }
    if (addAddress.addressUsageTypeCode === DEFAULT_DD_VALUE) {
      showAddressTypeError = true;
      showAddressTypeErrorMsg = ErrorConstants.ADDRESS_TYPE_REQUIRED;
      errorMessagesArray.push(ErrorConstants.ADDRESS_TYPE_REQUIRED);
    }
    if (
      !addAddress.addressLine1 ||
      (addAddress.addressLine1 && !addAddress.addressLine1.trim())
    ) {
      showAddressLine1Error = true;
      showAddressLine1ErrorMsg = ErrorConstants.ADDRESS_LINE_1_REQUIRED;
      errorMessagesArray.push(ErrorConstants.ADDRESS_LINE_1_REQUIRED);
    }
    if (addAddress.addressUsageSigCode === DEFAULT_DD_VALUE) {
      showSignificantTypeCodeError = true;
      errorMessagesArray.push(ErrorConstants.SIGNIFICANT_TYPE_CODE_REQUIRED);
      showSignificantTypeCodeErrorMsg =
        ErrorConstants.SIGNIFICANT_TYPE_CODE_REQUIRED;
    }
    if (!selectedAddAddressBeginDate) {
      showAdressBeginDateError = true;
      errorMessagesArray.push(ErrorConstants.BEGINDATE_REQUIRED);
      showAdressBeginDateErrorMsg = ErrorConstants.BEGINDATE_REQUIRED;
    }
    if (selectedAddAddressBeginDate) {
      addAddress.beginDate =
        new Date(selectedAddAddressBeginDate).getMonth() +
        1 +
        "/" +
        new Date(selectedAddAddressBeginDate).getDate() +
        "/" +
        new Date(selectedAddAddressBeginDate).getFullYear();
    }
    if (
      addAddress.beginDate &&
      addAddress.beginDate.toString() === "NaN/NaN/NaN"
    ) {
      addAddress.beginDate = "";
    }
    if (selectedAddAddressEndDate) {
      addAddress.endDate =
        new Date(selectedAddAddressEndDate).getMonth() +
        1 +
        "/" +
        new Date(selectedAddAddressEndDate).getDate() +
        "/" +
        new Date(selectedAddAddressEndDate).getFullYear();
    }
    const regex = /^^[a-zA-Z0-9 ]*$/;
    if (
      addAddress &&
      addAddress.zipCode5 &&
      addAddress.zipCode5.toString().trim() &&
      !regex.test(addAddress.zipCode5)
    ) {
      showZipcodeError = true;
      showZipcodeErrorMsg = ErrorConstants.INVALID_ZIPCODE;
    }

    // Begin Date Validation
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() === "Invalid Date"
    ) {
      invalidAddressBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedAddAddressBeginDate
      );
      if (isDateValid) {
        isAddressBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedAddAddressBeginDate)
        ) <
          new Date(
            DateUtilities.getDateInMMDDYYYYFormat(new Date(selectedDate))
          )
      ) {
        showAddressBeginDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // End Date Validation
    var tempSelectedAddressEndDate = new Date(selectedAddAddressEndDate);
    if (!selectedAddAddressEndDate) {
      addAddress.endDate = "12/31/9999";
      setSelectedAddAddressEndDate(new Date("12/31/9999"));
      tempSelectedAddressEndDate = new Date("12/31/9999");
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() === "Invalid Date"
    ) {
      invalidAddressEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() !== "Invalid Date"
    ) {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(
        tempSelectedAddressEndDate
      );
      if (isDateValid) {
        isAddressEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedAddressEndDate
          )
        ) >
          new Date(
            DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
          )
      ) {
        showAddressEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // Begin Date + End Date Comparison
    if (
      selectedAddAddressBeginDate &&
      tempSelectedAddressEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedAddAddressBeginDate
        )
      ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedAddressEndDate
          )
        )
    ) {
      showAddressBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR);
    }
    if (errorMessagesArray.length === 0) {
      const addressReq = {
        address1: addAddress.addressLine1,
        address2: addAddress.addressLine2,
        city: addAddress.city,
        state: addAddress.state === DEFAULT_DD_VALUE ? "" : addAddress.state,
        zip4: addAddress.zipCode5,
        zip5: ""
      };
      validateAddress(addressReq);
    }
    setShowError({
      invalidAddressBeginDateError: invalidAddressBeginDateError,
      isAddressEndDateValid: isAddressEndDateValid,
      invalidAddressEndDateError: invalidAddressEndDateError,
      isAddressBeginDateValid: isAddressBeginDateValid,
      showAddressEndDateMatchError: showAddressEndDateMatchError,
      showAddressBeginDateMatchError: showAddressBeginDateMatchError,
      addressEndDateLesserError: addressEndDateLesserError,
      showAddressBeginDateGreaterError: showAddressBeginDateGreaterError,
      showAddressTypeError: showAddressTypeError,
      showAddressLine1Error: showAddressLine1Error,
      showSignificantTypeCodeError: showSignificantTypeCodeError,
      showAdressBeginDateError: showAdressBeginDateError,
      showAddressEndDateError: showAddressEndDateError,
      showZipcodeError: showZipcodeError,
      showZipCodeReqError: showZipCodeReqError,
      showStatusError: showStatusError,
      showCityError: showCityError,
      showStateError: showStateError
    });
    setAddressErrorMessage({
      showAddressTypeErrorMsg: showAddressTypeErrorMsg,
      showAddressLine1ErrorMsg: showAddressLine1ErrorMsg,
      showSignificantTypeCodeErrorMsg: showSignificantTypeCodeErrorMsg,
      showAdressBeginDateErrorMsg: showAdressBeginDateErrorMsg,
      showAddressEndDateErrorMsg: showAddressEndDateErrorMsg,
      showZipcodeErrorMsg: showZipcodeErrorMsg,
      showZipcodereqErrorMsg: showZipcodereqErrorMsg,
      showCityErrorMsg: showCityErrorMsg,
      showStatusErrorMsg: showStatusErrorMsg,
      showStateErrorMsg: showStateErrorMsg
    });
    seterrorMessages(errorMessagesArray);
  };

  const validateMasterDates = (
    selectedDate,
    selectedEndDate,
    beginDate,
    endDate
  ) => {
    const selectedEndDateValue = selectedEndDate || new Date(AppConstants.MAX_DATE_FORMAT);
    var showDateError = false;
    const dateFormatting = (value) => {
      return (moment(new Date(DateUtilities.getDateInMMDDYYYYFormatWithApendZero(value))).format("MM/DD/YYYY"));
    };
    if (selectedDate) {
    if (
     (new Date(dateFormatting(beginDate)) <
     new Date(dateFormatting(selectedDate))) ||
     (new Date(dateFormatting(endDate)) >
      new Date(dateFormatting(selectedEndDateValue)))
        ) {
      showDateError = true;
    }
    }
    return showDateError;
  };

  const validateMasterInputs = flag => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var mainAccountTypeEror;
    var mainAccountNumberError;
    var mainRoutingError;
    var routingNumberError;
    var mainBeginDateError;
    var invalidMainBeginDateError;
    var invalidMainEndDateError;
    var bankNameError;
    var mainAccountBeginDateInvalid;
    var isMainBeginDateValid;
    var isMainBeginDateSmaller;
    var isMainEndDateValid = false;
    if (values.accountType === DEFAULT_DD_VALUE) {
      mainAccountTypeEror = true;
      errorMessagesArray.push("Account Type " + ErrorConstants.NAME_ERROR);
    }
    if (values.bankName === "") {
      bankNameError = true;
      errorMessagesArray.push("Bank Name " + ErrorConstants.NAME_ERROR);
    }
    if (!values.accountNumber) {
      mainAccountNumberError = true;
      errorMessagesArray.push("Account # " + ErrorConstants.NAME_ERROR);
    }
    if (!values.routingBank) {
      mainRoutingError = true;
      errorMessagesArray.push("Routing/ABA# " + ErrorConstants.NAME_ERROR);
    }
    if (!selectedDate) {
      mainBeginDateError = true;
      errorMessagesArray.push("Begin Date  " + ErrorConstants.NAME_ERROR);
    } else {
      setSelectedAddAddressBeginDate(selectedDate);
      setSelectedContactBeginDate(selectedDate);
    }
    if (selectedDate && selectedDate.toString() === "Invalid Date") {
      invalidMainBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (selectedDate && selectedDate.toString() !== "Invalid Date") {
      const isDateValid = DateUtilities.validateDateMinimumValue(selectedDate);
      if (isDateValid) {
        isMainBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }

      if (
        !isMainBeginDateValid &&
        DateUtilities.getDateInMMDDYYYYFormat(selectedSearchDate) !==
          DateUtilities.getDateInMMDDYYYYFormat(selectedDate) &&
        new Date(DateUtilities.getDateInMMDDYYYYFormat(selectedDate)) <
          new Date(DateUtilities.getDateInMMDDYYYYFormat(new Date()))
      ) {
        isMainBeginDateSmaller = true;
        errorMessagesArray.push(
          ErrorConstants.ACCOUNT_BEGIN_DATE_SMALLER_ERROR
        );
      }
    }
    if (!selectedEndDate) {
      setSelectedEndDate(new Date("12/31/9999"));
    }
    if (selectedEndDate && selectedEndDate.toString() === "Invalid Date") {
      invalidMainEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (selectedEndDate && selectedEndDate.toString() !== "Invalid Date") {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(selectedEndDate);
      if (isDateValid) {
        isMainEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    }
    if (
      selectedDate &&
      selectedEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedDate)
      ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
        )
    ) {
      mainAccountBeginDateInvalid = true;
      errorMessagesArray.push(ErrorConstants.ACCOUNT_BEGIN_DATE_ERROR);
    }
    if (flag) {
      if (lockBoxTableData.length === 0) {
        errorMessagesArray.push(ErrorConstants.ONE_LOCKBOX_REQ);
      } else {
        for (let i = 0; i < lockBoxTableData.length; i++) {
          const beginDate = lockBoxTableData[i].beginDate
            ? lockBoxTableData[i].beginDate
            : lockBoxTableData[i].lockBoxBeginDate;
          const endDate = lockBoxTableData[i].endDate
            ? lockBoxTableData[i].endDate
            : lockBoxTableData[i].lockBoxEndDate;
          if (
            validateMasterDates(
              selectedDate,
              selectedEndDate,
              beginDate,
              endDate
            )
          ) {
            errorMessagesArray.push(
              "Lockbox Begin and End dates must be within Bank Accounts Begin and End Date for LockBox #" +
                lockBoxTableData[i].lockBoxNumber
            );
          }
        }
      }
      if (addressTableData.length === 0) {
        errorMessagesArray.push(ErrorConstants.ONE_ADDRESS_REQ);
      } else {
        for (let i = 0; i < addressTableData.length; i++) {
          if (
            validateMasterDates(
              selectedDate,
              selectedEndDate,
              addressTableData[i].beginDate,
              addressTableData[i].endDate
            )
          ) {
            errorMessagesArray.push(
              "Address Begin and End dates must be within Bank Accounts Begin and End Date for Record #" +
                i +
                1
            );
          }
        }
      }
      if (addContactsData.length === 0) {
        errorMessagesArray.push(ErrorConstants.ONE_CONTACT_REQ);
      } else {
        for (let i = 0; i < addContactsData.length; i++) {
          if (
            validateMasterDates(
              selectedDate,
              selectedEndDate,
              addContactsData[i].contactAddrBeginDate,
              addContactsData[i].contactAddrEndDate
            )
          ) {
            errorMessagesArray.push(
              "Contact Begin and End dates must be within Bank Accounts Begin and End Date for Record #" +
                i +
                1
            );
          }
        }
      }
    }
    setShowError({
      mainAccountTypeEror: mainAccountTypeEror,
      mainAccountNumberError: mainAccountNumberError,
      mainRoutingError: mainRoutingError,
      mainBeginDateError: mainBeginDateError,
      invalidMainBeginDateError: invalidMainBeginDateError,
      mainAccountBeginDateInvalid: mainAccountBeginDateInvalid,
      invalidMainEndDateError: invalidMainEndDateError,
      isMainBeginDateValid: isMainBeginDateValid,
      isMainBeginDateSmaller: isMainBeginDateSmaller,
      isMainEndDateValid: isMainEndDateValid,
      bankNameError: bankNameError,
      routingNumberError: routingNumberError
    });
    return errorMessagesArray;
  };

  const masterSave = () => {
    const endDatevalue = selectedEndDate || new Date(AppConstants.MAX_DATE_FORMAT);
    lockBoxTableData.map(element => {
      element = removeLeadingTrailingSpaces(element);
    });
    addressTableData.map(element => {
      element = removeLeadingTrailingSpaces(element);
    });
    addContactsData.map(element => {
      element = removeLeadingTrailingSpaces(element);
    });
    let activeContacts = [];
    if (allowNavigation === false) {
      NoSaveMessage();
    } else {
      const errorMessagesArray = validateMasterInputs(true);
      if (addContactsData.length > 0) {
        activeContacts = addContactsData.filter(
          contact => contact.statusCode === "A"
        );
        if (activeContacts.length < 1) {
          errorMessagesArray.push(ErrorConstants.ACTIVE_CONTACT_ERROR);
          seterrorMessages(errorMessagesArray);
        }
      }
      if (errorMessagesArray.length === 0) {
        addressTableData.map(value => {
          if (value.addressUsageTypeCode === DEFAULT_DD_VALUE) {
            value.addressUsageTypeCode = "";
          }
          if (value.addressUsageSigCode === DEFAULT_DD_VALUE) {
            value.addressUsageSigCode = "";
          }
          if (value.changeReasonCode === DEFAULT_DD_VALUE) {
            value.changeReasonCode = "";
          }
          if (value.country === DEFAULT_DD_VALUE) {
            value.country = "";
          }
          if (value.county === DEFAULT_DD_VALUE) {
            value.county = "";
          }
          if (value.state === DEFAULT_DD_VALUE) {
            value.state = "";
          }
          if (value.statusCode === DEFAULT_DD_VALUE) {
            value.statusCode = "";
          }
        });
        notesInput.notesList = notesTableData;
        let addIput;
        if (
          payloadData &&
          payloadData[0] &&
          payloadData[0].versionNo &&
          _payload_combine
        ) {
          if (payloadData[0].versionNo > _payload_combine.versionNo) {
            addIput = {
              auditUserID: logInUserID,
              auditTimeStamp: DateUtilities.getUTCTimeStamp(),
              addedAuditUserID:
                payloadData[0] && payloadData[0].addedAuditUserID
                  ? payloadData[0].addedAuditUserID
                  : logInUserID,
              addedAuditTimeStamp:
                payloadData[0] && payloadData[0].addedAuditTimeStamp
                  ? payloadData[0].addedAuditTimeStamp
                  : DateUtilities.getUTCTimeStamp(),
              versionNo:
                payloadData[0] && payloadData[0].versionNo
                  ? payloadData[0].versionNo
                  : 0,
              dbRecord: false,
              sortColumn: null,
              auditKeyList: [],
              auditKeyListFiltered: false,
              fileSavedFlag: "false",
              smallsaveflag: false,
              lobCode: AppConstants.DEFAULT_LOB_VALUE,
              routingNumber: values.routingBank,
              bankName: values.bankName,
              accountType: values.accountType,
              accountNumber: values.accountNumber,
              accountName: values.accountName,
              beginDate: moment(selectedDate).format("MM/DD/YYYY"),
              endDate: moment(endDatevalue).format("MM/DD/YYYY"),
              addressVO: {
                auditUserID: logInUserID,
                auditTimeStamp: DateUtilities.getUTCTimeStamp(),
                addedAuditUserID:
                  payloadData[0] &&
                  payloadData[0].addressVO &&
                  payloadData[0].addressVO.addedAuditUserID
                    ? payloadData[0].addressVO.addedAuditUserID
                    : logInUserID,
                addedAuditTimeStamp:
                  payloadData[0] &&
                  payloadData[0].addressVO &&
                  payloadData[0].addressVO.addedAuditTimeStamp
                    ? payloadData[0].addressVO.addedAuditTimeStamp
                    : DateUtilities.getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: false,
                sortColumn: null,
                auditKeyList: [],
                auditKeyListFiltered: false,
                contactType: null,
                addressType: null,
                contactSSN: null,
                contactDob: null,
                contactDod: null,
                contactStatusDesc: null,
                contactTypeDesc: null,
                contactTitle: null,
                significanceTypeCode: null,
                status: null,
                contactAddrBeginDate: null,
                contactAddrEndDate: null,
                gender: null,
                streetaddress: null,
                zipcode: null,
                dummyInd: null,
                addressUsageSequenceNumber: null,
                statusCode: "",
                changeReason: null,

                beginDate: "11/21/2019",
                endDate: null,
                addressTypeRowIndex: null,
                latitudeNumber: null,
                longitudeNumber: null,
                uspsAddressLine1: null,
                uspsAddressLine2: null,
                uspsAddressVerifyCode: null,
                addressUsageSK: null,
                prefix: null,
                lastName: null,
                firstName: null,
                middleName: null,
                suffix: null,
                phone: null,
                phoneExtn: null,
                email: null,
                addressOne: null,
                addressTwo: null,
                addressThree: null,
                addressFour: null,
                county: null,
                country: null,
                town: null,
                addressVerification: null,
                uspsVerifiedCode: null,
                latitude: null,
                longitude: null,
                city: null,
                state: null,
                zipOne: null,
                zipTwo: null,
                representativeSK: null,
                eaddressSK: null,
                phoneSK: null,
                addressSK: null,
                countryCode: null,
                countyCode: null,
                addressBeginDateStr: null,
                addressEndDateStr: null,
                mailReturnedIndicator: false,
                addressVoidIndicator: false,
                addressPendingIndicator: false,
                phoneBeginDateStr: null,
                phoneEndDateStr: null,
                phoneVoidIndicator: false,
                phoneOutOfServiceIndicator: false,
                emailAddressBeginDateStr: null,
                emailAddressEndDateStr: null,
                emailAddressVoidIndicator: false,
                emailAddressBouncedIndicator: false,
                contactTypeRowIndex: null,
                commonEntityVersionNumber: 0,
                repCommonEntitySK: null,
                renderContactSection: false,
                renderAddContactSection: false,
                renderEditContactSection: false,
                renderDeleteContactSection: false,
                contactKey: null,
                contactRowIndex: null,
                renderAdditionalContactSection: false,
                renderMailingAddressSection: false,
                commonEntityCrossRefVersionNumber: 0,
                representativeVersionNumber: 0,
                addressUsageVersionNumber: 0,
                addressVersionNumber: 0,
                returnedMailVersionNumber: 0,
                phoneUsageVersionNumber: 0,
                phoneVersionNumber: 0,
                emailUsageVersionNumber: 0,
                emailVersionNumber: 0,
                renderPlusMailingAddressSection: false,
                renderPlusAdditionalContactSection: false,
                nameVrsnNo: 0,
                cmnEntityTypVrsnNo: 0,
                commonEntitySK: null,
                repCommonEntity: null,
                disableContactType: false
              },
              bankAddressVOList: addressTableData,
              lockBoxList: lockBoxTableData,
              lockBoxMap: null,
              contactList: addContactsData,
              contactMap: null,
              lockBoxVO: null,
              disableBankDetails: false,
              renderUpdatePage: false,
              deletedLockBoxList: [],
              deletedContactList: [],
              disableLockBoxDetails: false,
              lobDesc: null,
              bankAuditTimeStamp: null,
              bankAddedAuditTimeStamp: null,
              noteSetVO: notesInput
            };
          } else {
            addIput = {
              auditUserID: logInUserID,
              auditTimeStamp: DateUtilities.getUTCTimeStamp(),
              addedAuditUserID:
                _payload_combine && _payload_combine.addedAuditUserID
                  ? _payload_combine.addedAuditUserID
                  : logInUserID,
              addedAuditTimeStamp:
                _payload_combine && _payload_combine.addedAuditTimeStamp
                  ? _payload_combine.addedAuditTimeStamp
                  : DateUtilities.getUTCTimeStamp(),
              versionNo:
                _payload_combine && _payload_combine.versionNo
                  ? _payload_combine.versionNo
                  : 0,
              dbRecord: false,
              sortColumn: null,
              auditKeyList: [],
              auditKeyListFiltered: false,
              fileSavedFlag: "false",
              smallsaveflag: false,
              lobCode: AppConstants.DEFAULT_LOB_VALUE,
              routingNumber: values.routingBank,
              bankName: values.bankName,
              accountType: values.accountType,
              accountNumber: values.accountNumber,
              accountName: values.accountName,
              beginDate: moment(selectedDate).format("MM/DD/YYYY"),
              endDate: moment(endDatevalue).format("MM/DD/YYYY"),
              addressVO: {
                auditUserID: logInUserID,
                auditTimeStamp: DateUtilities.getUTCTimeStamp(),
                addedAuditUserID:
                  _payload_combine &&
                  _payload_combine.addressVO &&
                  _payload_combine.addressVO.addedAuditUserID
                    ? _payload_combine.addressVO.addedAuditUserID
                    : logInUserID,
                addedAuditTimeStamp:
                  _payload_combine &&
                  _payload_combine.addressVO &&
                  _payload_combine.addressVO.addedAuditTimeStamp
                    ? _payload_combine.addressVO.addedAuditTimeStamp
                    : DateUtilities.getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: false,
                sortColumn: null,
                auditKeyList: [],
                auditKeyListFiltered: false,
                contactType: null,
                addressType: null,
                contactSSN: null,
                contactDob: null,
                contactDod: null,
                contactStatusDesc: null,
                contactTypeDesc: null,
                contactTitle: null,
                significanceTypeCode: null,
                status: null,
                contactAddrBeginDate: null,
                contactAddrEndDate: null,
                gender: null,
                streetaddress: null,
                zipcode: null,
                dummyInd: null,
                addressUsageSequenceNumber: null,
                statusCode: "",
                changeReason: null,

                beginDate: "11/21/2019",
                endDate: null,
                addressTypeRowIndex: null,
                latitudeNumber: null,
                longitudeNumber: null,
                uspsAddressLine1: null,
                uspsAddressLine2: null,
                uspsAddressVerifyCode: null,
                addressUsageSK: null,
                prefix: null,
                lastName: null,
                firstName: null,
                middleName: null,
                suffix: null,
                phone: null,
                phoneExtn: null,
                email: null,
                addressOne: null,
                addressTwo: null,
                addressThree: null,
                addressFour: null,
                county: null,
                country: null,
                town: null,
                addressVerification: null,
                uspsVerifiedCode: null,
                latitude: null,
                longitude: null,
                city: null,
                state: null,
                zipOne: null,
                zipTwo: null,
                representativeSK: null,
                eaddressSK: null,
                phoneSK: null,
                addressSK: null,
                countryCode: null,
                countyCode: null,
                addressBeginDateStr: null,
                addressEndDateStr: null,
                mailReturnedIndicator: false,
                addressVoidIndicator: false,
                addressPendingIndicator: false,
                phoneBeginDateStr: null,
                phoneEndDateStr: null,
                phoneVoidIndicator: false,
                phoneOutOfServiceIndicator: false,
                emailAddressBeginDateStr: null,
                emailAddressEndDateStr: null,
                emailAddressVoidIndicator: false,
                emailAddressBouncedIndicator: false,
                contactTypeRowIndex: null,
                commonEntityVersionNumber: 0,
                repCommonEntitySK: null,
                renderContactSection: false,
                renderAddContactSection: false,
                renderEditContactSection: false,
                renderDeleteContactSection: false,
                contactKey: null,
                contactRowIndex: null,
                renderAdditionalContactSection: false,
                renderMailingAddressSection: false,
                commonEntityCrossRefVersionNumber: 0,
                representativeVersionNumber: 0,
                addressUsageVersionNumber: 0,
                addressVersionNumber: 0,
                returnedMailVersionNumber: 0,
                phoneUsageVersionNumber: 0,
                phoneVersionNumber: 0,
                emailUsageVersionNumber: 0,
                emailVersionNumber: 0,
                renderPlusMailingAddressSection: false,
                renderPlusAdditionalContactSection: false,
                nameVrsnNo: 0,
                cmnEntityTypVrsnNo: 0,
                commonEntitySK: null,
                repCommonEntity: null,
                disableContactType: false
              },
              bankAddressVOList: addressTableData,
              lockBoxList: lockBoxTableData,
              lockBoxMap: null,
              contactList: addContactsData,
              contactMap: null,
              lockBoxVO: null,
              disableBankDetails: false,
              renderUpdatePage: false,
              deletedLockBoxList: [],
              deletedContactList: [],
              disableLockBoxDetails: false,
              lobDesc: null,
              bankAuditTimeStamp: null,
              bankAddedAuditTimeStamp: null,
              noteSetVO: notesInput
            };
          }
        } else {
          addIput = {
            auditUserID: logInUserID,
            auditTimeStamp: DateUtilities.getUTCTimeStamp(),
            addedAuditUserID:
              _payload_combine && _payload_combine.addedAuditUserID
                ? _payload_combine.addedAuditUserID
                : logInUserID,
            addedAuditTimeStamp:
              _payload_combine && _payload_combine.addedAuditTimeStamp
                ? _payload_combine.addedAuditTimeStamp
                : DateUtilities.getUTCTimeStamp(),
            versionNo:
              _payload_combine && _payload_combine.versionNo
                ? _payload_combine.versionNo
                : 0,
            dbRecord: false,
            sortColumn: null,
            auditKeyList: [],
            auditKeyListFiltered: false,
            fileSavedFlag: "false",
            smallsaveflag: false,
            lobCode: AppConstants.DEFAULT_LOB_VALUE,
            routingNumber: values.routingBank,
            bankName: values.bankName,
            accountType: values.accountType,
            accountNumber: values.accountNumber,
            accountName: values.accountName,
            beginDate: moment(selectedDate).format("MM/DD/YYYY"),
            endDate: moment(endDatevalue).format("MM/DD/YYYY"),
            addressVO: {
              auditUserID: logInUserID,
              auditTimeStamp: DateUtilities.getUTCTimeStamp(),
              addedAuditUserID:
                _payload_combine &&
                _payload_combine.addressVO &&
                _payload_combine.addressVO.addedAuditUserID
                  ? _payload_combine.addressVO.addedAuditUserID
                  : logInUserID,
              addedAuditTimeStamp:
                _payload_combine &&
                _payload_combine.addressVO &&
                _payload_combine.addressVO.addedAuditTimeStamp
                  ? _payload_combine.addressVO.addedAuditTimeStamp
                  : DateUtilities.getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: false,
              sortColumn: null,
              auditKeyList: [],
              auditKeyListFiltered: false,
              contactType: null,
              addressType: null,
              contactSSN: null,
              contactDob: null,
              contactDod: null,
              contactStatusDesc: null,
              contactTypeDesc: null,
              contactTitle: null,
              significanceTypeCode: null,
              status: null,
              contactAddrBeginDate: null,
              contactAddrEndDate: null,
              gender: null,
              streetaddress: null,
              zipcode: null,
              dummyInd: null,
              addressUsageSequenceNumber: null,
              statusCode: "",
              changeReason: null,

              beginDate: "11/21/2019",
              endDate: null,
              addressTypeRowIndex: null,
              latitudeNumber: null,
              longitudeNumber: null,
              uspsAddressLine1: null,
              uspsAddressLine2: null,
              uspsAddressVerifyCode: null,
              addressUsageSK: null,
              prefix: null,
              lastName: null,
              firstName: null,
              middleName: null,
              suffix: null,
              phone: null,
              phoneExtn: null,
              email: null,
              addressOne: null,
              addressTwo: null,
              addressThree: null,
              addressFour: null,
              county: null,
              country: null,
              town: null,
              addressVerification: null,
              uspsVerifiedCode: null,
              latitude: null,
              longitude: null,
              city: null,
              state: null,
              zipOne: null,
              zipTwo: null,
              representativeSK: null,
              eaddressSK: null,
              phoneSK: null,
              addressSK: null,
              countryCode: null,
              countyCode: null,
              addressBeginDateStr: null,
              addressEndDateStr: null,
              mailReturnedIndicator: false,
              addressVoidIndicator: false,
              addressPendingIndicator: false,
              phoneBeginDateStr: null,
              phoneEndDateStr: null,
              phoneVoidIndicator: false,
              phoneOutOfServiceIndicator: false,
              emailAddressBeginDateStr: null,
              emailAddressEndDateStr: null,
              emailAddressVoidIndicator: false,
              emailAddressBouncedIndicator: false,
              contactTypeRowIndex: null,
              commonEntityVersionNumber: 0,
              repCommonEntitySK: null,
              renderContactSection: false,
              renderAddContactSection: false,
              renderEditContactSection: false,
              renderDeleteContactSection: false,
              contactKey: null,
              contactRowIndex: null,
              renderAdditionalContactSection: false,
              renderMailingAddressSection: false,
              commonEntityCrossRefVersionNumber: 0,
              representativeVersionNumber: 0,
              addressUsageVersionNumber: 0,
              addressVersionNumber: 0,
              returnedMailVersionNumber: 0,
              phoneUsageVersionNumber: 0,
              phoneVersionNumber: 0,
              emailUsageVersionNumber: 0,
              emailVersionNumber: 0,
              renderPlusMailingAddressSection: false,
              renderPlusAdditionalContactSection: false,
              nameVrsnNo: 0,
              cmnEntityTypVrsnNo: 0,
              commonEntitySK: null,
              repCommonEntity: null,
              disableContactType: false
            },
            bankAddressVOList: addressTableData,
            lockBoxList: lockBoxTableData,
            lockBoxMap: null,
            contactList: addContactsData,
            contactMap: null,
            lockBoxVO: null,
            disableBankDetails: false,
            renderUpdatePage: false,
            deletedLockBoxList: [],
            deletedContactList: [],
            disableLockBoxDetails: false,
            lobDesc: null,
            bankAuditTimeStamp: null,
            bankAddedAuditTimeStamp: null,
            noteSetVO: notesInput
          };
        }

        setAllowNavigation(false);
        updateAPICall(addIput);
      } else {
        seterrorMessages(errorMessagesArray);
      }
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setShowMsg(false);
    }, 5000);
    if (props.location.state && props.location.state.message) {
      setSuccessMessages([props.location.state.message]);
      setFocusCheck(false);
    }
  }, [props.location.state && props.location.state.message]);

  const setUpdatedNotes = searchData => {
    if (searchData && searchData.noteSetVO) {
      const noteSetVO = searchData.noteSetVO;
      const notesTable = searchData.noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: noteSetVO.addedAuditUserID
          ? noteSetVO.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
          ? noteSetVO.addedAuditTimeStamp
          : DateUtilities.getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTable,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });

      setNotesTableData(notesTable);
    }
  };
  useEffect(() => {
    if (searchData) {
      setUpdatedNotes(searchData);
    }
  }, [searchData]);
  const validateParentForm = () => {
    let flag = false;
    if (values.accountType === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!values.accountNumber) {
      flag = flag || true;
    }
    if (!values.routingBank) {
      flag = flag || true;
    }
    if (!selectedDate) {
      flag = flag || true;
    }
    if (selectedDate && selectedDate.toString() === "Invalid Date") {
      flag = flag || true;
    }
    if (selectedDate && selectedDate.toString() !== "Invalid Date") {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(selectedDate);
      if (isDateValid) {
        flag = flag || true;
      }
    }
    if (selectedEndDate && selectedEndDate.toString() === "Invalid Date") {
      flag = flag || true;
    }
    if (selectedEndDate && selectedEndDate.toString() !== "Invalid Date") {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(selectedEndDate);
      if (isDateValid) {
        flag = flag || true;
      }
    }
    if (
      selectedDate &&
      selectedEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedDate)
      ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
        )
    ) {
      flag = flag || true;
    }
    return flag;
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values, selectedEndDate]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    setShowError(false);
  };

  useEffect(() => {
    if (openLockBox && document.getElementById("lockbox_form_pop_up")) {
 document.getElementById("lockbox_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  }, [openLockBox]);
  useEffect(() => {
    if (open && document.getElementById("address_form_pop_up")) {
 document.getElementById("address_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  }, [open]);
  useEffect(() => {
    if (openContact && document.getElementById("contact_form_pop_up")) {
 document.getElementById("contact_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  }, [openContact]);

  const ValuesFromBankAccount = props.history && props.history.location &&
   props.history.location.state && props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/BankAccountAndLockbox',
        tableRender: true,
        state: { ValuesFromBankAccount: ValuesFromBankAccount }
      });
    }, 100);
      };

      const majorCancel = () => {
        seterrorMessages([]);
        if (focusCheck) {
          setDialogOpen(true);
        } else {
          setDialogOpen(false);
          props.history.push({
            pathname: '/BankAccountAndLockbox',
            tableRender: true,
            state: { ValuesFromBankAccount: ValuesFromBankAccount }
          });
        }
      };

      const statusCodeVal = addStatusList.find(value => value.code === addAddress.statusCode);
      const contactCodeVal = contactStatusList.find(value => value.code === contactDetails.statusCode);
      const changeCode = changeReasonList.find(value => value.code === addAddress.changeReasonCode);
      const signCode = significanceTypeList.find(value => value.code === addAddress.addressUsageSigCode);
      const contactSignCode = contactSignificanceList.find(value => value.code === contactDetails.significanceTypeCode);
      const stateCode = addStateList.find(value => value.code === addAddress.state);
      const countyCode = countyList.find(value => value.code === addAddress.county);
      const countryCode = addCountryList.find(value => value.code === addAddress.country);
      const contactPrefix = contactPrefixList.find(value => value.code === contactDetails.prefix);
      const contactSuffix = contactSuffixList.find(value => value.code === contactDetails.suffix);
  const contactGender = genderList.find(value => value.code === contactDetails.gender);

  return (
    <div>
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {successMessages.length > 0 && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={successMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Update Bank Account And LockBox
          </h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              data-testid="MajorSave"
              onClick={masterSave}
              disabled={!userInquiryPrivileges}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button
              className={
                auditButtonToggle
                  ? "btn btn-primary ml-1"
                  : "btn btn-text1 btn-transparent ml-1"
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
              Audit Log
            </Button>

            {window.location.pathname === '/BankAccountLockBoxEdit' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
                <NativeDropDown
                isRequired
                  id="accountType"
                  inputProps={{ maxlength: 3 }}
                  label="Account Type"
                data-testid="test_acctType"
                  disabled
                  value={values.accountType}
                  onChange={handleChange("accountType")}
                  errTxt={
                    mainAccountTypeEror
                      ? "Account Type " + ErrorConstants.NAME_ERROR
                      : null
                  }
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={accountTypeList.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))}
                />
              <div className="mui-custom-form input-md">
                <TextField
                  id="accountNumber"
                  fullWidth
                  required
                  label="Account #"
                  data-testid="Acc_Num"
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  inputProps={{ maxlength: 15 }}
                  value={values.accountNumber}
                  onChange={handleChange("accountNumber")}
                  error={
                    mainAccountNumberError
                      ? "Account # " + ErrorConstants.NAME_ERROR
                      : null
                  }
                  helperText={
                    mainAccountNumberError
                      ? "Account # " + ErrorConstants.NAME_ERROR
                      : null
                  }
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      AppConstants.NUMBER_ONLY_REGEX,
                      ""
                    );
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="accountName"
                  fullWidth
                  label="Account Name"
                  data-testid="test_acctNum"
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  value={values.accountName}
                  onChange={handleChange("accountName")}
                  inputProps={{ maxlength: 60 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="routingBank"
                  fullWidth
                  required
                  label="Routing/ABA#"
                  data-testid="test_routing"
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  inputProps={{ maxlength: 9 }}
                  value={values.routingBank}
                  onChange={handleChange("routingBank")}
                  error={
                    mainRoutingError
                      ? "Routing/ABA# " + ErrorConstants.NAME_ERROR
                      : routingNumberError
                      ? ErrorConstants.ROUTING_NUMBER_ERROR
                      : null
                  }
                  helperText={
                    mainRoutingError
                      ? "Routing/ABA# " + ErrorConstants.NAME_ERROR
                      : routingNumberError
                      ? ErrorConstants.ROUTING_NUMBER_ERROR
                      : null
                  }
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                   inputProps={{ "data-testid": "beginDate" }}
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    required
                    fullWidth
                    id="date-picker-dialog"
                    data-testid='begin_date'
                    value={selectedDate}
                    label="Begin Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : selectedSearchDate &&
                          new Date(selectedSearchDate) <
                            new Date(
                              DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
                                new Date()
                              )
                            )
                    }
                    error={
                      mainBeginDateError
                        ? "Begin Date " + ErrorConstants.NAME_ERROR
                        : invalidMainBeginDateError
                        ? "Begin Date " + ErrorConstants.DATE_INVALID
                        : isMainBeginDateValid
                        ? AppConstants.DATE_ERROR_1964
                        : isMainBeginDateSmaller
                        ? ErrorConstants.ACCOUNT_BEGIN_DATE_SMALLER_ERROR
                        : mainAccountBeginDateInvalid
                        ? ErrorConstants.ACCOUNT_BEGIN_DATE_ERROR
                        : null
                    }
                    helperText={
                      mainBeginDateError
                        ? "Begin Date " + ErrorConstants.NAME_ERROR
                        : invalidMainBeginDateError
                        ? "Begin Date " + ErrorConstants.DATE_INVALID
                        : isMainBeginDateValid
                        ? AppConstants.DATE_ERROR_1964
                        : isMainBeginDateSmaller
                        ? ErrorConstants.ACCOUNT_BEGIN_DATE_SMALLER_ERROR
                        : mainAccountBeginDateInvalid
                        ? ErrorConstants.ACCOUNT_BEGIN_DATE_ERROR
                        : null
                    }
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </div>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    required
                    fullWidth
                    disabled={!userInquiryPrivileges || disableEndDate}
                    id="date-picker-dialog2"
                    label="End Date"
                    data-testid='end_date'
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    error={
                      invalidMainEndDateError
                        ? "End Date " + ErrorConstants.DATE_INVALID
                        : isMainEndDateValid
                        ? AppConstants.DATE_ERROR_1964
                        : null
                    }
                    helperText={
                      invalidMainEndDateError
                        ? "End Date " + ErrorConstants.DATE_INVALID
                        : isMainEndDateValid
                        ? AppConstants.DATE_ERROR_1964
                        : null
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="mui-custom-form input-md">
                <TextField
                  required
                  id="bankName"
                  fullWidth
                  label="Bank Name"
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  value={values.bankName}
                  data-testid="bank_name"
                  onChange={handleChange("bankName")}
                  inputProps={{ maxlength: 60 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={
                    bankNameError
                      ? "Bank Name " + ErrorConstants.NAME_ERROR
                      : null
                  }
                  helperText={
                    bankNameError
                      ? "Bank Name " + ErrorConstants.NAME_ERROR
                      : null
                  }
                />
              </div>
            </div>
          </form>
          <div className="tab-panelbody m-0">
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="LockBox"
                    data-testid='Edit_LockBox_test' />
                  <Tab label="Address"
                    data-testid='Edit_Address_test' />
                  <Tab label="Contacts"
                    data-testid='Edit_Contacts_test' />
                  <Tab label="Notes"
                    data-testid='Edit_Notes_test' />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <div className="tab-holder p-0">
                  {value === 0 && (
                    <div className="float-right my-3">
                      <Button
                        className="btn-text-main-delete btn-transparent"
                        onClick={rowDeleteClick}
                        data-testid="test_lb_delete"
                        disabled={!userInquiryPrivileges}
                        title={"Delete"}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </Button>
                      <Button
                        className="btn-text-main-add btn-success ml-1"
                        data-testid="Add Lockbox"
                        title="Add LockBox"
                        onClick={handleClickOpenLockBox}
                        disabled={!userInquiryPrivileges}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        Add
                      </Button>
                    </div>
                  )}
                  <div className="clearfix"></div>
                  <div className="tab-holder table-no-wrap my-3">
                    {value === 0 && (
                      <div className="tab-holder p-0">
                        <LockBoxTable
                          tableData={lockBoxTableData || []}
                          handleLockBoxTableAdd={handleLockBoxTableAdd}
                          rowDeleteLockBox={rowDeleteLockBox}
                          onClickAuditDialogLog={onClickAuditDialogLog}
                          uniqueKey={"lockBoxId"}
                          setClose={setClose}
                          close={close}
                          setFocusCheck ={setFocusCheck}
                        />
                      </div>
                    )}
                    {openLockBox ? (
                      <div className="tabs-container" id="lockbox_form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {selectedRow ? "Update" : "Add"} LockBox
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              data-testid="SaveLockBox"
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              onClick={handleSaveLockBox}
                              disabled={!userInquiryPrivileges}
                              title={selectedRow ? "Update" : "Add"}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {selectedRow ? "Update" : "Add"}
                            </Button>
                            <Button
                              variant="outlined"
                              data-testid="ResetLockBox"
                              color="primary"
                              className="bt-reset btn-transparent  ml-1"
                              onClick={() => handleResetLockBox(lockBoxDetails)}
                              disabled={!userInquiryPrivileges}
                              title={'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              data-testid="test_lb_cancel"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleCloseLockBox}
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <div className="form-wrapper">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form with-date input-md">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    disabled={!userInquiryPrivileges}
                                    id="lockbox-beginDate"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      showLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxBeginDateSmallerError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_SMALLER_ERROR
                                        : showLockBoxBeginDateGreaterError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_GREATER_ERROR
                                        : showLockBoxBeginDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : overLapDateError
                                        ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                        : null
                                    }
                                    helperText={
                                      showLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxBeginDateSmallerError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_SMALLER_ERROR
                                        : showLockBoxBeginDateGreaterError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_GREATER_ERROR
                                        : showLockBoxBeginDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : overLapDateError
                                        ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedLockBoxBeginDate}
                                    onChange={handleLockBoxBeginDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                                <div className="mui-custom-form with-date input-md">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    disabled={!userInquiryPrivileges}
                                    id="lockbox-endDate"
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      showLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxEndDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : null
                                    }
                                    helperText={
                                      showLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxEndDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedLockBoxEndDate}
                                    data-testid="LockBoxEndDate"
                                    onChange={handleLockBoxEndDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="lockBoxNumber"
                                  fullWidth
                                  label="LockBox #"
                                  inputProps={{ maxlength: 7 }}
                                  value={lockBoxDetails.lockBoxNumber}
                                  error={
                                    showLockBoxNumberError
                                      ? "LockBox # " +
                                        ErrorConstants.NAME_ERROR
                                      : showLockBoxDuplicateNumberError
                                      ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                      : null
                                  }
                                  helperText={
                                    showLockBoxNumberError
                                      ? "LockBox # " +
                                        ErrorConstants.NAME_ERROR
                                      : showLockBoxDuplicateNumberError
                                      ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                      : null
                                  }
                                  required
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  onChange={handleChangeLockBox(
                                    "lockBoxNumber"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="lockBoxName"
                                  fullWidth
                                  label="LockBox Name"
                                  value={lockBoxDetails.lockBoxName}
                                  error={
                                    showLockBoxNameError
                                      ? "LockBox Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showLockBoxNameError
                                      ? "LockBox Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  inputProps={{ maxlength: 40 }}
                                  required
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  onChange={handleChangeLockBox("lockBoxName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                            </div>
                          </form>
                          <div className="clearfix"></div>
                          {showLogDialogTable ? (
                            <div id="audit-dialog-table">
                              <AuditTableComponent
                                print
                                auditRowData={auditTableData}
                                setAuditRowData={setAuditTableData}
                                setShowLogTable={setShowLogDialogTable}
                                setClose={setClose}
                              />{" "}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="tab-holder p-0">
                  {value === 1 && (
                    <>
                      <div className="float-right my-3">
                        <Button
                          className="btn-text-main-delete btn-transparent ml-1"
                          onClick={rowDeleteClickAddress}
                          data-testid="address_delete"
                          disabled={!userInquiryPrivileges}
                          title={"Delete"}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          onClick={handleClickOpen}
                          data-testid="Add-Address"
                          title="Add Address"
                          disabled={!userInquiryPrivileges}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                  <div className="clearfix"></div>
                  <div className="tab-holder table-no-wrap my-3">
                    {value === 1 && (
                      <div className="tab-holder p-0">
                        <AddressTable
                          tableData={filteredAddressData}
                          handleAddressTableAdd={handleAddressTableAdd}
                          rowDeleteAddress={rowDeleteAddress}
                          onClickAuditDialogLog={onClickAuditAddressLog}
                          uniqueKey={"addressSK"}
                          setClose={setClose}
                          close={close}
                        />
                      </div>
                    )}
                    {open ? (
                      <div className="tabs-container" id="address_form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {selectedRow ? "Update" : "New"} Address
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              variant="outlined"
                              color="primary"
                              data-testid="verify_address"
                              className="btn btn-success ml-1"
                              onClick={() =>
                                handleSaveAddress(
                                  removeLeadingTrailingSpaces(addAddress)
                                )
                              }
                              disabled={!userInquiryPrivileges}
                              title={"Verify"}
                            >
                              Verify
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              data-testid="Address_minor_add"
                              onClick={() => handleSaveAddressFinal()}
                              title={selectedRow ? "Update" : "Add"}
                              disabled={!userInquiryPrivileges}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {selectedRow ? "Update" : "Add"}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent  ml-1"
                              data-testid='address_reset'
                              onClick={() => handleResetAddress(addAddress)}
                              disabled={!userInquiryPrivileges}
                              title={'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              data-testid='address_cancel'
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleClose}
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <ErrorMessages errorMessages={addressError} />
                          <form autoComplete="off">
                            <div className="form-wrapper">
                                <NativeDropDown
                                  id="addressUsageTypeCode"
                                  inputProps={{ maxlength: 2 }}
                                  disabled={
                                    !userInquiryPrivileges || addAddress.uuid
                                  }
                                  label="Address Type"
                                  value={addAddress.addressUsageTypeCode}
                                  onChange={handleChangeAddAddress(
                                    "addressUsageTypeCode"
                                  )}
                                  placeholder=""
                                  isRequired
                                  errTxt={
                                    showAddressTypeError
                                      ? showAddressTypeErrorMsg || null
                                      : null
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={addDropdowns && addDropdowns['G1#G_ADR_USG_TY_CD'] &&
                                    addDropdowns['G1#G_ADR_USG_TY_CD'].length > 0
                                      ? addDropdowns['G1#G_ADR_USG_TY_CD'].map((item) => (
                                        <option key={item.code} value={item.code}>
                                          {item.description}
                                        </option>
                                      ))
                                    : null}
                                />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="address-beginDate"
                                    label="Begin Date"
                                    disabled={!userInquiryPrivileges}
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    required
                                    error={
                                      showAdressBeginDateError
                                        ? showAdressBeginDateErrorMsg
                                        : invalidAddressBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isAddressBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showAddressBeginDateMatchError
                                        ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                        : null
                                    }
                                    helperText={
                                      showAdressBeginDateError
                                        ? showAdressBeginDateErrorMsg
                                        : invalidAddressBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isAddressBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showAddressBeginDateMatchError
                                        ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedAddAddressBeginDate}
                                    onChange={handleAddAddressBeginDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="address-endDate"
                                    disabled={!userInquiryPrivileges}
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedAddAddressEndDate}
                                    onChange={handleAddAddressEndDateChange}
                                    required
                                    error={
                                      showAddressEndDateError
                                        ? showAddressEndDateErrorMsg
                                        : invalidAddressEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isAddressEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showAddressBeginDateGreaterError
                                        ? ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR
                                        : showAddressEndDateMatchError
                                        ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                        : null
                                    }
                                    helperText={
                                      showAddressEndDateError
                                        ? showAddressEndDateErrorMsg
                                        : invalidAddressEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isAddressEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showAddressBeginDateGreaterError
                                        ? ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR
                                        : showAddressEndDateMatchError
                                        ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                        : null
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                                <NativeDropDown
                                  id="addressUsageSigCode"
                                  inputProps={{ maxlength: 2 }}
                                  disabled={!userInquiryPrivileges}
                                  label="Significance Type Code"
                                  value={signCode !== undefined ? addAddress.addressUsageSigCode : DEFAULT_DD_VALUE}
                                  onChange={handleChangeAddAddress(
                                    "addressUsageSigCode"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  isRequired
                                  errTxt={
                                    showSignificantTypeCodeError
                                      ? showSignificantTypeCodeErrorMsg || null
                                      : null
                                  }
                                  dropdownList={significanceTypeList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                            </div>
                            <div className="form-wrapper">
                                <NativeDropDown
                                  id="changeReasonCode"
                                  inputProps={{ maxlength: 2 }}
                                  disabled={!userInquiryPrivileges}
                                  label="Change Reason"
                                  value={changeCode !== undefined ? addAddress.changeReasonCode : DEFAULT_DD_VALUE}
                                  onChange={handleChangeAddAddress(
                                    "changeReasonCode"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={changeReasonList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                                <NativeDropDown
                                  isRequired
                                  id="statusCode"
                                  inputProps={{ maxlength: 2 }}
                                  disabled={!userInquiryPrivileges}
                                  label="Status"
                                  value={statusCodeVal !== undefined ? addAddress.statusCode : DEFAULT_DD_VALUE}
                                  onChange={handleChangeAddAddress(
                                    "statusCode"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  errTxt={
                                    showStatusError ? showStatusErrorMsg : null
                                  }
                                  dropdownList={addStatusList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                              <div className="mui-custom-form override-width-md">
                                <TextField
                                  className="AddressLine1"
                                  id="addressLine1"
                                  fullWidth
                                  label="Address Line 1"
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  inputProps={{ maxlength: 64 }}
                                  value={addAddress.addressLine1}
                                  onChange={handleChangeAddAddress(
                                    "addressLine1"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  required
                                  error={
                                    showAddressLine1Error
                                      ? showAddressLine1ErrorMsg || null
                                      : null
                                  }
                                  helperText={
                                    showAddressLine1Error
                                      ? showAddressLine1ErrorMsg || null
                                      : null
                                  }
                                />
                              </div>
                              <div className="mui-custom-form override-width-md">
                                <TextField
                                  id="addressLine2"
                                  fullWidth
                                  label="Address Line 2"
                                  disabled={!userInquiryPrivileges}
                                  inputProps={{ maxlength: 64 }}
                                  value={addAddress.addressLine2}
                                  onChange={handleChangeAddAddress(
                                    "addressLine2"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-wrapper">
                              <div className="mui-custom-form input-md">
                                <TextField
                                  required
                                  id="city"
                                  fullWidth
                                  label="City"
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  value={addAddress.city}
                                  inputProps={{ maxlength: 30 }}
                                  onChange={handleChangeAddAddress("city")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={
                                    showCityError ? showCityErrorMsg : null
                                  }
                                  helperText={
                                    showCityError ? showCityErrorMsg : null
                                  }
                                />
                              </div>
                                <NativeDropDown
                                  isRequired
                                  id="state"
                                  inputProps={{ maxlength: 2 }}
                                  disabled={!userInquiryPrivileges}
                                  label="State"
                                  value={stateCode !== undefined ? addAddress.state : DEFAULT_DD_VALUE}
                                  onChange={handleChangeAddAddress("state")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  errTxt={
                                    showStateError ? showStateErrorMsg : null
                                  }
                                  dropdownList={addStateList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                              <div className="mui-custom-form input-md">
                                <TextField
                                  required
                                  id="zipCode5"
                                  fullWidth
                                  label="Zip Code"
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  inputProps={{ maxlength: 9 }}
                                  value={addAddress.zipCode5}
                                  onChange={handleChangeAddAddress("zipCode5")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={
                                    showZipCodeReqError
                                      ? showZipcodereqErrorMsg
                                      : null || showZipcodeError
                                      ? showZipcodeErrorMsg
                                      : null
                                  }
                                  helperText={
                                    showZipCodeReqError
                                      ? showZipcodereqErrorMsg
                                      : null || showZipcodeError
                                      ? showZipcodeErrorMsg
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                setShowAdditionalDetails(!showAdditionalDetails)
                              }
                              style={{
                                backgroundColor: "#274463",
                                color: "#fff",
                                padding: "10px",
                                margin: "10px 10px",
                                minWidth: "680px"
                              }}
                            >
                              Additional Address Details{" "}
                              <div className="pull-right">
                                {showAdditionalDetails ? (
                                  <span>
                                    <i
                                      className="fa fa-angle-up"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                  <span>
                                    <i
                                      className="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )}
                              </div>
                            </div>
                            {(() => {
                              if (showAdditionalDetails) {
                                return (
                                  <div>
                                    <div className="form-wrapper">
                                        <NativeDropDown
                                          id="county"
                                          inputProps={{ maxlength: 5 }}
                                          disabled={!userInquiryPrivileges}
                                          label="County"
                                          value={countyCode !== undefined ? addAddress.county : DEFAULT_DD_VALUE}
                                          onChange={handleChangeAddAddress(
                                            "county"
                                          )}
                                          SelectProps={{
                                            MenuProps: {
                                              className: classes.menu
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          dropdownList={countyList.map(option => (
                                            <option
                                              key={option.code}
                                              value={option.code}
                                            >
                                              {option.description}
                                            </option>
                                          ))}
                                        />
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="town"
                                          fullWidth
                                          label="Town"
                                          InputProps={{
                                            readOnly: !userInquiryPrivileges,
                                            className: !userInquiryPrivileges
                                              ? "Mui-disabled"
                                              : ""
                                          }}
                                          inputProps={{ maxlength: 3 }}
                                          value={addAddress.town}
                                          onChange={handleChangeAddAddress(
                                            "town"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                        <NativeDropDown
                                          id="country"
                                          inputProps={{ maxlength: 3 }}
                                          label="Country"
                                          disabled={!userInquiryPrivileges}
                                          value={countryCode !== undefined ? addAddress.country : DEFAULT_DD_VALUE}
                                          onChange={handleChangeAddAddress(
                                            "country"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          dropdownList={addCountryList.map(option => (
                                            <option
                                              key={option.code}
                                              value={option.code}
                                            >
                                              {option.description}
                                            </option>
                                          ))}
                                        />
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="addressLine3"
                                          fullWidth
                                          label="Address Line 3"
                                          InputProps={{
                                            readOnly: !userInquiryPrivileges,
                                            className: !userInquiryPrivileges
                                              ? "Mui-disabled"
                                              : ""
                                          }}
                                          inputProps={{ maxlength: 64 }}
                                          value={addAddress.addressLine3}
                                          onChange={handleChangeAddAddress(
                                            "addressLine3"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-wrapper">
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="addressLine4"
                                          fullWidth
                                          label="Address Line 4"
                                          disabled={!userInquiryPrivileges}
                                          inputProps={{ maxlength: 64 }}
                                          value={addAddress.addressLine4}
                                          onChange={handleChangeAddAddress(
                                            "addressLine4"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })()}
                            <div
                              onClick={() =>
                                setShowAdditionalDetails1(
                                  !showAdditionalDetails1
                                )
                              }
                              style={{
                                backgroundColor: "#274463",
                                color: "#fff",
                                padding: "10px",
                                margin: "10px 10px",
                                minWidth: "680px"
                              }}
                            >
                              Address Verification
                              <div className="pull-right">
                                {showAdditionalDetails1 ? (
                                  <span>
                                    <i
                                      className="fa fa-angle-up"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                  <span>
                                    <i
                                      className="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )}
                              </div>
                            </div>
                            {(() => {
                              if (showAdditionalDetails1) {
                                return (
                                  <div>
                                    <div className="form-wrapper">
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="addressLine01"
                                          fullWidth
                                          label="Address Line 1"
                                          inputProps={{ maxlength: 64 }}
                                          value={uspsAddres.addressLine1}
                                          onChange={handleChangeAddAddress(
                                            "addressLine1"
                                          )}
                                          placeholder=""
                                          InputProps={{
                                            readOnly: true,
                                            className: "Mui-disabled"
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="addressLine02"
                                          fullWidth
                                          label="Address Line 2"
                                          inputProps={{ maxlength: 64 }}
                                          value={uspsAddres.addressLine2}
                                          onChange={handleChangeAddAddress(
                                            "addressLine2"
                                          )}
                                          placeholder=""
                                          InputProps={{
                                            readOnly: true,
                                            className: "Mui-disabled"
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          InputProps={{
                                            readOnly: true,
                                            className: "Mui-disabled"
                                          }}
                                          id="address-city"
                                          fullWidth
                                          label="City"
                                          value={uspsAddres.city}
                                          inputProps={{ maxlength: 30 }}
                                          onChange={handleChangeAddAddress(
                                            "city"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                        <NativeDropDown
                                          InputProps={{
                                            readOnly: true,
                                            className: "Mui-disabled"
                                          }}
                                        id="address-state"
                                          inputProps={{ maxlength: 2 }}
                                          label="State"
                                          value={uspsAddres.state}
                                          onChange={(e) => { setUspsAddress({ ...uspsAddres, state: e.target.value }); }}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          dropdownList={addStateList.map(option => (
                                            <option
                                              key={option.code}
                                              value={option.code}
                                            >
                                              {option.description}
                                            </option>
                                          ))}
                                        />
                                    </div>
                                    <div className="form-wrapper">
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          disabled
                                          id="address-zipCode5"
                                          fullWidth
                                          label="Zip Code"
                                          inputProps={{ maxlength: 5 }}
                                          value={uspsAddres.zipCode5}
                                          onChange={handleChangeAddAddress(
                                            "zipCode5"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })()}
                            <div className="mui-custom-form input-md">
                              <label
                                className="MuiFormLabel-root small-label"
                                htmlFor="fiscalyes"
                              >
                                Consider USPS
                              </label>
                              <div className="sub-radio">
                                <Radio
                                  value="Y"
                                  name="void"
                                  id="fiscalyes"
                                  checked={considerUSPS === "Y"}
                                  onChange={handleChangeUSPS("Flag")}
                                  disabled={!userInquiryPrivileges}
                                />
                                <span className="text-black"> Yes </span>
                                <Radio
                                  defaultChecked="true"
                                  value="N"
                                  className="ml-2"
                                  name="void"
                                  id="fiscalyes"
                                  checked={considerUSPS === "N"}
                                  onChange={handleChangeUSPS("Flag")}
                                  disabled={!userInquiryPrivileges}

                                />
                                <span className="text-black"> No </span>
                              </div>
                            </div>
                          </form>
                          <div className="clearfix"></div>
                        </div>
                        {showLogAddressTable ? (
                          <div id="audit-Address-table">
                            <AuditTableComponent
                              print
                              auditRowData={auditAddressTableData}
                              setAuditRowData={setAuditAddressTableData}
                              setShowLogTable={setShowLogAddressTable}
                              setClose={setClose}
                            />{" "}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="tab-holder p-0">
                  {value === 2 && (
                    <div className="float-right my-3">
                      <Button
                        className="btn-text-main-delete btn-transparent"
                        onClick={rowDeleteClickContact}
                        disabled={!userInquiryPrivileges}
                        data-testid="Add_con_delete"
                        title={"Delete"}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </Button>
                      <Button
                        className="btn-text-main-add btn-success ml-1 "
                        onClick={handleClickOpenContact}
                        disabled={!userInquiryPrivileges}
                        data-testid="test_Con_add_btn"
                        title="Add Contact"
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        Add
                      </Button>
                    </div>
                  )}
                  <div className="clearfix"></div>
                  <div className="tab-holder table-no-wrap my-3">
                    {value === 2 && (
                      <div className="tab-holder p-0">
                        <ContactTable
                          tableData={filteredContactData}
                          handleContactTableAdd={handleContactTableAdd}
                          rowDeleteContact={rowDeleteContact}
                          onClickAuditDialogLog={onClickAuditContactsLog}
                          uniqueKey={"representativeSK"}
                          setClose={setClose}
                          close={close}
                        />
                      </div>
                    )}
                    {openContact ? (
                      <div className="tabs-container" id="contact_form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {selectedRow ? "Update" : "New"} Contact
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              onClick={() => handleSaveContact(contactDetails)}
                              disabled={!userInquiryPrivileges}
                              data-testid='con_minor_ad'
                              title={selectedRow ? "Update" : "Add"}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {selectedRow ? "Update" : "Add"}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent  ml-1"
                              onClick={() => handleResetContact(contactDetails)}
                              disabled={!userInquiryPrivileges}
                              data-testid='Con_reset'
                              title = {'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleContactClose}
                              data-testid='Con_cancel'
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <div className="form-wrapper">
                                <NativeDropDown
                                  isRequired
                                  id="statusCode"
                                  inputProps={{ maxlength: 2 }}
                                  disabled={!userInquiryPrivileges}
                                  label="Status"
                                  value={contactCodeVal !== undefined ? contactDetails.statusCode : DEFAULT_DD_VALUE}
                                  errTxt={
                                    showStatusrror
                                      ? "Status " + ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("statusCode")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactStatusList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    disabled={!userInquiryPrivileges}
                                    id="contact-beginDate"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      showContactBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidContactBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isContactBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showContactBeginDateGreaterError
                                        ? ErrorConstants.CONTACT_BEGIN_DATE_GREATER_ERROR
                                        : showContactDateMatchError
                                        ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                        : null
                                    }
                                    helperText={
                                      showContactBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidContactBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isContactBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showContactBeginDateGreaterError
                                        ? ErrorConstants.CONTACT_BEGIN_DATE_GREATER_ERROR
                                        : showContactDateMatchError
                                        ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactBeginDate}
                                    onChange={handleContactBeginDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    disabled={!userInquiryPrivileges}
                                    id="contact-endDate"
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      invalidContactEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isContactEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showContactEndDateMatchError
                                        ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                        : null
                                    }
                                    helperText={
                                      invalidContactEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isContactEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showContactEndDateMatchError
                                        ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactEndDate}
                                    onChange={handleContactEndDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>

                                <NativeDropDown
                                  isRequired
                                  disabled={
                                    !userInquiryPrivileges || selectedRow
                                  }
                                  id="contactType"
                                  inputProps={{ maxlength: 2 }}
                                  label="Contact Type"
                                  value={contactDetails.contactType}
                                  errTxt={
                                    showContactTypeError
                                      ? "Contact Type " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("contactType")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={addDropdowns && addDropdowns['G1#G_REP_XREF_TY_CD'] &&
                                    addDropdowns['G1#G_REP_XREF_TY_CD'].length > 0
                                      ? addDropdowns['G1#G_REP_XREF_TY_CD'].map((option, index) => (
                                          <option key={index} value={option.code}>
                                            {option.description}
                                          </option>
                                        ))
                                      : null}
                                />
                            </div>
                            <div className="form-wrapper">
                                <NativeDropDown
                                  isRequired
                                  disabled={!userInquiryPrivileges}
                                  id="significanceTypeCode"
                                  inputProps={{ maxlength: 2 }}
                                  label="Significance"
                                  value={contactSignCode !== undefined ? contactDetails.significanceTypeCode : DEFAULT_DD_VALUE}
                                  errTxt={
                                    showSignificanceError
                                      ? "Significance " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact(
                                    "significanceTypeCode"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactSignificanceList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="contactSSN"
                                  fullWidth
                                  label="SSN"
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  inputProps={{ maxlength: 9 }}
                                  value={contactDetails.contactSSN}
                                  onChange={handleAddContact("contactSSN")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="contactTitle"
                                  fullWidth
                                  label="Title"
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  inputProps={{ maxlength: 60 }}
                                  value={contactDetails.contactTitle}
                                  onChange={handleAddContact("contactTitle")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                                <NativeDropDown
                                  id="prefix"
                                inputProps={{ maxlength: 10 }}
                                  disabled={!userInquiryPrivileges}
                                  label="Prefix"
                                  value={contactPrefix !== undefined ? contactDetails.prefix : DEFAULT_DD_VALUE}
                                  onChange={handleAddContact("prefix")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactPrefixList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                            </div>
                            <div className="form-wrapper">
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="firstName"
                                  fullWidth
                                  label="First Name"
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  inputProps={{ maxlength: 35 }}
                                  required
                                  value={contactDetails.firstName}
                                  error={
                                    showContactFName
                                      ? "First Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showContactFName
                                      ? "First Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("firstName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="middleName"
                                  fullWidth
                                  label="Middle Name"
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  inputProps={{ maxlength: 25 }}
                                  value={contactDetails.middleName}
                                  error={
                                    showContactMName
                                      ? "Middle Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showContactMName
                                      ? "Middle Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("middleName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="lastName"
                                  fullWidth
                                  label="Last Name"
                                  inputProps={{ maxlength: 60 }}
                                  required
                                  disabled={!userInquiryPrivileges}
                                  value={contactDetails.lastName}
                                  error={
                                    showContactLName
                                      ? "Last Name " + ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showContactLName
                                      ? "Last Name " + ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("lastName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                                <NativeDropDown
                                  id="suffix"
                                  inputProps={{ maxlength: 10 }}
                                  disabled={!userInquiryPrivileges}
                                  label="Suffix"
                                  value={contactSuffix !== undefined ? contactDetails.suffix : DEFAULT_DD_VALUE}
                                  onChange={handleAddContact("suffix")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactSuffixList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                            </div>
                            <div className="form-wrapper">
                                <NativeDropDown
                                  id="gender"
                                  disabled={!userInquiryPrivileges}
                                  inputProps={{ maxlength: 3 }}
                                  label="Gender"
                                  value={contactGender !== undefined ? contactDetails.gender : DEFAULT_DD_VALUE}
                                  onChange={handleAddContact("gender")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={genderList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form with-date input-md">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="contactDob"
                                    label="Date of Birth"
                                    disabled={!userInquiryPrivileges}
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactDOB}
                                    onChange={handleContactDOB}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                    helperText={
                                      invalidDateOfBirthError
                                        ? "Date of Birth " +
                                          ErrorConstants.DATE_INVALID
                                        : null
                                    }
                                    error={
                                      invalidDateOfBirthError
                                        ? "Date of Birth " +
                                          ErrorConstants.DATE_INVALID
                                        : null
                                    }
                                  />
                                </div>
                                <div className="mui-custom-form with-date input-md">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="contactDod"
                                    label="Date of Death"
                                    disabled={!userInquiryPrivileges}
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactDOD}
                                    onChange={handleContactDOD}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                    helperText={
                                      invalidDateOfDeathError
                                        ? "Date of Death " +
                                          ErrorConstants.DATE_INVALID
                                        : showDeathDateError
                                        ? ErrorConstants.DEATH_DATE_ERROR
                                        : null
                                    }
                                    error={
                                      invalidDateOfDeathError
                                        ? "Date of Death " +
                                          ErrorConstants.DATE_INVALID
                                        : showDeathDateError
                                        ? ErrorConstants.DEATH_DATE_ERROR
                                        : null
                                    }
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                            </div>
                          </form>
                          <div className="clearfix"></div>
                        </div>
                        {showLogContactsTable ? (
                          <div id="audit-Contacts-table">
                            <AuditTableComponent
                              print
                              auditRowData={auditContactsTableData}
                              setAuditRowData={setAuditContactsTableData}
                              setShowLogTable={setShowLogContactsTable}
                              setClose={setClose}
                            />{" "}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div className="tab-holder p-0">
                  {value === 3 && (
                    <Notes
                      addNotes={addNotes}
                      disableNotes={disableNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      isNotesTable={true}
                      setErrorMessages={seterrorMessages}
                      userInquiryPrivileges = {userInquiryPrivileges}
                      setFocusCheck={setFocusCheck}
                    />
                  )}
                </div>

              </TabPanel>
            </div>
          </div>
        </div>
        {showLogTable ? (
          <div id="audit-table">
            <AuditTableComponent
              print
              auditRowData={auditRowData}
              setAuditRowData={setAuditRowData}
              setShowLogTable={setShowLogTable}
            />{" "}
          </div>
        ) : null}
        <Footer print />
        <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
      </div>
    </div>
  );
}
