/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FCN_REQUIRED = 'FCN is required.';
export const PAYEE_REQUIRED = 'Payee ID is required.';
export const PAYEE_ID_TYPE_CODE_REQUIRED = 'Payee ID Type Code is required.';
export const PAYEE_TYPE_REQUIRED = 'Payee Type is required.';
export const FCN_TEXT = 'FCN';
export const PAYEE_TEXT = 'Payee';
export const NO_RECODRS_FOUND = 'No records found for the search criteria entered.';
export const NOT_A_PAYOUT = 'Entered FCN is not a Payout.';
export const DELETED_SUCCESSFULLY = 'Selected payout(s) deleted successfully.';
export const APPROVED_SUCCESSFULLY = 'Selected payout(s) approved successfully.';
export const APPROVED_AND_DELETED_SUCCESSFULLY = 'Selected payout(s) approved and deleted successfully.';
export const THRU_DATE_INVALID = 'Entered Established Thru Date is invalid.';
export const FROM_DATE_INVALID = 'Entered Established From Date is invalid.';
export const THRU_DATE_GREATERTHAN_FROM_DATE = 'Established thru date must be greater than Established from date.';
export const SYS_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const SYSTEM_SUCCESSFULL = 'System successfully saved the information.';
export const DATE_INCORRECT_FORMAT = 'Please enter the data in correct format.';
export const PAYEE_APPROVED_STATUS_FOR_BOTH = 'Both';
export const PAYEE_APPROVED_STATUS_FOR_UNAPPROVED = 'Unapproved';

