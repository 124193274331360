/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import TableComponent from "../../../../SharedModules/Table/Table";

export default function SystemParameterAddTable(props) {
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
        }
        return each;
      });	 
      return props.showVoid ? tData : tData.filter((c) => c.voidDate == null);
	  
    }
	
    return [];
  };
  return (
    <TableComponent
      isSearch={false}
      print={props.print}
      tableData={getTableData(props.tableData || [])}
      headCells={props.systemParameterEditHeadCells}
      onTableRowClick={props.editSystemVariableTable}
      defaultSortColumn={props?.systemParameterEditHeadCells[0]?.id}
      onTableRowDelete={props.rowDeleteSystemParameterDetails}
      onTableCellButtonClick={props.onClickAuditDialogLog}
      uniqueKey="parameterDetailSK"
      setClose={props.setClose}
      close={props.close}
    ></TableComponent>
  );
}
