/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './financialPayoutActionTypes';
import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler/index';
export const resetFCNSearchFinancialPayout = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const getNewPayoutResponse = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.ADD_NEW_PAYOUT_RESPONSE}`)
      .then(response => {
        if (response.data === undefined) {
          dispatch(setNewPayoutResponse([]));
        } else {
          dispatch(setNewPayoutResponse(response.data));
        }
      })
      .catch(error => {
        
        dispatch(setNewPayoutResponse(error));
      });
  };
};

export const setNewPayoutResponse = (newPayoutResponse) => {
  return {
    type: actionTypes.NEW_PAYOUT_RESPONSE,
    newPayoutResponse: newPayoutResponse
  };
};

export const searchFCNFinanceAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_PAYOUT_DETAILS}`, value)
      .then(response => {

        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setFCNFinancialPayout([]));
        } else {
          dispatch(setFCNFinancialPayout(sendingResponse));
        }
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFCNFinancialPayout(sendingResponse));
      });
  };
};

export const setFCNFinancialPayout = (fcns) => {
  return {
    type: actionTypes.SEARCH_FINANCIAL_PAYOUT,
    fcns: fcns
  };
};

export const resetPayeeSearchFinancialPayout = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const searchFCNReceivableActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYEE_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setPayeeFinancialPayout([]));
        } else {
          dispatch(setPayeeFinancialPayout(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setPayeeFinancialPayout(sendingResponse));
      });
  };
};

export const setPayeeFinancialPayout = (payees) => {
  return {
    type: actionTypes.PAYEE_SEARCH_FINANCIAL_PAYOUT,
    payees: payees
  };
};


export const getPayeeIdType = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYOUT_PAYEE_ID_TYPE_CODE_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
        
      });
  };
};

export const setPayeeIdTypeDropdown = (data) => {
  return {
    type: actionTypes.PAYEE_ID_TYPE_DROPDOWN,
    payeeIdTypeDropdown: data
  };
};

export const searchMCNFinancialPayoutAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYEE_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setMCNFinancialPayout([]));
        } else {
          dispatch(setMCNFinancialPayout(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setMCNFinancialPayout(sendingResponse));
        
      });
  };
};

export const setMCNFinancialPayout = (mcns) => {
  return {
    type: actionTypes.MCN_SEARCH_FINANCIAL_PAYOUT,
    mcns: mcns
  };
};

export const validatePayeeDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALIDATE_PAYEE_DETAILS}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeDetails(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeDetails(sendingResponse));
        
      });
  };
};

export const setPayeeDetails = (data) => {
  return {
    type: actionTypes.PAYOUT_DETAILS,
    payoutDetails: data
  };
};

export const validateMemberDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALIDATE_MEMBER_DETAILS}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setMemberDetails(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setMemberDetails(sendingResponse));
        
      });
  };
};

export const setMemberDetails = (data) => {
  return {
    type: actionTypes.PAYOUT_MEMBER_DETAILS,
    payoutMemberDetails: data
  };
};
export const financialPayoutAdd = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    })
  }
  formInfo.append('payoutVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYOUT_ADD_ENDPOINT}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {

        let sendingResponse = commonAPIHandler(response);
        dispatch(setFinancialPayoutAdd(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialPayoutAdd(sendingResponse));
        
      });
  };
};
export const setFinancialPayoutAdd = (financialPayoutAddResponse) => {
  return {
    type: actionTypes.PAYOUT_ADD_ACTION,
    financialPayoutAddResponse: financialPayoutAddResponse
  };
};

export const financialPayoutUpdate = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    })
  }
  formInfo.append('payoutVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYOUT_UPDATE_ENDPOINT}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setFinancialPayoutUpdate(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialPayoutUpdate(sendingResponse));
        
      });
  };
};

export const setFinancialPayoutUpdate = (financialPayoutUpdateResponse) => {
  return {
    type: actionTypes.PAYOUT_UPDATE_ACTION,
    financialPayoutUpdateResponse: financialPayoutUpdateResponse
  };
};

export const getPayeeIdTypeCodeAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYOUT_PAYEE_ID_TYPE_CODE_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeIdTypeCodeDetails(sendingResponse.payeeIdTypeCode));
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeCodeDetails(sendingResponse));
      });
  };
};

export const setPayeeIdTypeCodeDetails = (payeeIDTypeCodeData) => {
  return {
    type: actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE,
    payeeIDTypeCodeDetails: payeeIDTypeCodeData
  };
};

export const financialPayOutSearchAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_PAYOUT_SEARCH}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setFinancialPayoutSearch(sendingResponse));
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialPayoutSearch(sendingResponse));
      });
  };
};

export const setFinancialPayoutSearch = (searchData) => {
  return {
    type: actionTypes.SEARCH_FINANCIAL_PAYEE,
    payeeRes: searchData
  };
};

export const getFinancialPayoutDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_PAYOUT_DETAILS}`, value)
      .then(response => {

        let sendingResponse = commonAPIHandler(response);
        dispatch(setFinancialPayoutDetails(sendingResponse));
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialPayoutDetails(sendingResponse));
      });
  };
};

export const setFinancialPayoutDetails = (searchData) => {
  return {
    type: actionTypes.GET_SINGLE_PAYOUT_DETAILS,
    singlePayoutDetails: searchData
  };
};

export const resetSinglePayoutDetails = () => {
  return {
    type: actionTypes.RESET_SINGLE_PAYOUT_DETAILS,
    data: null
  };
};
