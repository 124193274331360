/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';
import { DEFAULT_LOB_VALUE, DESC_SORT } from '../../../../SharedModules/AppConstants';
import TableApprovalComponent from '../../../../SharedModules/Table/TableApproval';

const headCells = [
  {
    id: 'fcn',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    width: '19%',
    isLink: true
  },
  {
    id: 'payeeTypeDesc',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    width: '10.7%'
  },
  {
    id: 'payeeID',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    width: '10.7%'
  },
  {
    id: 'payeeName',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    width: '15%',
    isPayeeName: true
  },
  {
    id: 'reasonCodeDesc',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    width: '14%',
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: 'addedAuditUserID',
    disablePadding: false,
    label: 'Creator',
    enableHyperLink: false,
    width: '19%'
  },
  {
    id: 'amount',
    disablePadding: false,
    label: 'Payout Amount',
    enableHyperLink: false,
    width: '12.8%',
    isBalance: true
  },
  {
    id: 'approvedUserID',
    disablePadding: false,
    label: 'Approver',
    enableHyperLink: false,
    width: '15.5%',
    isNull: true
  },
  {
    id: 'approvalCode',
    disablePadding: false,
    label:'Select for Approval',
    enableHyperLink: false,
    width: '21.1%',
    isApproval: true,
    showHeaderCheckBox: true
  },
  {
    id: 'delete',
    disablePadding: false,
    label: 'Delete Payout',
    enableHyperLink: false,
    width: '12.1%',
    isDelete: true
  }
];
function PayoutApprovalSearchResults(props) {
  const editRow = row => event => { };

  const editLink = row => event => {
    props.editLink(row);
  };

  const allowNavigation = () => {
    props.setAllowNavigation(true);
  };

  const selectedRowsForApproval = selectedArray => {
    props.selectedArrayRowsApproval(selectedArray);
  };

  const selectedRowsForDelete = selectedArray => {
    props.selectedArrayRowsDelete(selectedArray);
  };
  const formatSearchCriteria = (_row) => {
    return ({
      fcn: _row.fcn,
      searchBy: 'searchByFCN',
      lob: [DEFAULT_LOB_VALUE],
      fcnSearch: 1,
      payeeSearch: 0,
      payeeType: null,
      payeeID: null,
      payeeIDTypeCode: null
    });
  };

  return (
    <div className="tab-holder-p-8 tableNoWrap px-0">
      <TableApprovalComponent
        fixedTable
        print={props.print}
        pathTo='/FinancialPayoutUpdate?data='
        formatSearchCriteria={formatSearchCriteria}
        approvalArray={props.approvalArray}
        deleteArray={props.deleteArray}
        headCells={headCells}
        tableData={props.tableData ? props.tableData : []}
        newSelectedArrayForApproval={selectedRowsForApproval}
        newSelectedArrayForDelete={selectedRowsForDelete}
        allowNavigation={allowNavigation}
        onTableRowClick={editRow}
        onLinkClick={editLink}
        defaultSortColumn={headCells[0].id}
        isSearch={true}
        values={props.values}
        sortOrder={DESC_SORT}
      />
    </div>
  );
}
export default withRouter(PayoutApprovalSearchResults);
