/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import * as actionTypes from './AccountPayableActionTypes';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData({ systemFailure: true }));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const fcnSearch = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYOUT_APPROVAL_SEARCH}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setFcnSearchData(sendingResponse));
      })
      .catch((error) => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFcnSearchData(sendingResponse));
      });
  };
};

export const setFcnSearchData = (data) => {
  return {
    type: actionTypes.ACCOUNT_PAYABLE_APPROVAL_FCN_SEARCH,
    fcnSearchData: data
  };
};

export const setFPASearchData = (data) => {
  return {
    type: actionTypes.PAYOUT_APPROVAL_SEARCH_VALUES,
    searchQuery: data
  };
};

export const payeeSearch = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYOUT_APPROVAL_SEARCH}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeSearchData(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeSearchData(sendingResponse));
      });
  };
};

export const getPayeeIdType = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYOUT_PAYEE_ID_TYPE_CODE_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      });
  };
};

export const setPayeeIdTypeDropdown = (data) => {
  return {
    type: actionTypes.GETPAYEEIDTYPE,
    payeeIdTypeDropdown: data
  };
};

export const setPayeeSearchData = (data) => {
  return {
    type: actionTypes.ACCOUNT_PAYABLE_APPROVAL_PAYEE_SEARCH,
    payeeSearchData: data
  };
};

export const resetAccounPayable = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const updatePayoutApprovalActionFCN = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPDATE_PAYOUT_APPROVAL}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setUpdatePayoutApprovalFCN(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setUpdatePayoutApprovalFCN(sendingResponse));
      });
  };
};

export const setUpdatePayoutApprovalFCN = (data) => {
  return {
    type: actionTypes.UPDATE_PAYOUT_APPROVAL_FCN,
    updateApprovalDataFCN: data
  };
};


export const updatePayoutApprovalActionPayee = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPDATE_PAYOUT_APPROVAL}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setUpdatePayoutApprovalPayee(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setUpdatePayoutApprovalPayee(sendingResponse));
      });
  };
};

export const setUpdatePayoutApprovalPayee = (data) => {
  return {
    type: actionTypes.UPDATE_PAYOUT_APPROVAL_PAYEE,
    updateApprovalDataPayee: data
  };
};

